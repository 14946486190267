export const createCustomerReducer = (state ={ customer: []}, action) => {
    switch(action.type) {
        case 'CUSTOMER_CREATE_SUCCESS':
            return { customer: action.payload}
        default:
            return state
    }
}


export const getCustomerReducer = (state ={ customers: []}, action) => {
    switch(action.type) {
        case 'GET_CUSTOMER_SUCCESS':
            return { customers: action.payload}
        default:
            return state
    }
}


export const statusCustomerReducer = (state ={ customers: []}, action) => {
    switch(action.type) {
        case 'STATUS_CUSTOMER_SUCCESS':
            return { customers: action.payload}
        default:
            return state
    }
}



export const deleteCustomerReducer = ( state = {}, action) => {
    switch (action.type) {
        case 'DELETE_CUSTOMER_SUCCESS':
            return { event: action.payload }
        default:
            return state
    }
}



export const orderListReducer = (state = { orders: []}, action) => {
    switch (action.type) {
        case 'ORDERS_LIST_REQUEST':
            return { orders: [] }
        case 'ORDERS_LIST_SUCCESS':
            return {
                orders: action.payload,
            }
        case 'ORDERS_LIST_FAIL':
            return { error: action.payload }
        default:
            return state
    }
}


export const subscriberListReducer = (state = { subscribers: []}, action) => {
    switch (action.type) {
        case 'SUBSCRIBERS_LIST_REQUEST':
            return { subscribers: [] }
        case 'SUBSCRIBERS_LIST_SUCCESS':
            return {
                subscribers: action.payload,
            }
        case 'SUBSCRIBERS_LIST_FAIL':
            return { error: action.payload }
        default:
            return state
    }
}



