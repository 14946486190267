import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from "styled-components";
import { WEIGHTS, COLORS } from '../../constants';
import { login } from '../../actions/userActions';

const LoginPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;

    useEffect(() => {
        if (userInfo) {
            navigate('/')
        }
    }, [navigate, userInfo])


    const loginHandler = (e) => {
        e.preventDefault()
        dispatch(login(email, password))
    }

    return (
        <Wrapper>
            
                <Label>Welcome back.</Label>
                {/*<Label2>New to timeout?<Nav>Sign up</Nav> </Label2>*/}
                <Container>
                    <Label2>Email</Label2>
                    <Input value={email} onChange={(e) => setEmail(e.target.value)} type='email' placeholder='Email' />
                </Container>
                <Container>
                    <Label2>Password</Label2>
                    <Input value={password} onChange={(e) => setPassword(e.target.value)} type='password' placeholder='Password' />
                </Container>
                <LoginButton onClick={loginHandler}>Log in</LoginButton>              
        </Wrapper>
    )
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 80px;
    padding: 40px 20px 20px 20px;

`;

const Label = styled.label`
    font-size: 1.5rem;
    color: #37517e;
    font-weight: ${WEIGHTS.medium};
`

const Label2 = styled(Label)`
    font-size: 1rem;
    color: #5d7079;
    font-weight: ${WEIGHTS.normal};
`;

const Nav = styled.a`
    text-decoration: underline;
    color: #0097C7;
`;


const Container = styled.div`
    padding-bottom: 15px;
    display: flex;
    flex-direction: column;
`;

const Input = styled.input`
    width: 350px;
    height: 50px;
    font-size: 1rem;
    border-radius: 4px;
    color: grey;
    outline: none;

    &:focus {
        border-color: #0081ba;
    }
`;

const LoginButton = styled.button`
    width: 350px;
    font-size: 14px;
    font-weight: ${WEIGHTS.medium};
    border-radius: 6px;
    height: 45px;
    color: white;
    background-color: #023E8A;
    text-decoration: none;
    border: none;
`;

export default LoginPage;