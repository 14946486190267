import React from 'react';
import "./franchisedatatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { franchiseColumns } from "../../datatablesource";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { listFranchises, deleteFranchise } from "../../actions/franchiseActions";
import { useDispatch, useSelector } from "react-redux";

const FranchiseDatatable = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
      if(!userInfo) {
          navigate('/bro')
      }
  })

  const franchises = useSelector((state) => state.franchiseList.franchises);


  useEffect(() => {
    if(userInfo) {
      dispatch(listFranchises())
    }
  }, [dispatch, userInfo])

  console.log(franchises?.[4]?.brand)


  function refreshPage() {
    window.location.reload(false);
  }

  const handleDelete = (id, e) => {
    //setData(data.filter((item) => item.id !== id));
    e.preventDefault();
    dispatch(deleteFranchise(id));
    refreshPage()
  };

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Link to={`/franchises/edit/${params.row.id}`} style={{ textDecoration: "none" }}>
              <div className="viewButton">Edit</div>
            </Link>
            <div
              className="deleteButton"
              onClick={(e) => handleDelete(params.row.id, e)}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];
  return (
    <div className="datatable">
      <div className="datatableTitle">
       Franchises
        <Link to="/franchises/new" className="link">
          Add New
        </Link>
      </div>
      {franchises?
        <DataGrid
          className="datagrid"
          rows={franchises}
          columns={franchiseColumns.concat(actionColumn)}
          pageSize={50}
          rowsPerPageOptions={[50]}
          checkboxSelection
        />
      : null
      }

    </div>
  );
};

export default FranchiseDatatable;
