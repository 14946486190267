export const NavLinks = [

    {
        id: "melbourne",
        name: "Melbourne",
        link: "/melbourne",
    },

    {
        id: "sydney",
        name: "Sydney",
        link: "/sydney",
    },

    {
        id: "brisbane",
        name: "Brisbane",
        link: "/brisbane",
    },

    {
        id: "adelaide",
        name: "Adelaide",
        link: "/adelaide",
    },

    {
        id: "perth",
        name: "Perth",
        link: "/perth",
    }
];

export const SecondNavLinks = [
    {
        id: "home",
        name: "Home",
        link: "/",
    },
    {
        id: "about",
        name: "About Us",
        link: "/aboutus",
    },
    {
        id: "contact",
        name: "Contact",
        link: "/contact Us",
    },
    {
        id: "list",
        name: "List VENUE",
        link: "/listvenue",
    },
    {
        id: "faq",
        name: "FAQs",
        link: "/faqs",
    },
    {
        id: "terms",
        name: "TERMS & Condition",
        link: "/terms",
    },

];

export const VENUE = [
    {
        id: "bar",
        value: "bars",
    },
    {
        id: "restaurant",
        value: "Restaurants",
    },
    {
        id: "function",
        value: "functions",
    },
    {
        id: "unique",
        value: "Unique spaces"
    },
    {
        id: "entertainment",
        value: "Entertainment",
    },
];


export const SUBTYPE2 = [
    {
        id: "hidden-bars",
        value: "Hidden Bars",
        typeSubtype: "bars",
    },
    {
        id: "after-work-bars",
        value: "After Work Bars",
        typeSubtype: "bars",
    },
    {
        id: "bars-for-hire",
        value: "Bars For Hire",
        typeSubtype: "bars",
    },
    {
        id: "bars-W/-smoking-area",
        value: "Bars W/ Smoking Area",
        typeSubtype: "bars",
    },
    {
        id: "bars-with-a-view",
        value: "Bars With A View",
        typeSubtype: "bars",
    },
    {
        id: "beer-gardens",
        value: "Beer Gardens",
        typeSubtype: "bars",
    },


];

export const AREA2 = [
    {
        id: "regional",
        value: "regional nsw",
        cityArea: "melbourne",
    },
    {
        id: "bay",
        value: "bay side",
        cityArea: "melbourne",
    },
    {
        id: "cbd",
        value: "Close to cbd",
        cityArea: "melbourne",
    },
    {
        id: "east",
        value: "east",
        cityArea: "mel",
    },
    {
        id: "north",
        value: "north",
        cityArea: "mel",
    },
    {
        id: "west",
        value: "west",
        cityArea: "mel",
    },
    {
        id: "south",
        value: "south",
        cityArea: "mel",
    },
];


export const SUBURBw = [
    {
        id: "alexandria",
        value: "Alexandria",
    },
    {
        id: "annandale",
        value: "Annandale",
    },
    {
        id: "artarmon",
        value: "Artarmon",
    },
    {
        id: "avalon",
        value: "Avalon",
    },
    {
        id: "balgowlah",
        value: "Balgowlah",
    },
    {
        id: "balmain",
        value: "Balmain",
    },
    {
        id: "balmoral",
        value: "Balmoral",
    },
    {
        id: "bankstown",
        value: "Bankstown",
    },
    {
        id: "barangaroo",
        value: "Barangaroo",
    },
    {
        id: "baulkham-hills ",
        value: "Baulkham Hills ",
    },

    {
        id: "Bay view",
        value: "Bayview",
    },
];




export const SUBTYPE = [
    {
        id: "vegetarian-restaurants",
        value: "Vegetarian Restaurant",
        typeSubtype: "restaurant",
    },
    {
        id: "italian-restaurants",
        value: "Italian Restaurant",
        typeSubtype: "restaurant",
    },
    {
        id: "date-restaurants",
        value: "Date Restaurant",
        typeSubtype: "restaurant",
    },
    {
        id: "chinese-restaurants",
        value: "Chinese Restaurant",
        typeSubtype: "restaurant",
    },
    {
        id: "cafes-restaurants",
        value: "Cafes",
        typeSubtype: "restaurant",
    },
    {
        id: "top-cafes-restaurants",
        value: "Top Cafes",
        typeSubtype: "restaurant",
    },
    {
        id: "live-music-restaurants",
        value: "Restaurant with Live Music",
        typeSubtype: "restaurant",
    },
    {
        id: "late-night-restaurants",
        value: "Late Night Dining Restaurant",
        typeSubtype: "restaurant",
    },
    {
        id: "private-restaurants",
        value: "Private Diving Restaurant",
        typeSubtype: "restaurant",
    },
    {
        id: "awesomeview-restaurants",
        value: "Restaurant with Awesome View",
        typeSubtype: "restaurant",
    },
    {
        id: "outdoor-restaurants",
        value: "Outdoor Dining Restaurant",
        typeSubtype: "restaurant",
    },

    {
        id: "hidden-bars",
        value: "Hidden Bars",
        typeSubtype: "bars",
    },
    {
        id: "after-work-bars",
        value: "After Work Bars",
        typeSubtype: "bars",
    },
    {
        id: "bars-for-hire",
        value: "Bars For Hire",
        typeSubtype: "bars",
    },
    {
        id: "bars-W/-smoking-area",
        value: "Bars W/ Smoking Area",
        typeSubtype: "bars",
    },
    {
        id: "bars-with-a-view",
        value: "Bars With A View",
        typeSubtype: "bars",
    },
    {
        id: "bars-with-a-view",
        value: "Bars With A View",
        typeSubtype: "bars",
    },
    {
        id: "date-bars",
        value: "Date Bars",
        typeSubtype: "bars",
    },
    {
        id: "laneway-bars",
        value: "Laneway Bars",
        typeSubtype: "bars",
    },
    {
        id: "new-bars",
        value: "New Bars",
        typeSubtype: "bars",
    },
    {
        id: "rooftop-bars",
        value: "Bars With A View",
        typeSubtype: "bars",
    },


    {
        id: "bmall-function",
        value: "Small Function",
        typeSubtype: "function",
    },
    {
        id: "big-function",
        value: "Big Functions",
        typeSubtype: "function",
    },
    {
        id: "birthday-function",
        value: "Birthday Party",
        typeSubtype: "function",
    },
    {
        id: "wedding-function",
        value: "Wedding Functions",
        typeSubtype: "function",
    },
    {
        id: "corporate-function",
        value: "Corporate Functions",
        typeSubtype: "function",
    },
    {
        id: "view-function",
        value: "Functions with great View",
        typeSubtype: "function",
    },
    {
        id: "blank-canvas-unique",
        value: "Black Canvas",
        typeSubtype: "unique",
    },
    {
        id: "boats-cruises-unique",
        value: "Boats & Cruises",
        typeSubtype: "unique",
    },
    {
        id: "warehouses-unique",
        value: "Warehouses for Hire",
        typeSubtype: "unique",
    },
    {
        id: "private-dining-unique",
        value: "Unique Private Dining",
        typeSubtype: "unique",
    },
    {
        id: "blank-canvas-unique",
        value: "Black Canvas",
        typeSubtype: "unique",
    },
    {
        id: "blank-canvas-unique",
        value: "Black Canvas",
        typeSubtype: "unique",
    },
    {
        id: "galleries-entertainment",
        value: "Galleries",
        typeSubtype: "entertainment",
    },
    {
        id: "comedy-entertainment",
        value: "Comedy",
        typeSubtype: "entertainment",
    },
    {
        id: "rock-entertainment",
        value: "Rock Music",
        typeSubtype: "entertainment",
    },
    {
        id: "exhibitions-entertainment",
        value: "Exhibitions",
        typeSubtype: "entertainment",
    },
    {
        id: "live-music-entertainment",
        value: "Live Music",
        typeSubtype: "entertainment",
    },
    {
        id: "theatre-entertainment",
        value: "Theatre",
        typeSubtype: "entertainment",
    },
    {
        id: "activities-regional-nsw",
        value: "Activities",
        typeSubtype: "regional-nsw",
    },
    {
        id: "spa-&-wellbeing-regional-nsw",
        value: "Spa & Wellbeing",
        typeSubtype: "regional-nsw",
    },
    {
        id: "activities-regional-nsw",
        value: "Activities",
        typeSubtype: "regional-nsw",
    },
    {
        id: "arts-attraction-regional-nsw",
        value: "Arts & Attraction",
        typeSubtype: "regional-nsw",
    },
    {
        id: "accommodation-regional-nsw",
        value: "Accommodation",
        typeSubtype: "regional-nsw",
    },
    {
        id: "bed-&-breakfast-regional-nsw",
        value: "Bed & Breakfast",
        typeSubtype: "regional-nsw",
    },
    {
        id: "wineries-regional-nsw",
        value: "Wineries",
        typeSubtype: "regional-nsw",
    },

];


export const AREA = [
    {
        id: "regional",
        value: "regional nsw",
        cityArea: "melbourne",
    },
    {
        id: "bay",
        value: "bay side",
        cityArea: "melbourne",
    },
    {
        id: "cbd",
        value: "Close to cbd",
        cityArea: "melbourne",
    },
    {
        id: "east",
        value: "east",
        cityArea: "mel",
    },
    {
        id: "north",
        value: "north",
        cityArea: "mel",
    },
    {
        id: "west",
        value: "west",
        cityArea: "mel",
    },
    {
        id: "south",
        value: "south",
        cityArea: "mel",
    },
];


export const SUBURB = [
    {
        id: "abbotsford",
        value: "Abbotsford", 
    },
    {
        id: "alexandria",
        value: "Alexandria",
    },
    {
        id: "annandale",
        value: "Annandale",
    },
    {
        id: "artarmon",
        value: "Artarmon",
    },
    {
        id: "avalon",
        value: "Avalon",
    },
    {
        id: "balgowlah",
        value: "Balgowlah",
    },
    {
        id: "balmain",
        value: "Balmain",
    },
    {
        id: "balmoral",
        value: "Balmoral",
    },
    {
        id: "bankstown",
        value: "Bankstown",
    },
    {
        id: "barangaroo",
        value: "Barangaroo",
    },
    {
        id: "baulkham-hills",
        value: "Baulkham Hills ",
    },

    {
        id: "bay-view",
        value: "Bayview",
    },
];


export const MEL_SUBURB = [
    
    {
        id:"abbotsford",
        value: 'Abbotsford',
    },
  {
    id:"albert-park",
    value: 'Albert Park',
  },
  {
    id:"albury",
    value: "Albury",
  },
  {
    id:"altona",
    value: "Altona",
  },
  {
    id:"armadale",
    value: "Armadale",
  },
  {
    id:"ascot-vale",
    value: "Ascot Vale",
  },
  {
    id: "balaclava",
    value: "Balaclava",
  },
  {
    id: "balwyn",
    value: "Balwyn",
  },
  {
    id: "beaumaris",
    value: "Beaumaris",
  },
  {
    id: "beechworth",
    value: "Beechworth",
  },
  {
    id: "bentleigh",
    value: "Bentleigh",
  },
  {
    id: "bentleigh-east",
    value: "Bentleigh East",
  },
  {
    id: "black-rock",
    value: "Black Rock",
  },
  {
    id: "blackburn",
    value: "Blackburn",
  },
  {
    id: "boronia",
    value: "Boronia",
  },
  {
    id: "brighton",
    value: "Brighton",
  },
  {
    id: "brunswick",
    value: "Brunswick",
  },
  {
    id: "brunswick-east",
    value: "Brunswick East",
  },
  {
    id: "burnley",
    value: "Burnley",
  },

  {
    id: "camberwell",
    value: "Camberwell",
  },
  {
    id: "canterbury",
    value: "Canterbury",
  },
  {
    id: "carlton",
    value: "Carlton",
  },
  {
    id: "carnegie",
    value: "Carnegie",
  },{
    id: "carrum-downs",
    value: "Carrum Downs",
  },{
    id: "caulfield",
    value: "Caulfield",
  },
  {
    id: "balaclava",
    value: "Balaclava",
  },
  {
    id: "caulfield-east",
    value: "Caulfield East",
  },
  {
    id: "cheltenham",
    value: "Cheltenham",
  },
  {
    id: "clayton",
    value: "Clayton",
  },
  {
    id: "clifton-hill",
    value: "Clifton Hill",
  },
  {
    id: "coburg",
    value: "Coburg",
  },

  {
    id: "coburg-north",
    value: "Coburg North",
  },

  {
    id: "collingwood",
    value: "Collingwood",
  },

  {
    id: "cranbourne",
    value: "Cranbourne",
  },
  {
    id: "cremorne",
    value: "Cremorne",
  },
  {
    id: "dandenong",
    value: "Dandenong",
  },
  {
    id: "dandenong-ranges",
    value: "Dandenong Ranges",
  },
  {
    id: "daylesford",
    value: "Daylesford",
  },
  {
    id: "docklands",
    value: "Docklands",
  },
  {
    id: "donvale",
    value: "Donvale",
  },
  {
    id: "east-doncaster",
    value: "East Doncaster",
  },
  {
    id: "east-melbourne",
    value: "East Melbourne",
  },
  {
    id: "elsternwick",
    value: "Elsternwick",
  },
  {
    id: "elwood",
    value: "Elwood",
  },
  {
    id: "essendon",
    value: "Essendon",
  },
  {
    id: "ferntree-gully",
    value: "Ferntree Gully",
  },
  {
    id: "fitzroy",
    value: "Fitzroy",
  },
  {
    id: "flemington",
    value: "Flemington",
  },
  {
    id: "footscray",
    value: "Footscray",
  },
  {
    id: "frankston",
    value: "Frankston",
  },
  {
    id: "gippsland",
    value: "Gippsland",
  },
  {
    id: "glen-huntly",
    value: "Glen Huntly",
  },
  {
    id: "glen-iris",
    value: "Glen Iris",
  },
  {
    id: "glen-waverley",
    value: "Glen Waverley",
  },
  {
    id: "goldfields",
    value: "Goldfields",
  },
  {
    id: "grampians",
    value: "Grampians",
  },
  {
    id: "great-ocean-road",
    value: "Great Ocean Road",
  },
  {
    id: "hampton",
    value: "Hampton",
  },
  {
    id: "hawthorn",
    value: "Hawthorn",
  },
  {
    id: "hawthorn-east",
    value: "Hawthorn East",
  },
  {
    id: "heathcote-junction",
    value: "Heathcote Junction",
  },
  {
    id: "heatherton",
    value: "Heatherton",
  },
  {
    id: "high-country",
    value: "High Country",
  },
  {
    id: "highett",
    value: "Highett",
  },
  {
    id: "hoppers-crossing",
    value: "Hoppers Crossing",
  },
  {
    id: "hughesdale",
    value: "Hughesdale",
  },
  {
    id: "huntingdale",
    value: "Huntingdale",
  },
  {
    id: "ivanhoe",
    value: "Ivanhoe",
  },
  {
    id: "keilor",
    value: "Keilor",
  },
  {
    id: "kensington",
    value: "Kensington",
  },
  
  {
    id: "knoxfield",
    value: "knoxfield",
  },
  {
    id: "kew",
    value: "Kew",
  },
  {
    id: "kilsyth",
    value: "Kilsyth",
  },
  {
    id: "lyndhurst",
    value: "Lyndhurst",
  },
  {
    id: "laverton",
    value: "Laverton",
  },
  {
    id: "Lilydale",
    value: "Lilydale",
  },
  {
    id: "mooroolbark",
    value: "Mooroolbark",
  },
  {
    id: "moonee-ponds",
    value: "Moonee Ponds",
  },
  {
    id: "melbourne-cbd",
    value: "Melbourne CBD",
  },
  {
    id: "malvern-east",
    value: "Malvern East",
  },
  {
    id: "malvern",
    value: "Malvern",
  },
  {
    id: "mornington-peninsula",
    value: "Mornington Peninsula",
  },
  {
    id: "mordialloc",
    value: "Mordialloc",
  },
  {
    id: "middle-park",
    value: "Middle Park",
  },
  {
    id: "murrumbeena",
    value: "Murrumbeena",
  },
  {
    id: "mount-evelyn",
    value: "Mount Evelyn",
  },
  {
    id: "moorabbin",
    value: "Moorabbin",
  },
  {
    id: "montrose",
    value: "Montrose",
  },
  {
    id: "north-melbourne",
    value: "North Melbourne",
  },
  {
    id: "narre-warren",
    value: "Narre Warren",
  },
  {
    id: "nagambie",
    value: "Nagambie",
  },
  {
    id: "newport",
    value: "Newport",
  },
  {
    id: "northcote",
    value: "Northcote",
  },
  {
    id: "ormond",
    value: "Ormond",
  },
  
  {
    id: "oakleigh",
    value: "Oakleigh",
  },
  {
    id: "parkville",
    value: "Parkville",
  },
  {
    id: "port-melbourne",
    value: "Port Melbourne",
  },
  {
    id: "philip-island",
    value: "Philip Island",
  },
  {
    id: "pascoe-vale",
    value: "Pascoe Vale",
  },
  {
    id: "preston",
    value: "Preston",
  },
  {
    id: "point-cook",
    value: "Point Cook",
  },
  {
    id: "prahran",
    value: "Prahran",
  },
  {
    id: "ringwood",
    value: "Ringwood",
  },
  {
    id: "richmond",
    value: "Richmonda",
  },
  {
    id: "rutherglen",
    value: "Rutherglen",
  },
  {
    id: "sandringham",
    value: "Sandringham",
  },
  {
    id: "scoresby",
    value: "Scoresby",
  },

  {
    id: "seddon",
    value: "Seddon",
  },
  {
    id: "south-melbourne",
    value: "South Melbourne",
  },
  {
    id: "south-wharf",
    value: "South Wharf",
  },
  {
    id: "south-yarra",
    value: "South Yarra",
  },
  {
    id: "southbank",
    value: "Southbank",
  },
  {
    id: "spotswood",
    value: "Spotswood",
  },
  {
    id: "st-kilda",
    value: "St Kilda",
  },
  {
    id: "st-kilda-east",
    value: "St Kilda East",
  },
  {
    id: "surrey-hills",
    value: "Surrey Hills",
  },
  {
    id: "templestowe",
    value: "Templestowe",
  },
  {
    id: "the-murray",
    value: "The Murray",
  },
  {
    id: "thornbury",
    value: "Thornbury",
  },
  {
    id: "toorak",
    value: "Toorak",
  },
  {
    id: "tullamarine",
    value: "Tullamarine",
  },
  {
    id: "upper-plenty",
    value: "Upper Plenty",
  },
  {
    id: "wahgunyah",
    value: "Wahgunyah",
  },
  {
    id: "wallan",
    value: "Wallan",
  },
  {
    id: "warrandyte",
    value: "Warrandyte",
  },
  {
    id: "west-melbourne",
    value: "West Melbourne",
  },
  {
    id: "wodonga",
    value: "Wodonga",
  },
  {
    id: "williamstown",
    value: "Williamstown",
  },
  {
    id: "werribee",
    value: "Werribee",
  },
  {
    id: "windsor",
    value: "Windsor",
  },
  {
    id: "yarraville",
    value: "Yarraville",
  },
  {
    id: "yarra-valley",
    value: "Yarra Valley",
  },
];




export const SYD_SUBURB = [
    {
        id:"alexandria",
        value: 'Alexandria',
    },
    {
        id:"abbotsford",
        value: "Abbotsford",
    },
    {
        id:"avalon",
        value: "Avalon",
    },
    {
        id:"annandale",
        value: "Annandale",
    },
    {
        id:"artarmon",
        value: "Artarmon",
    },
    {
        id:"baulkham-hills",
        value: "Baulkham Hills",
    },
    {
        id:"balmoral",
        value: "Balmoral",
    },
    {
        id:"balgowlah",
        value: "Balgowlah",
    },
    {
        id:"beaconsfield",
        value: "Beaconsfield",
    },
    {
        id:"barangaroo",
        value: "Barangaroo",
    },
    {
        id: "bigola-beach",
        value: "Bigola Beach",
    },
    {
        id:"bondi-junction",
        value: "Bondi Junction",
    },
    {
        id:"balmain",
        value: "Balmain",
    },

    {
        id:"blackheath",
        value: "Blackheath",
    },
    {
        id:"belrose",
        value: "Belrose",
    },
    {
        id:"bayview",
        value: "Bayview",
    },
    {
        id:"breakfast-point",
        value: "Breakfast Point",
    },
    {
        id: "bondi",
        value: "Bondi",
    },

    {
        id: "botany",
        value: "Botany",
    },
    {
        id: "brookvale",
        value: "Brookvale",
    },
    {
        id: "bronte",
        value: "Bronte",
    },
    {
        id:"camperdown",
        value: "Camperdown",
    },
    {
        id: "cabarita",
        value: "Cabarita",
    },
    {
        id: "concord",
        value: "Concord",
    },

    {
        id:"cammeray",
        value: "Cammeray",
    },
    {
        id:"clareville",
        value: "Clareville",
    },
    {
        id:"cremorne",
        value: "Cremorne",
    },
    {
        id:"church-point",
        value: "Church Point",
    },
    {
        id:"canada-bay",
        value: "Canada Bay",
    },
    {
        id:"centennial-park",
        value: "Centennial Park",
    },
    {
        id:"central-western",
        value: "Central Western",
    },
    {
        id:"campbell-town<",
        value: "Campbell Town<",
    },
    {
        id: "camden",
        value: "Camden",
    },
    {
        id:"curl-curl",
        value: "Curl Curl",
    },
    {
        id:"campbelltown",
        value: "Campbelltown",
    },
    {
        id:"chippendale",
        value: "Chippendale",
    },
    {
        id:"crows-nest",
        value: "Crows Nest",
    },
    {
        id:"cromer",
        value: "Cromer",
    },
    {
        id:"clontarf",
        value: "Clontarf",
    },
    {
        id:"circular-quay",
        value: "Circular Quay",
    },
    {
        id:"chatswood",
        value: "Chatswood",
    },
    {
        id:"coogee",
        value: "Coogee",
    },
    {
        id:"clovelly",
        value: "Clovelly",
    },
    {
        id:"chiswick",
        value: "Chiswick",
    },
    {
        id:"dawes-point",
        value: "Dawes Point",
    },
    {
        id:"dover-heights",
        value: "Dover Heights",
    },
    {
        id:"darlington",
        value: "Darlington",
    },
    {
        id:"double-bay",
        value: "Double Bay",
    },
    {
        id:"dee-why",
        value: "Dee Why",
    },
    {
        id:"darlinghurst",
        value: "Darlinghurst",
    },
    {
        id:"dulwich-hill",
        value: "Dulwich Hill",
    },
    {
        id:"eastlakes",
        value: "Eastlakes",
    },
    {
        id:"elizabeth-bay",
        value: "Elizabeth Bay",
    },
    {
        id:"erskineville",
        value: "Erskineville",
    },
    {
        id:"eveleigh",
        value: "Eveleigh",
    },
    {
        id:"enmore",
        value: "Enmore",
    },
    {
        id:"forest-lodge",
        value: "Forest Lodge",
    },
    {
        id:"abbotsford",
        value: "Abbotsford",
    },
    {
        id:"far-west",
        value: "Far West",
    },
    {
        id:"five-dock",
        value: "Five Dock",
    },
    {
        id:"freshwater",
        value: "Freshwater",
    },
    {
        id: "girraween",
        value: "Girraween",
    },
    {
        id: "greenacre",
        value: "Greenacre",
    },
    {
        id: "glebe",
        value: "Glebe",
    },
    {
        id: "gordon",
        value: "Gordon",
    },
    {
        id: "haymarket",
        value: "Haymarket",
    },
    {
        id: "huntingwood",
        value: "Huntingwood",
    },
    {
        id: "homebush",
        value: "Homebush",
    },
    {
        id: "hazelbrook",
        value: "Hazelbrook",
    },
    {
        id: "hunter",
        value: "Hunter",
    },
    {
        id: "hurstville",
        value: "Hurstville",
    },
    {
        id: "ingleside",
        value: "Ingleside",
    },
    {
        id: "illawarra",
        value: "Illawarra",
    },

    {
        id: "kingsford",
        value: "Kingsford",
    },
    {
        id: "kirribilli",
        value: "Kirribilli",
    },
    {
        id: "katoomba",
        value: "Katoomba",
    },
    {
        id: "kensington",
        value: "Kensington",
    },
    {
        id: "linley-point",
        value: "Linley Point",
    },
    {
        id: "liberty-grove",
        value: "Liberty Grove",
    },
    {
        id: "lane-cove-north",
        value: "Lane Cove North",
    },
    {
        id: "leichhardt",
        value: "Leichhardt",
    },
    {
        id: "longueville",
        value: "Longueville",
    },
    {
        id: "lane-cove",
        value: "Lane Cove",
    },
    {
        id: "liverpool",
        value: "Liverpool",
    },
    {
        id: "lilyfield",
        value: "Lilyfield",
    },
    {
        id: "millers-point",
        value: "Millers Point",
    },
    {
        id: "marrickville",
        value: "Marrickville",
    },
    {
        id: "macquarie-park",
        value: "Macquarie Park",
    },
    {
        id: "mcmahons-point",
        value: "McMahons Point",
    },
    {
        id: "marsfield",
        value: "Marsfield",
    },
    {
        id: "manly-vale",
        value: "Manly Vale",
    },
    {
        id: "maroubra",
        value: "Maroubra",
    },
    {
        id: "manly",
        value: "Manly",
    },
    {
        id: "mount-annan",
        value: "Mount Annan",
    },
    {
        id: "mascot",
        value: "Mascot",
    },
    {
        id: "mona-vale",
        value: "Mona Vale",
    },
    {
        id: "moore-park",
        value: "Moore Park",
    },
    {
        id: "murray",
        value: "Murray",
    },
    {
        id: "mortdale",
        value: "Mortdale",
    },
    {
        id: "mosman",
        value: "Mosman",
    },
    {
        id: "narellan",
        value: "Narellan",
    },
    {
        id: "new-england",
        value: "New England",
    },
    {
        id: "neutral-bay",
        value: "Neutral Bay",
    },
    {
        id: "north-epping",
        value: "North Epping",
    },
    {
        id: "northrocks",
        value: "Northrocks",
    },
    {
        id: "north-strathfield",
        value: "North Strathfield",
    },
    {
        id: "north-sydney",
        value: "North Sydney",
    },
    {
        id: "northmead",
        value: "Northmead",
    },
    {
        id: "north-bondi",
        value: "North Bondi",
    },
    {
        id: "north-coast",
        value: "North Coast",
    },
    {
        id: "newington",
        value: "Newington",
    },
    {
        id: "narraweena",
        value: "Narraweena",
    },
    {
        id: "narrabeen",
        value: "Narrabeen",
    },
    {
        id: "newtown",
        value: "Newtown",
    },
    {
        id: "northwood",
        value: "Northwood",
    },
    {
        id: "newport",
        value: "Newport",
    },
    {
        id: "north-ryde",
        value: "North Ryde",
    },
    {
        id: "orana",
        value: "Orana",
    },
    {
        id: "oatley",
        value: "Oatley",
    },
    {
        id: "punchbowl",
        value: "Punchbowl",
    },
    {
        id: "petersham",
        value: "Petersham",
    },
    {
        id: "pemulwuy",
        value: "Pemulwuy",
    },
    {
        id: "paddington",
        value: "Paddington",
    },
    {
        id: "point-piper",
        value: "Point Piper",
    },
    {
        id: "prospect",
        value: "Prospect",
    },
    {
        id: "potts-point",
        value: "Potts Point",
    },
    {
        id: "parramatta",
        value: "Parramatta",
    },
    {
        id: "pyrmont",
        value: "Pyrmont",
    },
    {
        id: "penshurst",
        value: "Penshurst",
    },
    {
        id: "queenscliff",
        value: "Queenscliff",
    },
    {
        id: "rosebery",
        value: "Rosebery",
    },
    {
        id: "riverina",
        value: "Riverina",
    },
    {
        id: "redfern",
        value: "Redfern",
    },
    {
        id: "rose-bay",
        value: "Rose Bay",
    },
    {
        id: "randwick",
        value: "Randwick",
    },
    {
        id: "rhodes",
        value: "Rhodes",
    },
    {
        id: "rozelle",
        value: "Rozelle",
    },
    {
        id: "roseberry",
        value: "Roseberry",
    },
    {
        id: "sans-souci",
        value: "Sans Souci",
    },
    {
        id: "surry-hills",
        value: "Surry Hills",
    },
    {
        id: "sydney-cbd",
        value: "Sydney CBD",
    },
    {
        id: "south-eastern",
        value: "South Eastern",
    },
    {
        id: "south-coogee",
        value: "South Coogee",
    },
    {
        id: "sydney-olympic-park",
        value: "Sydney Olympic Park",
    },
    {
        id: "st-leonards",
        value: "St Leonards",
    },
    {
        id: "sydney-harbour",
        value: "Sydney Harbour",
    },
    {
        id: "seaforth",
        value: "Seaforth",
    },
    {
        id: "sydneham",
        value: "Sydneham",
    },
    {
        id: "tamarama",
        value: "Tamarama",
    },
    {
        id: "the-rocks",
        value: "The Rocks",
    },
    {
        id: "terrey-hills",
        value: "Terrey Hills",
    },
    {
        id: "toongabbie",
        value: "Toongabbie",
    },
    {
        id: "the-spit",
        value: "The Spit",
    },
    {
        id: "ultimo",
        value: "Ultimo",
    },
    {
        id: "vaucluse",
        value: "Vaucluse",
    },
    {
        id: "watsons-bay",
        value: "Watsons Bay",
    },
    {
        id: "woolloomooloo",
        value: "Woolloomooloo",
    },
    {
        id: "wiley-park",
        value: "Wiley Park",
    },
    {
        id: "wentworth-point",
        value: "Wentworth Point",
    },
    {
        id: "warriewood",
        value: "Warriewood",
    },
    {
        id: "walsh-bay",
        value: "Walsh Bay",
    },
    {
        id: "winston-hills",
        value: "Winston Hills",
    },
    {
        id: "waverton",
        value: "Waverton",
    },
    {
        id: "west-pymble",
        value: "West Pymble",
    },
    {
        id: "waterloo",
        value: "Waterloo",
    },
    {
        id: "zetland",
        value: "Zetland",
    },
    {
        id: "wollstonecraft",
        value: "Wollstonecraft",
    },
    {
        id: "woollahra",
        value: "Woollahra",
    },
    {
        id: "yagoona",
        value: "Yagoona",
    },

];


export const BRIS_SUBURB = [
    {
        id: "acacia-ridge",
        value: "Acacia Ridge",
    },
    {
        id: "albion",
        value: "Albion",
    },

    {
        id: "algester",
        value: "Algester",
    },
    {
        id: "annerley",
        value: "Annerley",
    },
    {
        id: "archerfield",
        value: "Archerfield",
    },
    {
        id: "ascot",
        value: "Ascot",
    },
    {
        id: "ashgrove",
        value: "Ashgrove",
    },
    {
        id: "auchenflower",
        value: "Auchenflower",
    },
    {
        id: "balmoral",
        value: "Balmoral",
    },
    {
        id: "broadbeach-waters",
        value: "Broadbeach Waters",
    },
    {
        id: "burleigh-heads",
        value: "Burleigh Heads",
    },
    {
        id: "broadbeach",
        value: "Broadbeach",
    },
    {
        id: "brisbane",
        value: "Brisbane",
    },
    {
        id: "bowen-hills",
        value: "Bowen Hills",
    },
    {
        id: "bundall",
        value: "Bundall",
    },
    {
        id: "bulimba",
        value: "Bulimba",
    },
    {
        id: "burleigh-waters",
        value: "Burleigh Waters",
    },
    {
        id: "cairns",
        value: "Cairns",
    },
    {
        id: "camp-hill",
        value: "Camp Hill",
    },
    {
        id: "chevron-island",
        value: "Chevron Island",
    },
    {
        id: "coolangatta",
        value: "Coolangatta",
    },
    {
        id: "coorparoo",
        value: "Coorparoo",
    },
    {
        id: "currumbin",
        value: "Currumbin",
    },{
        id: "dutton-park",
        value: "Dutton Park",
    },
    {
        id: "durack",
        value: "Durack",
    },
    {
        id: "eagle-farm",
        value: "Eagle Farm",
    },
    {
        id: "eight-mile-plains",
        value: "Eight Mile Plains",
    },
    {
        id: "east-brisbane",
        value: "East Brisbane",
    },
    {
        id: "ekibin",
        value: "Ekibin",
    },
    {
        id: "fortitude-valley",
        value: "Fortitude Valley",
    },
    {
        id: "fairfield",
        value: "Fairfield",
    },
    {
        id: "greenslopes",
        value: "Greenslopes",
    },
    {
        id: "herston",
        value: "Herston",
    },
    {
        id: "hawthorne",
        value: "Hawthorne",
    },
    {
        id: "holland-park",
        value: "Holland Park",
    },
    {
        id: "highgate-hill",
        value: "Highgate Hill",
    },
    {
        id: "hamilton",
        value: "Hamilton",
    },
    {
        id: "hemmant",
        value: "Hemmant",
    },
    {
        id: "indooroopilly",
        value: "Indooroopilly",
    },
    {
        id: "kippa-ring",
        value: "Kippa-Ring",
    },
    {
        id: "kuraby",
        value: "Kuraby",
    },
    {
        id: "kangaroo-point",
        value: "Kangaroo Point",
    },
    {
        id: "kuranda",
        value: "Kuranda",
    },
    {
        id: "kenmore",
        value: "Kenmore",
    },
    {
        id: "loganholme",
        value: "Loganholme",
    },
    {
        id: "mermaid-beach",
        value: "Mermaid Beach",
    },
    {
        id: "mount-coot-tha",
        value: "Mount Coot-Tha",
    },
    {
        id: "main-beach",
        value: "Main Beach",
    },
    {
        id: "macgregor",
        value: "MacGregor",
    },
    {
        id: "manly-west",
        value: "Manly West",
    },
    {
        id: "morningside",
        value: "Morningside",
    },
    {
        id: "milton",
        value: "Milton",
    },
    {
        id: "new-farm",
        value: "New Farm",
    },
    {
        id: "newstead",
        value: "Newstead",
    },
    {
        id: "newport",
        value: "Newport",
    },
    {
        id: "noosa",
        value: "Noosa",
    },
    {
        id: "yagoona",
        value: "Yagoona",
    },
    {
        id: "petrie-terrace",
        value: "Petrie Terrace",
    },
    {
        id: "pallara",
        value: "Pallara",
    },
    {
        id: "port-douglas",
        value: "Port Douglas",
    },
    {
        id: "palm-beach",
        value: "Palm Beach",
    },
    {
        id: "rochedale",
        value: "Rochedale",
    },
    {
        id: "red-hill",
        value: "Red Hill",
    },
    {
        id: "runcorn",
        value: "Runcorn",
    },
    {
        id: "Redcliffe",
        value: "Redcliffe",
    },
    {
        id: "rosalie",
        value: "Rosalie",
    },
    {
        id: "south-brisbane",
        value: "South Brisbane",
    },
    {
        id: "scarborough",
        value: "Scarborough",
    },
    {
        id: "stones-corner",
        value: "Stones Corner",
    },
    {
        id: "south-bank",
        value: "South Bank",
    },
    {
        id: "spring-hill",
        value: "Spring Hill",
    },
    {
        id: "southport",
        value: "Southport",
    },
    {
        id: "seven-hills",
        value: "Seven Hills",
    },
    {
        id: "surfers-paradise",
        value: "Surfers Paradise",
    },
    {
        id: "St-lucia",
        value: "St Lucia",
    },
    {
        id: "toowong",
        value: "Toowong",
    },
    {
        id: "teneriffe",
        value: "Teneriffe",
    },
    {
        id: "taringa",
        value: "Taringa",
    },
    {
        id: "tweed-heads",
        value: "Tweed Heads",
    },
    {
        id: "tingalpa",
        value: "Tingalpa",
    },
    {
        id: "woolloongabba",
        value: "Woolloongabba",
    },
    {
        id: "willawong",
        value: "Willawong",
    },
    {
        id: "wynnum-west",
        value: "Wynnum West",
    },
    {
        id: "windsor",
        value: "Windsor",
    },
   
    {
        id: "west-end",
        value: "West End",
    },
    {
        id: "yeronga",
        value: "Yeronga",
    },
];


export const ADEL_SUBURB = [
    {
        id: "angaston",
        value: "Angaston",
    },
    {
        id: "adelaide-cbd",
        value: "Adelaide CBD",
    },
    {
        id: "albert-park",
        value: "Albert Park",
    },
    {
        id: "belair",
        value: "Belair",
    },
    {
        id: "bedford-park",
        value: "Bedford Park",
    },
    {
        id: "bridgewater",
        value: "Bridgewater",
    },
    {
        id: "beverley",
        value: "Beverley",
    },
    {
        id: "brompton",
        value: "Brompton",
    },
    {
        id: "bowden",
        value: "Bowden",
    },
    {
        id: "christies-beach",
        value: "Christies Beach",
    },
    {
        id: "crafers",
        value: "Crafers",
    },
    {
        id: "cherry-gardens",
        value: "Cherry Gardens",
    },
    {
        id: "clarendon",
        value: "Clarendon",
    },
    {
        id: "camden-park",
        value: "Camden Park",
    },
    {
        id: "cleland",
        value: "Cleland",
    },
    {
        id: "croydon",
        value: "Croydon",
    },
    {
        id: "dulwich",
        value: "Dulwich",
    },
    {
        id: "edwardstown",
        value: "Edwardstown",
    },
   
    {
        id: "elizabeth",
        value: "Elizabeth",
    },
    {
        id: "eden-hills",
        value: "Eden Hills",
    },
    {
        id: "elwomple",
        value: "Elwomple",
    },
    {
        id: "fullarton",
        value: "Fullarton",
    },
    {
        id: "fulham",
        value: "Fulham",
    },
   
    {
        id: "gilberton",
        value: "Gilberton",
    },
    {
        id: "glenelg south",
        value: "Glenelg South",
    },
    {
        id: "glenelg-north",
        value: "Glenelg North",
    },
    {
        id: "glenelg-east",
        value: "Glenelg East",
    },
    {
        id: "glandore",
        value: "Glandore",
    },
    
    {
        id: "glenelg",
        value: "Glenelg",
    },
    {
        id: "glenside",
        value: "Glenside",
    },
    {
        id: "greenfields",
        value: "Greenfields",
    },
    {
        id: "goodwood",
        value: "Goodwood",
    },
    {
        id: "grange",
        value: "Grange",
    },
    {
        id: "hackney",
        value: "Hackney",
    },
    {
        id: "hope-valley",
        value: "Hope Valley",
    },
    {
        id: "hyde-park",
        value: "Hyde Park",
    },
    {
        id: "hove",
        value: "Hove",
    },
    {
        id: "hilton",
        value: "Hilton",
    },
    {
        id: "henley-beach",
        value: "Henley Beach",
    },
    {
        id: "happy-valley",
        value: "Happy Valley",
    },
    {
        id: "Heathfield",
        value: "Heathfield",
    },
    {
        id: "Highgate",
        value: "Highgatea",
    },
    {
        id: "hindmarsh",
        value: "Hindmarsh",
    },
    {
        id: "jervois",
        value: "Jervois",
    },
    {
        id: "kangarilla",
        value: "Kangarilla",
    },
    {
        id: "kensington-gardens",
        value: "Kensington Gardens",
    },
    {
        id: "kings-park",
        value: "Kings Park",
    },
    {
        id: "kent-town",
        value: "Kent Town",
    },
    {
        id: "kensington-park",
        value: "Kensington Park",
    },
    {
        id: "keswick",
        value: "Keswick",
    },
    {
        id: "kilkenny",
        value: "Kilkenny",
    },
    {
        id: "kingswood",
        value: "Kingswood",
    },
    {
        id: "lockleys",
        value: "Lockleys",
    },
    {
        id: "largs-north",
        value: "Largs North",
    },
    {
        id: "malvern",
        value: "Malvern",
    },
    {
        id: "mcLaren-vale",
        value: "McLaren Vale",
    },
    {
        id: "mount-george",
        value: "Mount Georgea",
    },
    {
        id: "mawson-lakes",
        value: "Mawson Lakes",
    },
    {
        id: "medindie",
        value: "Medindie",
    },
    {
        id: "mitcham",
        value: "Mitcham",
    },
    {
        id: "mount-lofty",
        value: "Mount Lofty",
    },
    {
        id: "modbury",
        value: "Modbury",
    },
    {
        id: "moana",
        value: "Moana",
    },
    {
        id: "munno-para",
        value: "Munno Para",
    },
    {
        id: "norwood",
        value: "Norwood",
    },
    {
        id: "north-adelaide",
        value: "North Adelaide",
    },
    {
        id: "north-haven",
        value: "North Haven",
    },
    {
        id: "onkaparinga-hills",
        value: "Onkaparinga Hills",
    },
    {
        id: "outer-harbour",
        value: "Outer Harbour",
    },
    {
        id: "osborne",
        value: "Osborne",
    },
    {
        id: "para-hills-west",
        value: "Para Hills West",
    },{
        id: "piccadilly",
        value: "Piccadilly",
    },
    {
        id: "pooraka",
        value: "Pooraka",
    },
    {
        id: "pasadena",
        value: "Pasadena",
    },
    {
        id: "parkside",
        value: "Parkside",
    },
    {
        id: "prospect",
        value: "Prospect",
    },
    {
        id: "penrice",
        value: "Penrice",
    },
    {
        id: "plympton",
        value: "Plympton",
    },
    {
        id: "reynella",
        value: "Reynella",
    },
    {
        id: "richmond",
        value: "Richmond",
    },
    {
        id: "Ridgehaven",
        value: "Ridgehaven",
    },
    {
        id: "rosslyn-park",
        value: "Rosslyn Park",
    },
    {
        id: "royal-park",
        value: "Royal Park",
    },
    {
        id: "springfield",
        value: "Springfield",
    },
    {
        id: "shepherds-hill",
        value: "Shepherds Hill",
    },
    {
        id: "stockwell",
        value: "Stockwell",
    },
    {
        id: "smithfield",
        value: "Smithfield",
    },
    {
        id: "sheidow-park",
        value: "Sheidow Park",
    },
    {
        id: "seacombe-gardens",
        value: "Seacombe Gardens",
    },
    {
        id: "seaford",
        value: "Seaford",
    },
    {
        id: "stirling",
        value: "Stirling",
    },
    {
        id: "stepney",
        value: "Stepney",
    },
    {
        id: "thebarton",
        value: "Thebarton",
    },
    {
        id: "torrensville",
        value: "Torrensville",
    },
    {
        id: "toorak-gardens",
        value: "Toorak Gardens",
    },
    {
        id: "otrrens-park",
        value: "Torrens Park",
    },
    {
        id: "tailem-bend",
        value: "Tailem Bend",
    },
    {
        id: "taperoo",
        value: "Taperoo",
    },
    {
        id: "unley",
        value: "Unley",
    },
    {
        id: "west-croydon",
        value: "West Croydon",
    },
    {
        id: "wellington",
        value: "Wellington",
    },
    {
        id: "woods-point",
        value: "Woods Point",
    },
    {
        id: "woodcroft",
        value: "Woodcroft",
    },
    {
        id: "west-beach",
        value: "West Beach",
    },
    {
        id: "walkerville",
        value: "Walkerville",
    },
    {
        id: "woodville",
        value: "Woodville",
    },

];





export const PERTH_SUBURB = [
    {
        id: "applecross",
        value: "Applecross",
    },
    {
        id: "ardross",
        value: "Ardross",
    },
    {
        id: "atwell",
        value: "Atwell",
    },
    {
        id: "ascot",
        value: "Ascot",
    },
    {
        id: "ashby",
        value: "Ashby",
    },
    {
        id: "aveley",
        value: "Aveley",
    },
    {
        id: "bentley",
        value: "Bentley",
    },
    {
        id: "beaconsfield",
        value: "Beaconsfield",
    },
    {
        id: "bayswater",
        value: "Bayswater",
    },
    {
        id: "belmont",
        value: "Belmont",
    },
    {
        id: "bassendean",
        value: "Bassendean",
    },
    {
        id: "bedford",
        value: "Bedford",
    },
    {
        id: "burswood",
        value: "Burswood",
    },
    {
        id: "bicton",
        value: "Bicton",
    },
    {
        id: "cockburn-central",
        value: "Cockburn Central",
    },
    {
        id: "caversham",
        value: "Caversham",
    },
    {
        id: "churchlands",
        value: "YChurchlands",
    },
    {
        id: "cottesloe",
        value: "Cottesloe",
    },
    {
        id: "city-beach",
        value: "City Beach",
    },
    {
        id: "canning-vale",
        value: "Canning Vale",
    },
    {
        id: "clifton-hill",
        value: "Clifton Hill",
    },
    {
        id: "claremont",
        value: "Claremont",
    },
    {
        id: "carlisle",
        value: "Carlisle",
    },
    {
        id: "crawley",
        value: "Crawley",
    },
    {
        id: "coolbinia",
        value: "Coolbinia",
    },
    {
        id: "como",
        value: "Como",
    },
    {
        id: "dalkeith",
        value: "Dalkeith",
    },
    {
        id: "duncraig",
        value: "Duncraig",
    },
    {
        id: "dayton",
        value: "Dayton",
    },
    {
        id: "dianella",
        value: "Dianella",
    },
    {
        id: "east-victoria-park",
        value: "East Victoria ParkYagoona",
    },
    {
        id: "east-perth",
        value: "East Perth",
    },
    {
        id: "east-fremantle",
        value: "East Fremantle",
    },
    {
        id: "embleton",
        value: "Embleton",
    },
    {
        id: "fremantle",
        value: "Fremantle",
    },
    {
        id: "floreat",
        value: "Floreat",
    },
    {
        id: "guildford",
        value: "Guildford",
    },
    {
        id: "gracetown",
        value: "Gracetown",
    },
    {
        id: "greenwood",
        value: "Greenwood",
    },
    
    {
        id: "henley-brook",
        value: "Henley Brook",
    },
    {
        id: "hillarys",
        value: "Hillarys",
    },
    {
        id: "hazelmere",
        value: "Hazelmere",
    },
    {
        id: "highgate",
        value: "Highgate",
    },
    {
        id: "huntingdale",
        value: "Huntingdale",
    },
    {
        id: "inglewood",
        value: "Inglewood",
    },
    {
        id: "jandakot",
        value: "Jandakot",
    },
    {
        id: "kings-park",
        value: "Kings ParkYagoona",
    },
    {
        id: "kensington",
        value: "Kensington",
    },
    {
        id: "kewdale",
        value: "Kewdale",
    },
    {
        id: "leederville",
        value: "Leederville",
    },
    {
        id: "margaret-river",
        value: "Margaret River",
    },
    {
        id: "mount-hawthorn",
        value: "Mount Hawthorn",
    },
    {
        id: "mosman-park",
        value: "Mosman Park",
    },
    {
        id: "midland",
        value: "Midland",
    },
    {
        id: "morley",
        value: "Morley",
    },
    {
        id: "maylands",
        value: "Maylands",
    },
    {
        id: "mount-lawley",
        value: "Mount Lawley",
    },
    {
        id: "mount-pleasant",
        value: "Mount Pleasant",
    },
    {
        id: "north-perth",
        value: "North Perth",
    },
    {
        id: "north-fremantle",
        value: "North Fremantle",
    },
    {
        id: "nedlands",
        value: "Nedlands",
    },
    {
        id: "northbridge",
        value: "Northbridge",
    },
    {
        id: "osborne-park",
        value: "Osborne Park",
    },
    {
        id: "parkwood",
        value: "Parkwood",
    },
    {
        id: "padbury",
        value: "Padbury",
    },
    {
        id: "palmyra",
        value: "Palmyra",
    },
    {
        id: "perth-cbd",
        value: "Perth CBD",
    },
    {
        id: "rivervale",
        value: "Rivervale",
    },
    {
        id: "redcliffe",
        value: "Redcliffe",
    },
    {
        id: "south-fremantle",
        value: "South Fremantle",
    },
    {
        id: "southern-river",
        value: "Southern River",
    },
    {
        id: "subiaco",
        value: "Subiaco",
    },
    {
        id: "sorrento",
        value: "Sorrento",
    },
    {
        id: "shenton-park",
        value: "Shenton Park",
    },
    {
        id: "south-guildford",
        value: "South Guildford",
    },
    {
        id: "south-perth",
        value: "South Perth",
    },
    {
        id: "scarborough",
        value: "Scarborough",
    },
    {
        id: "sinagra",
        value: "Sinagra",
    },
    {
        id: "south-lake",
        value: "South Lake",
    },
    {
        id: "stirling",
        value: "Stirling",
    },
    {
        id: "success",
        value: "Success",
    },
    {
        id: "thornlie",
        value: "Thornlie",
    },
    {
        id: "the-vines",
        value: "The Vines",
    },
    {
        id: "tapping",
        value: "Tapping",
    },
    {
        id: "treeby",
        value: "Treeby",
    },
    {
        id: "viveash",
        value: "Viveash",
    },
    {
        id: "victoria-park",
        value: "Victoria Park",
    },
    {
        id: "woodlands",
        value: "Woodlands",
    },
    {
        id: "west-perth",
        value: "West Perth",
    },
    
    {
        id: "wilyabrup",
        value: "Wilyabrup",
    },
    {
        id: "wembley-downs",
        value: "Wembley Downs",
    },
    {
        id: "woodlands",
        value: "Woodlands",
    },
    {
        id: "wanneroo",
        value: "Wanneroo",
    },
];




    
        /*export const COLORS = {
    white: 'hsl(0deg 0% 100%)',
    gray: {
        100: 'hsl(185deg 5% 95%)',
        300: 'hsl(190deg 5% 80%)',
        500: 'hsl(196deg 4% 60%)',
        700: 'hsl(220deg 5% 40%)',
        900: 'hsl(220deg 3% 20%)',
        1000: 'hsl(40deg, 5%, 13%)',
        1100: 'hsl(19deg 7% 56%)',
    },

    primary: 'hsl(340deg 64% 47%)',
    secondary: 'hsl(240deg 60% 63%)',
}; */

export const COLORS = {
    white: 'hsl(0deg 0% 100%)',
    offblack: 'hsl(24deg 5% 6%)',
    gray: {
      100: 'hsl(40deg 12% 95%)',
      300: 'hsl(35deg 8% 80%)',
      500: 'hsl(30deg 4% 60%)',
      700: 'hsl(28deg 5% 40%)',
      900: 'hsl(24deg 6% 16%)',
    },
    primary: 'hsl(224deg 30% 40%)',
    secondary: 'hsl(180deg 34% 37%)',
    urgent: 'hsl(0deg 55% 54%)',
  };

export const WEIGHTS = {
    normal: 500,
    medium: 600,
    bold: 800,
}

export const BREAKPOINTS = {
    phone: 600,
    tablet: 950,
    laptop: 1600,
    tabletMin: 550,
    laptopMin: 1100,
    desktopMin: 1500,
}

export const QUERIES = {
    phoneAndSmaller: `(max-width: ${BREAKPOINTS.phone / 16}rem)`,
    tabletAndSmaller: `(max-width: ${BREAKPOINTS.tablet /16}rem)`,
    laptopAndSmaller: `(max.width: ${BREAKPOINTS.laptop / 16}rem)`,
    tabletAndUp: `(min-width: ${BREAKPOINTS.tabletMin / 16}rem)`,
    laptopAndUp: `(min-width: ${BREAKPOINTS.laptopMin /16}rem)`,
    desktopAndUp: `(min-width: ${BREAKPOINTS.desktopMin /16}rem)`,
    tabletOnly: `
        (min-width: ${BREAKPOINTS.tabletMin /16}rem) and
        (max-width: ${(BREAKPOINTS.laptopMin - 1) / 16}rem)`,
};


export const FAMILIES = {
    serif: '"Crimson Pro", Georgia, serif',
    sansSerif:
      '"Helvetica Neue", Helvetica, "Franklin Gothic Medium", "Franklin Gothic", "ITC Franklin Gothic", sans-serif',
    logo: 'Chomsky',
  };
  
