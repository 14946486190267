export const franchiseListReducer = (state = { franchises: []}, action) => {
    switch (action.type) {
        case 'FRANCHISE_LIST_REQUEST':
            return { franchises: [] }
        case 'FRANCHISE_LIST_SUCCESS':
            return {
                franchises: action.payload,
            }
        case 'FRANCHISE_LIST_FAIL':
            return { error: action.payload }
        default:
            return state
    }
}


export const franchiseByIdReducer = (state = { franchise: []}, action) => {
    switch (action.type) {
        case 'FRANCHISE_DETAILS_BY_ID_REQUEST':
            return {...state}
        case 'FRANCHISE_DETAILS_BY_ID_SUCCESS':
            return { franchise: action.payload }
        default:
            return state
    }
}



export const franchiseCreateReducer = ( state = {}, action) => {
    switch (action.type) {
        case 'FRANCHISE_CREATE_SUCCESS':
            return { franchise: action.payload }
        default:
            return state
    }
}

export const updateFranchiseReducer = ( state = {}, action) => {
    switch (action.type) {
        case 'FRANCHISE_UPDATE_SUCCESS':
            return { franchise: action.payload }
        default:
            return state
    }
}


export const deleteFranchiseReducer = ( state = {}, action) => {
    switch (action.type) {
        case 'DELETE_FRANCHISE_SUCCESS':
            return { franchise: action.payload }
        default:
            return state
    }
}


