import React from 'react';
import "./sidebar.scss";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import StoreIcon from "@mui/icons-material/Store";
import InsertChartIcon from "@mui/icons-material/InsertChart";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import SettingsSystemDaydreamOutlinedIcon from "@mui/icons-material/SettingsSystemDaydreamOutlined";
import PsychologyOutlinedIcon from "@mui/icons-material/PsychologyOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { Link, useNavigate } from "react-router-dom";
import { DarkModeContext } from "../../context/darkModeContext";
import { useContext } from "react";
import { logout } from "../../actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

const Sidebar = () => {
  //const { dispatch } = useContext(DarkModeContext);

  const dispatch = useDispatch();

  const logoutHandler = () => {
        dispatch(logout())
  }

  const navigate = useNavigate();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
      if(!userInfo) {
          navigate('/')
      }
  })

  return (
    <div className="sidebar">
      <div className="top">
        <Link to="/" style={{ textDecoration: "none" }}>
          <span className="logo">Admin</span>
        </Link>
      </div>
      <hr />
      <div className="center">
        <ul>
          <p className="title">MAIN</p>

          <Link to="/" style={{ textDecoration: "none" }}>
            <li>
              <DashboardIcon className="icon" />
              <span>Dashboard</span>
            </li>
          </Link>

          <p className="title">LISTS</p>
          <Link to="/users" style={{ textDecoration: "none" }}>
            <li>
              <PersonOutlineIcon className="icon" />
              <span>Users</span>
            </li>
          </Link>
          <Link to="/products" style={{ textDecoration: "none" }}>
            <li>
              <StoreIcon className="icon" />
              <span>Products</span>
            </li>
          </Link>
          <Link to="/orders" style={{ textDecoration: "none" }}>
            <li>
              <StoreIcon className="icon" />
              <span>Orders</span>
            </li>
          </Link>
          <Link to="/subscribers" style={{ textDecoration: "none" }}>
            <li>
              <StoreIcon className="icon" />
              <span>Subscribers</span>
            </li>
          </Link>
          <Link to="/franchises" style={{ textDecoration: "none" }}>
            <li>
              <StoreIcon className="icon" />
              <span>Franchises</span>
            </li>
          </Link>
          <Link to="/utils" style={{ textDecoration: "none" }}>
            <li>
              <StoreIcon className="icon" />
              <span>Utils</span>
            </li>
          </Link>
          <Link to="/deals" style={{ textDecoration: "none" }}>
            <li>
              <StoreIcon className="icon" />
              <span>Deals</span>
            </li>
          </Link>
          <Link to="/raffles" style={{ textDecoration: "none" }}>
            <li>
              <StoreIcon className="icon" />
              <span>Raffles</span>
            </li>
          </Link>
          <Link to="/promos" style={{ textDecoration: "none" }}>
            <li>
              <StoreIcon className="icon" />
              <span>Promos</span>
            </li>
          </Link>
          <Link to="/wholesellers" style={{ textDecoration: "none" }}>
            <li>
              <StoreIcon className="icon" />
              <span>Wholesellers</span>
            </li>
          </Link>
          <Link to="/newsletter" style={{ textDecoration: "none" }}>
            <li>
              <StoreIcon className="icon" />
              <span>Newsletter</span>
            </li>
          </Link>
          <li>
            <ExitToAppIcon className="icon" />
            <span onClick={logoutHandler}>Logout</span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
