export const productListReducer = (state = { products: []}, action) => {
    switch (action.type) {
        case 'PRODUCT_LIST_REQUEST':
            return { products: [] }
        case 'PRODUCT_LIST_SUCCESS':
            return {
                products: action.payload,
            }
        case 'PRODUCT_LIST_FAIL':
            return { error: action.payload }
        default:
            return state
    }
}



export const venueListByTypeReducer = (state = { venuesByType: []}, action) => {
    switch (action.type) {
        case 'VENUE_LIST_BY_TYPE_REQUEST':
            return { venuesByType: [] }
        case 'VENUE_LIST_BY_TYPE_SUCCESS':
            return {
                venuesByType: action.payload,
            }
        case 'VENUE_LIST_BY_TYPE_FAIL':
            return { error: action.payload }
        default:
            return state
    }
}


export const venueListBySubTypeReducer = (state = { venuesBySubType: []}, action) => {
    switch (action.type) {
        case 'VENUE_LIST_BY_TYPE_REQUEST':
            return { venuesBySubType: [] }
        case 'VENUE_LIST_BY_TYPE_SUCCESS':
            return {
                venuesBySubType: action.payload,
            }
        case 'VENUE_LIST_BY_TYPE_FAIL':
            return { error: action.payload }
        default:
            return state
    }
}




export const productByIdReducer = (state = { product: []}, action) => {
    switch (action.type) {
        case 'PRODUCT_DETAILS_BY_ID_REQUEST':
            return {...state}
        case 'PRODUCT_DETAILS_BY_ID_SUCCESS':
            return { product: action.payload }
        default:
            return state
    }
}


export const venueDetailsReducer = (state = { venue: []}, action) => {
    switch (action.type) {
        case 'VENUE_DETAILS_REQUEST':
            return {...state}
        case 'VENUE_DETAILS_SUCCESS':
            return { venue: action.payload }
        default:
            return state
    }
}


export const productCreateReducer = ( state = {}, action) => {
    switch (action.type) {
        case 'PRODUCT_CREATE_SUCCESS':
            return { venue: action.payload }
        default:
            return state
    }
}

export const updateProductReducer = ( state = {}, action) => {
    switch (action.type) {
        case 'PRODUCT_UPDATE_SUCCESS':
            return { product: action.payload }
        default:
            return state
    }
}


export const deleteVenueReducer = ( state = {}, action) => {
    switch (action.type) {
        case 'DELETE_PRODUCT_SUCCESS':
            return { product: action.payload }
        default:
            return state
    }
}


export const venueReviewCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case 'CREATE_VENUE_REVIEW_SUCCESS':
            return { success: true}
        case 'CREATE_REVIEW_REVIEW_FAIL':
            return { error: action.payload }
        default:
            return state
    }
}



export const updatePhoneMetricsReducer = ( state = { phone: [] }, action ) =>
 {
    switch(action.type) {
        case 'UPDATE_PHONE_METRIC_SUCCESS':
            return { phone: action.payload }
        default:
            return state
    }
}


export const updateEmailMetricsReducer = ( state = { email: [] }, action ) =>
 {
    switch(action.type) {
        case 'UPDATE_PHONE_METRIC_SUCCESS':
            return { email: action.payload }
        default:
            return state
    }
}


export const updateWebsiteMetricsReducer = ( state = { website: [] }, action ) =>
 {
    switch(action.type) {
        case 'UPDATE_PHONE_METRIC_SUCCESS':
            return { website: action.payload }
        default:
            return state
    }
}


export const getMetricsByIdReducer = ( state = { metrics: {}}, action ) => 
{
    switch (action.type) {
        case 'GET_METRICS_BY_ID_SUCCESS':
            return {
                metrics: action.payload,
            }
        default:
            return state
    }
        
}

