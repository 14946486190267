import React from 'react';
import styled from 'styled-components';
import { QUERIES } from '../../constants';
import {
    Search,
    Instagram,
    Facebook,
    Twitter,
    User,
    MessageCircle,
    Menu,
    MapPin,
    CornerUpLeft,
    FileText,
    Phone,
    ShoppingBag,
    ShoppingCart,
    ChevronDown,
    ChevronRight,
    Triangle,
    X,
    Star,
    ChevronUp,
    Plus,
    Minus,
} from 'react-feather';

const icons = {
    user: User,
    chat: MessageCircle,
    track: MapPin,
    return: CornerUpLeft,
    quote: FileText,
    search: Search,
    menu: Menu,
    insta: Instagram,
    twit: Twitter,
    face: Facebook,
    phone: Phone,
    'chevron-right': ChevronRight,
    'shopping-cart': ShoppingCart,
    'shopping-bag': ShoppingBag,
    'chevron-down': ChevronDown,
    'chevron-up': ChevronUp,
    triangle: Triangle,
    close: X,
    star: Star,
    plus: Plus,
    minus: Minus,
}

const Icon = ({ id, color, size, strokeWidth, ...delegated }) => {
    const Component = icons[id];

    if (!Component) {
        throw new Error(`No icon found for ID: ${id}`);
    }

    return (
        <Wrapper strokeWidth={strokeWidth} {...delegated}>
            <Component className="compo" color={color} size={size} />
        </Wrapper>
        
    )
};

const Wrapper = styled.div`
    & > svg {
        display: block;
        stroke-width: ${(p) => 
            p.strokeWidth !== undefined ? p.strokeWidth + 'px' : undefined}
        
        
    
    }

    .compo {
        @media ${QUERIES.phoneAndSmaller} {
            height: 30px;
            width: 30px;
        }
    }

    


`;



export default Icon;
