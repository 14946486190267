import React from 'react';
import "./deals.scss"
import Sidebar from "../../components/sidebar/Sidebar"
import Navbar from "../../components/navbar/Navbar"
import DealDatatable from "../../components/dealdatatable/DealDatatable"

const Deal = () => {
  return (
    <div className="list">
      <Sidebar/>
      <div className="listContainer">
        <Navbar/>
        <DealDatatable />
      </div>
    </div>
  )
}

export default Deal;
