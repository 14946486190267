import axios from "axios";

export const listFranchises = (f) => async (dispatch, getState) => {
    try {
        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
              Authorization: `Bearer ${userInfo.token}`,
            },
        }
        dispatch({ type: 'PRODUCT_LIST_REQUEST'});

        const { data } = await axios.get(`/api/v1/franchises`, config)
        const dataRow = data.data.franchises
        dispatch({
            type: 'FRANCHISE_LIST_SUCCESS',
            payload: dataRow,
        })
    } catch (error) {
        dispatch({
            type: 'FRANCHISE_LIST_FAIL',
            payload:
                error.response && error.response.data.message
                    ?error.response.data.message
                    :error.message,
        })
    }
}


export const createFranchise = ({ franchisee, phone_no, email, realDate, address, coordinates }) => async (dispatch, getState) => {
    try {

        const { userLogin: { userInfo } } = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`
            }
        }
        const { data } = await axios.post('/api/v1/franchises', {
            franchisee, phone_no, email, realDate, address, coordinates
        }, config)

        dispatch({
            type: 'FRANCHISE_CREATE_SUCCESS'
        })
    } catch (error) {
        console.log(error)
    }
}

export const updateFranchise = ({ franchiseId, franchisee, phone_no, email, realDate, address, coordinates, status }) => async (dispatch, getState) => {
    try {

        const {
            userLogin: { userInfo }
        } = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            }
        }
        var id = franchiseId
        const { data } = await axios.put(`/api/v1/franchises/${id}`, {
            franchiseId, franchisee, phone_no, email, realDate, address, coordinates, status 
        }, config)
       
        dispatch({
            type: 'FRANCHISE_UPDATE_SUCCESS',
            payload: data,
        })
    } catch (error) {
        console.log(error);
    }
}

export const deleteFranchise = (id) => async (dispatch, getState) => {
    try {
        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
              Authorization: `Bearer ${userInfo.token}`,
            },
        }
        const { data } = await axios.delete(`/api/v1/franchises/${id}`, config);
        dispatch({
            type: 'FRANCHISE_PRODUCT_SUCCESS',
            payload: data,
        })
    } catch (error) {
        console.log(error);
    }
}


export const getFranchiseById = (id) => async (dispatch, getState) => {
    try {
        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
              Authorization: `Bearer ${userInfo.token}`,
            },
        }
        dispatch({ type: 'FRANCHISE_DETAILS_BY_ID_REQUEST'});
        const { data } = await axios.get(`/api/v1/franchises/franchisedetails/${id}`, config);

        dispatch({
            type: 'FRANCHISE_DETAILS_BY_ID_SUCCESS',
            payload: data.data.franchise,
        })
    } catch (error) {
        console.log(error);
    }
}




