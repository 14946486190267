

//temporary data
export const userRows = [
  {
    id: 1,
    username: "Snow",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    status: "active",
    email: "1snow@gmail.com",
    age: 35,
  },
  {
    id: 2,
    username: "Jamie Lannister",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    email: "2snow@gmail.com",
    status: "passive",
    age: 42,
  },
  {
    id: 3,
    username: "Lannister",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    email: "3snow@gmail.com",
    status: "pending",
    age: 45,
  },
  {
    id: 4,
    username: "Stark",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    email: "4snow@gmail.com",
    status: "active",
    age: 16,
  },
  {
    id: 5,
    username: "Targaryen",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    email: "5snow@gmail.com",
    status: "passive",
    age: 22,
  },
  {
    id: 6,
    username: "Melisandre",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    email: "6snow@gmail.com",
    status: "active",
    age: 15,
  },
  {
    id: 7,
    username: "Clifford",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    email: "7snow@gmail.com",
    status: "passive",
    age: 44,
  },
  {
    id: 8,
    username: "Frances",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    email: "8snow@gmail.com",
    status: "active",
    age: 36,
  },
  {
    id: 9,
    username: "Roxie",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    email: "snow@gmail.com",
    status: "pending",
    age: 65,
  },
  {
    id: 10,
    username: "Roxie",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    email: "snow@gmail.com",
    status: "active",
    age: 65,
  },
];




export const dealColumns = [
  { field: "deal_id", headerName: "ID", width: 70 },
  {
    field: "name",
    headerName: "Name",
    width: 230,
  },

  {
    field: "original_price",
    headerName: "original_price",
    width: 100,
  },
  {
    field: "deal_price",
    headerName: "deal_price",
    width: 100,
  },
  {
    field: "month",
    headerName: "month",
    width: 100,
  },
  {
    field: "day",
    headerName: "day",
    width: 100,
  },
  {
    field: "year",
    headerName: "year",
    width: 100,
  },
  {
    field: "status",
    headerName: "Active",
    width: 100,
  },
];


export const raffleColumns = [
  { field: "raffle_id", headerName: "ID", width: 70 },
  {
    field: "name",
    headerName: "Name",
    width: 230,
  },

  {
    field: "original_price",
    headerName: "original_price",
    width: 100,
  },
  {
    field: "month",
    headerName: "month",
    width: 100,
  },
  {
    field: "day",
    headerName: "day",
    width: 100,
  },
  {
    field: "year",
    headerName: "year",
    width: 100,
  },
  {
    field: "status",
    headerName: "Active",
    width: 100,
  },
];


export const promoColumns = [
  { field: "id", headerName: "ID", width: 70 },
  {
    field: "codes",
    headerName: "Codes",
    width: 230,
  },
  {
    field: "percent",
    headerName: "Percent",
    width: 100,
  },
  {
    field: "status",
    headerName: "Status",
    width: 100,
  },
];


export const productColumns = [
  { field: "id", headerName: "ID", width: 70 },
  {
    field: "name",
    headerName: "Name",
    width: 230,
  },

  {
    field: "category",
    headerName: "Category",
    width: 150,
  },
  {
    field: "brand",
    headerName: "Brand",
    width: 160,
    /*renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.status}`}>
          {params.row.status}
        </div>
      );
    },*/
  },
];




export const franchiseColumns = [
  { field: "id", headerName: "ID", width: 70 },
  {
    field: "franchisee",
    headerName: "Franchisee",
    width: 200,
  },

  {
    field: "phone_no",
    headerName: "Phone_no",
    width: 150,
  },
  {
    field: "email",
    headerName: "Email",
    width: 150,
  },
  {
    field: "registered_date",
    headerName: "Registered Date",
    width: 150,
  },
  {
    field: "coordinates",
    headerName: "Co-ordinates",
    width: 150,
  },
  
  {
    field: "address",
    headerName: "Address",
    width: 160,
    /*renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.status}`}>
          {params.row.status}
        </div>
      );
    },*/
  },
];



export const userColumns = [
  { field: "id", headerName: "ID", width: 70 },
  {
    field: "name",
    headerName: "Name",
    width: 180,
  },

  {
    field: "email",
    headerName: "Category",
    width: 230,
  },
  {
    field: "admin",
    headerName: "isAdmin?",
    width: 120,
    /*renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.status}`}>
          {params.row.status}
        </div>
      );
    },*/
  },
];


var addressNames = ['city: ', 'country: ', 'Address Line 1: ', 'Address Line 2: ', 'Postal Code: ', 'State: '];


export const ordersColumns = [
  { field: "id", headerName: "ID", width: 70 },
  {
    field: "customer_email",
    headerName: "Customer Email",
    width: 250,
  },

  {
    field: "ordered_products",
    headerName: "Ordered Products",
    width: 230,
    renderCell: (params) => (
      <ul className="flex">
        {params.row.ordered_products.map((product, index) => (
          <li key={index}>{product[0]} {product[1]} ${product[2]}</li>
        ))}
      </ul>
    ),
  },
  {
    field: "total_amount",
    headerName: "Total Amount",
    width: 100, 
    /*renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.status}`}>
          {params.row.status}
        </div>
      );
    },*/
  },
  {
    field: "shipping",
    headerName: "Shipping Details",
    width: 290,
    minWidth: 300,
    maxWidth: 600,
    renderCell: (params) => (
      <ul className="flex">
        {params.row.shipping_address.map((product, index) => (
          <li key={index}>{addressNames[index].concat(product)}</li>
        ))}
      </ul>
    ),
  },
  {
    field: "ordered_date",
    headerName: "Ordered Date",
    width: 230,
  },
  
  {
    field: "payment_status",
    headerName: "Payment Status",
    width: 130,
  },
  {
    field: "order_status",
    headerName: "Order Status",
    width: 130,
  },
];



export const subscriberColumns = [
  { field: "customer_id", headerName: "ID", width: 70 },
  {
    field: "customer_email",
    headerName: "Customer Email",
    width: 250,
  },

  {
    field: "active_status",
    headerName: "Active Status",
    width: 150,
  },

  {
    field: "startdate",
    headerName: "Start Date",
    width: 230,
  },
  
  {
    field: "enddate",
    headerName: "End Date",
    width: 130,
  },

];