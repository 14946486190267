import React from "react";
import "./newsletter.scss"
import Sidebar from "../../components/sidebar/Sidebar"
import Navbar from "../../components/navbar/Navbar"
import NewsDatatable from "../../components/newstable/NewsDatatable";


const Newsletter = () => {
    return (
        <div className="list">
            <Sidebar/>
            <div className="listContainer">
            <Navbar/>
            <NewsDatatable />
            </div>
        </div>
    )
}

export default Newsletter;
