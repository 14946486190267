import axios from "axios";


export const listUtils = () => async (dispatch, getState) => {
    try {

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
              Authorization: `Bearer ${userInfo.token}`,
            },
        }
        dispatch({ type: 'UTILS_LIST_REQUEST'});

        const { data } = await axios.get(`/api/v1/utils`, config);
        const dataRow = data.data.utils
     
        dispatch({
            type: 'UTILS_LIST_SUCCESS',
            payload: dataRow,
        })
    } catch (error) {
        dispatch({
            type: 'UTILS_LIST_FAIL',
            payload:
                error.response && error.response.data.message
                    ?error.response.data.message
                    :error.message,
        })
    }
}



export const updateCategory = (utilArray) => async (dispatch, getState) => {
    try {

        const {
            userLogin: { userInfo }
        } = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            }
        }

        const { data } = await axios.put(`/api/v1/utils/category`, {
            utilArray
        }, config)
        //console.log(images)
        dispatch({
            type: 'CATEGORY_UPDATE_SUCCESS',
            payload: data,
        })
    } catch (error) {
        console.log(error);
    }
}



export const updateBody = (utilArray) => async (dispatch, getState) => {
    try {

        const {
            userLogin: { userInfo }
        } = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            }
        }

        const { data } = await axios.put(`/api/v1/utils/body`, {
            utilArray
        }, config)
        //console.log(images)
        dispatch({
            type: 'BODY_UPDATE_SUCCESS',
            payload: data,
        })
    } catch (error) {
        console.log(error);
    }
}




export const updateType = (utilArray) => async (dispatch, getState) => {
    try {

        const {
            userLogin: { userInfo }
        } = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            }
        }

        const { data } = await axios.put(`/api/v1/utils/type`, {
            utilArray
        }, config)
        //console.log(images)
        dispatch({
            type: 'TYPE_UPDATE_SUCCESS',
            payload: data,
        })
    } catch (error) {
        console.log(error);
    }
}



export const updateSubCategory = (utilArray) => async (dispatch, getState) => {
    try {

        const {
            userLogin: { userInfo }
        } = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            }
        }

        const { data } = await axios.put(`/api/v1/utils/subcategory`, {
            utilArray
        }, config)
        //console.log(images)
        dispatch({
            type: 'SUBCATEGORY_UPDATE_SUCCESS',
            payload: data,
        })
    } catch (error) {
        console.log(error);
    }
}



export const updateBrand = (utilArray) => async (dispatch, getState) => {
    try {

        const {
            userLogin: { userInfo }
        } = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            }
        }

        const { data } = await axios.put(`/api/v1/utils/brand`, {
            utilArray
        }, config)
        //console.log(images)
        dispatch({
            type: 'SUBCATEGORY_UPDATE_SUCCESS',
            payload: data,
        })
    } catch (error) {
        console.log(error);
    }
}
