import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import slugify from 'slugify';

//import Header from '../components/Header';
import { WEIGHTS, QUERIES, COLORS, NavLinks, AREA, VENUE, SUBTYPE, SUBURB, MEL_SUBURB, SYD_SUBURB, BRIS_SUBURB, ADEL_SUBURB, PERTH_SUBURB } from '../../constants';
import {
    updateCategory,
    listUtils,
    updateBody,
    updateBrand,
    updateType,
    updateSubCategory,
} from '../../actions/utilActions';
import { useDispatch, useSelector } from 'react-redux';
import Sidebar from '../../components/sidebar/Sidebar';
import Navbar from '../../components/navbar/Navbar';
import { useNavigate } from 'react-router-dom';
import Icon from '../../components/Icon';


const MainWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 150px;
`;

const GridWrapper = styled.div`
    display: grid;
    grid-template-columns:
    repeat(auto-fit, minmax(320px, 1fr));
    gap: 52px;
    padding: 16px 32px;
    @media ${QUERIES.laptopAndUp} {
        padding: 16px 82px;
    }
`;



const Label = styled.label`
    padding: 20px 0px 10px 0px;
    font-size: 1rem;
    font-weight: ${WEIGHTS.medium};
    color: #023E8A;

`;

const HugeLabel = styled(Label)`
    font-size: 3rem;
    padding-left: 42px;
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;


const Input = styled.input`
    width: 250px;
    height: 40px;
    font-size: 1rem;
    border-radius: 4px;
    color: grey;
    outline: none;

    &:focus {
        border-color: #0081ba;
    }
`;



const Button = styled.button`
    width: 160px;
    padding: 10px;
    margin: 8px;
    border-radius: 5px;
    border: 1px solid #023E8A;
    color: white;
    background: #023E8A; 
    cursor: pointer;
`;


const TableWrapper2 = styled.div`
    padding: 20px 20px 0px 20px;
    overflow-x: scroll;
    background: #023E8A; 
    padding: 30px 20px;
    border-radius: 8px;
    height: auto;

    @media ${QUERIES.laptopAndUp} {
        padding-top: 20px;
        overflow: hidden;
    }
`;


const Table2 = styled.table`
    border: none !important;
`;


const TableHead = styled.thead`

`;


const TableRow = styled.tr`
    
`;

const TableBody = styled.tbody`
    
`;




const TableHeader2 = styled.th`
    background: #023E8A;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid white;
    text-align: left;
`;


const TableData2 = styled.td`
    color: white;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 2px solid white;
    background: #023E8A;
    
`;


const Suburbs = styled.div`
    height: auto;
    width: 350px;
    border-radius: 5px;
    background: #023E8A;
    display: flex;
    flex-direction: column;
    padding: 20px;
`;


const Infos = styled.div`
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid white;
`;

const Label2 = styled.label`
    font-size: 0.8rem;
    color: white;
`;

const SecondWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;


const SecondWrapper2 = styled(SecondWrapper)`
    justify-content: center;
    align-items: center;
`;


const Faqss = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
`;

const Faqs = styled(SecondWrapper)`
    gap: 8px;
`;

const TextArea = styled.textarea`

`;


const Container = styled.div`
    display: flex;
    flex-direction: column;

`;


const  Wrapper2 =  styled(Wrapper)`
    padding: 16px 32px;
    @media ${QUERIES.laptopAndUp} {
        padding: 16px 82px;
    }
`;


const SelectWrapper = styled.div`
  position: relative;
  isolation: isolate;
  text-decoration: uppercase;
  display: flex;
  z-index: 1;
  align-items: flex-start;
  flex-direction: column;
  border-radius: 5px;
  background: #023E8A;
  width: 280px;
  padding-bottom: 20px;
  box-shadow: 10px ${COLORS.gray[1100]};
`;


const Select = styled.select`
  position: relative;
  appearance: none;
  filter: none;
  width: 280px;
  font-weight: ${WEIGHTS.normal};
  text-transform: uppercase;
  font-size: 0.8rem;
  padding: 20px 0px 0px 12px;
  border: none;
  border-radius: 20px;
  outline: none;
  color: white;
  autocomplete: off;
  background: transparent;
  z-index: 4;
  cursor: pointer;
  & > :focus {
    outline: 0;
    outline-color: transparent;
    outline-style: none;
  }
`;

const Option = styled.option`
  background: #023E8A;
  font-size: 0.8rem;
  text-transform: uppercase;
  font-weight: ${WEIGHTS.medium};
`;

const ChevronIcon = styled(Icon)`
  position: absolute;
  top: 1px;
  right: 10px;
  bottom: 2px;
  margin: auto;
  width: 24px;
  height: 24px;
  z-index: 3;
  color: white;
  cursor: inherit;
`;

const Container2 = styled.div`
    display: flex;
    flex-direction: column;
    
    @media ${QUERIES.laptopAndUp} {
        
    }
`;


const Utils = () => {
    const [melUtils, setMelUtils] = useState([]);
    const [sydUtils, setSydUtils] = useState([]); 
    const [brisUtils, setBrisUtils] = useState([]);
    const [adelUtils, setAdelUtils] = useState([]);
    const [perthUtils, setPerthUtils] = useState([]);
    const [category, setCategory] = useState();
    const [city, setCity] = useState();


    const [ categoryId, setCategoryId ] = useState();
    const [typeId, setTypeId] = useState();
    const [subCategoryId, setSubCategoryId] = useState();
    const [brandId, setBrandId] = useState();
    const [bodyId, setBodyId] = useState();

    const [ brandValue, setBrandValue] = useState();
    const [ bodyValue, setBodyValue] = useState();

    const [ categoryValue, setCategoryValue] = useState();
    const [typeValue, setTypeValue] = useState();
    const [subCategoryValue, setSubCategoryValue] = useState();
    const [areaValue, setAreaValue] = useState();

    const [alert, setAlert] = useState();
    const [alert2, setAlert2] = useState();
    const [alert3, setAlert3] = useState();
    const [alert4, setAlert4] = useState();
    const [alert5, setAlert5] = useState();


    
    const dispatch = useDispatch();

    const navigate = useNavigate();

    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;

    useEffect(() => {
        if(!userInfo) {
            navigate('/')
        }
    })
    

    const utils = useSelector((state) => state.utilList.utils);

    var id = "1";


    useEffect(() => {
        if(id) {
            dispatch(
                listUtils()
            )   
        }
    }, [id]);


    useEffect(() => {
        if(utils) {
            setMelUtils(utils?.mel_suburb)
            setSydUtils(utils?.syd_suburb)
            setBrisUtils(utils?.bris_suburb)
            setAdelUtils(utils?.adel_suburb)
            setPerthUtils(utils?.perth_suburb)
        }
    }, [utils])


    const categoryHandler = (e) => {
        e.preventDefault();
        if(categoryValue.trim() !== '') {
            setAlert5(false)
            var ids = slugifier(categoryValue);
            var utilArray = [];
            if(utils?.category?.length !== 0){
                utils?.category?.map((arr, index) => (
                    utilArray[index] = arr
                ))
            }

            var lastt = utilArray.length;
            utilArray[lastt] = [ids, categoryValue];
            
            dispatch(
                updateCategory(utilArray)
            )

            refreshPage()
        } else {
            setAlert5(true)
        }
    }


    const bodyHandler = (e) => {
        e.preventDefault();
        if(bodyValue.trim() !== '') {
            setAlert5(false)
            var ids = slugifier(bodyValue);
            var utilArray = [];
            if(utils?.body?.length !== 0){
                utils?.body?.map((arr, index) => (
                    utilArray[index] = arr
                ))
            }

            var lastt = utilArray.length;
            utilArray[lastt] = [ids, bodyValue];
            
            dispatch(
                updateBody(utilArray)
            )

            refreshPage()
        } else {
            setAlert5(true)
        }
    }



    const slugifier = (value) => {
        var link = slugify(value, {
            lower: true,      // convert to lower case, defaults to `false`
            strict: true,
        });

        return link
    }


    const onCategoryChange = (e) => {
        /*const selectedCategory = e.target.value;
        console.log('selected category', selectedCategory)
        const selectedCategoryId = utils?.type?.filter((a) => a[0] === selectedCategory)[0];
        const realType = selectedCategoryId.id? selectedCategoryId.id : selectedCategoryId[0];
        console.log('real type', selectedCategory)
        setCategory(selectedCategory);*/
        const selectedCategory = e.target.value;
        /*if(selectedSubType === 'any') {
            setSubType(selectedSubType);
        } else {
            const selectedSubTypeId = SUBTYPE.filter((t) => t.id === selectedSubType)[0]? SUBTYPE.filter((t) => t.id === selectedSubType)[0] : utils?.subtype?.filter((a) => a[0] === selectedSubType)[0];
            const realSubType = selectedSubTypeId.id? selectedSubTypeId.id : selectedSubTypeId[0];
            console.log(realSubType)
            setSubType(realSubType);
        } */

        const selectedCategoryId = utils?.category.filter((a) => a[0] === selectedCategory)[0];
        const realCategory = selectedCategoryId.id? selectedCategoryId.id : selectedCategoryId[0];
        console.log(realCategory)
        setCategory(realCategory);
    };



    const onCityChange = (e) => {
        const selectedCity = e.target.value;
        console.log(selectedCity)
        const selectedCityId = NavLinks.filter((n) => n.id === selectedCity)[0];
        const realCity = selectedCityId.id
        setCity(realCity);
    };


    const categoryDeleteHandler = async (e) => {
        e.preventDefault();
        var realId = categoryId - 1;
        
        if (realId > -1) { // only splice array when item is found
            utils?.category.splice(realId, 1); // 2nd parameter means remove one item only
            var utilArray = [];
            if(utils?.category?.length > 0) {
                utils?.category?.map((suburbs, index) => (
                    utilArray[index] = suburbs
                ))
            }

            dispatch(
                updateCategory(utilArray)
            )

            refreshPage()
        }
    }



    const bodyDeleteHandler = async (e) => {
        e.preventDefault();
        var realId = bodyId - 1;
        
        if (realId > -1) { // only splice array when item is found
            utils?.body.splice(realId, 1); // 2nd parameter means remove one item only
            var utilArray = [];
            if(utils?.body?.length > 0) {
                utils?.body?.map((suburbs, index) => (
                    utilArray[index] = suburbs
                ))
            }

            dispatch(
                updateBody(utilArray)
            )

            refreshPage()
        }
    }




    const typeHandler = (e) => {
        e.preventDefault();
        if(typeValue.trim() !== '') {
            setAlert5(false)
            var ids = slugifier(typeValue);
            var utilArray = [];
            if(utils?.type?.length !== 0){
                utils?.type?.map((arr, index) => (
                    utilArray[index] = arr
                ))
            }

            var lastt = utilArray.length;
            utilArray[lastt] = [ids, typeValue];
            
            dispatch(
                updateType(utilArray)
            )

            refreshPage()
        } else {
            setAlert5(true)
        }
    }


    const typeDeleteHandler = async (e) => {
        e.preventDefault();
        var realId = typeId - 1;
        
        if (realId > -1) { // only splice array when item is found
            utils?.type?.splice(realId, 1); // 2nd parameter means remove one item only
            var utilArray = [];
            if(utils?.type?.length > 0) {
                utils?.type?.map((suburbs, index) => (
                    utilArray[index] = suburbs
                ))
            }

            dispatch(
                updateType(utilArray)
            )

            refreshPage()
        }
    }



    const subCategoryHandler = (e) => {
        e.preventDefault();
        if(category.trim() !== '') {
            if(subCategoryValue.trim() !== '') {
                setAlert5(false)
                var ids = slugifier(subCategoryValue);
                var utilArray = [];
                if(utils?.sub_category?.length !== 0){
                    utils?.sub_category?.map((arr, index) => (
                        utilArray[index] = arr
                    ))
                }
    
                var lastt = utilArray.length;
                utilArray[lastt] = [ids, subCategoryValue, category];
                
                dispatch(
                    updateSubCategory(utilArray)
                )
    
                refreshPage()
            } else {
                setAlert5(true)
            }
        }
        
    }



    const brandHandler = (e) => {
        e.preventDefault();
        if(category.trim() !== '') {
            if(brandValue.trim() !== '') {
                setAlert5(false)
                var ids = slugifier(brandValue);
                var utilArray = [];
                if(utils?.brand?.length !== 0){
                    utils?.brand?.map((arr, index) => (
                        utilArray[index] = arr
                    ))
                }
    
                var lastt = utilArray.length;
                utilArray[lastt] = [ids, brandValue, category];
                
                dispatch(
                    updateBrand(utilArray)
                )
    
                refreshPage()
            } else {
                setAlert5(true)
            }
        }
        
    }



    const subCategoryDeleteHandler = async (e) => {
        e.preventDefault();
        var realId = subCategoryId - 1;
        
        if (realId > -1) { // only splice array when item is found
            utils?.sub_category?.splice(realId, 1); // 2nd parameter means remove one item only
            var utilArray = [];
            if(utils?.sub_category?.length > 0) {
                utils?.sub_category?.map((suburbs, index) => (
                    utilArray[index] = suburbs
                ))
            }

            dispatch(
                updateSubCategory(utilArray)
            )

            refreshPage()
        }
    }


    const brandDeleteHandler = async (e) => {
        e.preventDefault();
        var realId = brandId - 1;
        
        if (realId > -1) { // only splice array when item is found
            utils?.brand?.splice(realId, 1); // 2nd parameter means remove one item only
            var utilArray = [];
            if(utils?.brand?.length > 0) {
                utils?.brand?.map((suburbs, index) => (
                    utilArray[index] = suburbs
                ))
            }

            dispatch(
                updateBrand(utilArray)
            )

            refreshPage()
        }
    }


    function refreshPage(){ 
        window.location.reload(); 
    }

    console.log(utils?.mel_suburb?.[0][1])

    return (
        <div className="new">
            <Sidebar />
            <div className="newContainer">
                <Navbar />
                <div className="top">
                    <h1>Utils</h1>
                </div>
                <div className="bottom">
                    <MainWrapper>
                        <SecondWrapper>
                            <HugeLabel>Catagory</HugeLabel>
                            <GridWrapper>
                                <Wrapper>
                                    <Input value={categoryValue} onChange={(e) => setCategoryValue(e.target.value)} type='text' placeholder='Category' />
                                    <Button onClick={(e)  => categoryHandler(e)}>Add Category</Button>
                                    <Suburbs>
                                            <Infos>
                                                <Label2>Id</Label2>
                                                <Label2>Catagory</Label2>
                                            </Infos>
                                        {utils?.category?.map((c, index) => (
                                                <Infos key={index}>
                                                    <Label2>{index + 1}</Label2>
                                                    <Label2>{utils?.category?.[index]?.[1]}</Label2>
                                                </Infos>

                                        ))
                                        }
                                    </Suburbs>
                                    <Input value={categoryId} onChange={(e) => setCategoryId(e.target.value)} type='text' placeholder='Category Id' />
                                    <Button onClick={(e) => categoryDeleteHandler(e)}>
                                        Delete
                                    </Button>
                                </Wrapper>
                            </GridWrapper>
                        </SecondWrapper>  

                        {/*<SecondWrapper>
                                <HugeLabel>Type</HugeLabel>
                                <Wrapper2>
                                    <Input value={typeValue} onChange={(e) => setTypeValue(e.target.value)} type='text' placeholder='Suburb' />
                                    <Button onClick={(e)  => typeHandler(e)}>Add type</Button>
                                    <Suburbs>
                                            <Infos>
                                                <Label2>Id</Label2>
                                                <Label2>Type</Label2>
                                            </Infos>
                                        {utils?.type?.map((c, index) => (
                                                <Infos key={index}>
                                                    <Label2>{index + 1}</Label2>
                                                    <Label2>{utils?.type?.[index]?.[1]}</Label2>
                                                </Infos>

                                        ))
                                        }
                                    </Suburbs>
                                    <Input value={typeId} onChange={(e) => setTypeId(e.target.value)} type='text' placeholder='Type Id' />
                                    <Button onClick={(e) => typeDeleteHandler(e)}>
                                        Delete
                                    </Button>
                                </Wrapper2>
                                    </SecondWrapper>*/}

                        <SecondWrapper>
                                <HugeLabel>Sub-Category</HugeLabel>
                                <Wrapper2>
                                    <Label>Select Category</Label>
                                    <Container2>
                                        <Label2>Catagory</Label2>
                                        <SelectWrapper>
                                            <Select
                                                value={category}
                                                onChange={(e) => {
                                                    onCategoryChange(e);
                                                }}
                                            >
                                                <Option value>Select Catagory</Option>
                                                
                                                {utils?.category?.length !== 0?
                                                    <>
                                                        {utils?.category?.map((s, index) => (
                                                            <Option key={index} value={s?.[0]}>
                                                                {s?.[1]}
                                                            </Option>
                                                        ))}
                                                    </>
                                                : null
                                                }
                                            </Select>
                                            <ChevronIcon
                                                id="chevron-down"
                                                size={24}
                                                strokeWidth={1.5}
                                            />
                                        </SelectWrapper>
                                    </Container2>
                                    <Input value={subCategoryValue} onChange={(e) => setSubCategoryValue(e.target.value)} type='text' placeholder='Sub-Category' />
                                    <Button onClick={(e)  => subCategoryHandler(e)}>Add Sub-Category</Button>
                                    <Suburbs>
                                            <Infos>
                                                <Label2>Id</Label2>
                                                <Label2>Sub-Category</Label2>
                                            </Infos>
                                        {utils?.sub_category?.map((c, index) => (
                                                <Infos key={index}>
                                                    <Label2>{index + 1}</Label2>
                                                    <Label2>{utils?.sub_category?.[index]?.[1]}</Label2>
                                                </Infos>

                                        ))
                                        }
                                    </Suburbs>
                                    <Input value={subCategoryId} onChange={(e) => setSubCategoryId(e.target.value)} type='text' placeholder='Sub-Category Id' />
                                    <Button onClick={(e) => subCategoryDeleteHandler(e)}>
                                        Delete
                                    </Button>
                                </Wrapper2>
                        </SecondWrapper>
                        <SecondWrapper>
                                <HugeLabel>Brand</HugeLabel>
                                <Wrapper2>
                                    <Label>Select Category</Label>
                                    <Container2>
                                        <Label2>Catagory</Label2>
                                        <SelectWrapper>
                                            <Select
                                                value={category}
                                                onChange={(e) => {
                                                    onCategoryChange(e);
                                                }}
                                            >
                                                <Option value>Select Catagory</Option>
                                                
                                                {utils?.category?.length !== 0?
                                                    <>
                                                        {utils?.category?.map((s, index) => (
                                                            <Option key={index} value={s?.[0]}>
                                                                {s?.[1]}
                                                            </Option>
                                                        ))}
                                                    </>
                                                : null
                                                }
                                            </Select>
                                            <ChevronIcon
                                                id="chevron-down"
                                                size={24}
                                                strokeWidth={1.5}
                                            />
                                        </SelectWrapper>
                                    </Container2>
                                    <Input value={brandValue} onChange={(e) => setBrandValue(e.target.value)} type='text' placeholder='Brand' />
                                    <Button onClick={(e)  => brandHandler(e)}>Add Brand</Button>
                                    <Suburbs>
                                            <Infos>
                                                <Label2>Id</Label2>
                                                <Label2>Brand</Label2>
                                            </Infos>
                                        {utils?.brand?.map((c, index) => (
                                                <Infos key={index}>
                                                    <Label2>{index + 1}</Label2>
                                                    <Label2>{utils?.brand?.[index]?.[1]}</Label2>
                                                </Infos>

                                        ))
                                        }
                                    </Suburbs>
                                    <Input value={brandId} onChange={(e) => setBrandId(e.target.value)} type='text' placeholder='Brand Id' />
                                    <Button onClick={(e) => brandDeleteHandler(e)}>
                                        Delete
                                    </Button>
                                </Wrapper2>
                        </SecondWrapper>
                        <SecondWrapper>
                            <HugeLabel>Body</HugeLabel>
                            <GridWrapper>
                                <Wrapper>
                                    <Input value={bodyValue} onChange={(e) => setBodyValue(e.target.value)} type='text' placeholder='Body' />
                                    <Button onClick={(e)  => bodyHandler(e)}>Add Body</Button>
                                    <Suburbs>
                                            <Infos>
                                                <Label2>Id</Label2>
                                                <Label2>Body</Label2>
                                            </Infos>
                                        {utils?.body?.map((c, index) => (
                                                <Infos key={index}>
                                                    <Label2>{index + 1}</Label2>
                                                    <Label2>{utils?.body?.[index]?.[1]}</Label2>
                                                </Infos>

                                        ))
                                        }
                                    </Suburbs>
                                    <Input value={bodyId} onChange={(e) => setBodyId(e.target.value)} type='text' placeholder='Body Id' />
                                    <Button onClick={(e) => bodyDeleteHandler(e)}>
                                        Delete
                                    </Button>
                                </Wrapper>
                            </GridWrapper>
                        </SecondWrapper>
                    </MainWrapper>
                </div>
            </div>
        </div>
    )
}




export default Utils;
