import axios from "axios";

export const listDeals = () => async (dispatch, getState) => {
    try {
        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
              Authorization: `Bearer ${userInfo.token}`,
            },
        }

        dispatch({ type: 'DEAL_LIST_REQUEST'});

        const { data } = await axios.get(`/api/v1/deals`, config
        );
        const dataRow = data.data.deals
        dispatch({
            type: 'DEAL_LIST_SUCCESS',
            payload: dataRow,
        })
    } catch (error) {
        dispatch({
            type: 'DEAL_LIST_FAIL',
            payload:
                error.response && error.response.data.message
                    ?error.response.data.message
                    :error.message,
        })
    }
}

export const getDealById = (id) => async (dispatch, getState) => {
    try {
        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
              Authorization: `Bearer ${userInfo.token}`,
            },
        }
        dispatch({ type: 'DEAL_DETAILS_BY_ID_REQUEST'});
        const { data } = await axios.get(`/api/v1/deals/dealdetails/${id}`, config);
        console.log(data.data.deal);

        dispatch({
            type: 'DEAL_DETAILS_BY_ID_SUCCESS',
            payload: data.data.deal,
        })
    } catch (error) {
        console.log(error);
    }
}


export const createDeal = ({ original_price, deal_price, image, name, month, day, year, size }) => async (dispatch, getState) => {
    try {

        const { userLogin: { userInfo } } = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`
            }
        }
        const { data } = await axios.post('/api/v1/deals', {
            original_price, deal_price, image, name, month, day, year, size
        }, config)

        dispatch({
            type: 'VENUE_CREATE_SUCCESS'
        })
    } catch (error) {
        console.log(error)
    }
}

export const updateDeal = ({ original_price, deal_price, image, name, month, day, year, dealId, size }) => async (dispatch, getState) => {
    try {

        const {
            userLogin: { userInfo }
        } = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            }
        }
        var id = dealId
        const { data } = await axios.put(`/api/v1/deals/${id}`, {
            original_price, deal_price, image, name, month, day, year, size
        
        }, config)
        //console.log(images)
        dispatch({
            type: 'DEAL_UPDATE_SUCCESS',
            payload: data,
        })
    } catch (error) {
        console.log(error);
    }
}

export const deleteDeal = (id) => async (dispatch, getState) => {
    try {
        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
              Authorization: `Bearer ${userInfo.token}`,
            },
        }
        const { data } = await axios.delete(`/api/v1/deals/${id}`, config);
        dispatch({
            type: 'DELETE_DEAL_SUCCESS',
            payload: data,
        })
    } catch (error) {
        console.log(error);
    }
}



