export const userListReducer = (state = { users: []}, action) => {
    switch (action.type) {
        case 'USERS_LIST_REQUEST':
            return { users: [] }
        case 'USERS_LIST_SUCCESS':
            return {
                users: action.payload,
            }
        case 'USERS_LIST_FAIL':
            return { error: action.payload }
        default:
            return state
    }
}




export const userLoginReducer = (state = {}, action ) => {
    switch (action.type){
        case 'USER_LOGIN_SUCCESS':
            return { userInfo: action.payload }
        case 'USER_LOGOUT':
            return {}
        default:
            return state
    }
}


export const userDetailsReducer = ( state = { user: {}}, action) => {
    switch (action.type) {
        case 'USER_DETAILS_SUCCESS':
            return { user: action.payload }
        default:
            return state
    }
}



