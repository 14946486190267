import React from 'react';
import "./franchiselist.scss"
import Sidebar from "../../components/sidebar/Sidebar"
import Navbar from "../../components/navbar/Navbar"
import Datatable from "../../components/franchisedatatable/FranchiseDatatable"

const FranchiseList = () => {
  return (
    <div className="list">
      <Sidebar/>
      <div className="listContainer">
        <Navbar/>
        <Datatable />
      </div>
    </div>
  )
}

export default FranchiseList;
