import React from 'react';
import "./newsdatatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { ordersColumns, userColumns, userRows } from "../../datatablesource";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { listUsers, deleteUser } from "../../actions/userActions";
import { getAllOrders, deleteOrder } from "../../actions/paymentActions";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";


const styles = theme => ({

})


const Wrapper = styled.div`
  height: auto;
`;

const NewsDatatable = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
      if(!userInfo) {
          navigate('/bro')
      }
  })

  const orders = useSelector((state) => state.orderList.orders);


  var eco = {}



  useEffect(() => {
    if(userInfo) {
      dispatch(getAllOrders())
    }
  }, [dispatch, userInfo])


  function refreshPage() {
    window.location.reload(false);
  }

  const handleDelete = (id, e) => {
    //setData(data.filter((item) => item.id !== id));
    e.preventDefault();
    dispatch(deleteOrder(id));
    refreshPage();
  };

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Link to={`/orders/edit/${params.row.id}`} style={{ textDecoration: "none" }}>
              <div className="viewButton">Edit</div>
            </Link>
            <div
              className="deleteButton"
              onClick={(e) => handleDelete(params.row.id, e)}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];
  return (
    <div className="datatable">
      <div className="datatableTitle">
        Newsletter Information
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', justifyContent: 'center', alignItems: 'center' }}>
        <p>
            We are using mailchimp for newsletter. Below i will link you tutorial on how to send newsletter to your contacts/customer using it. I think i changed the account email to your email. I will attach the login details below.

        </p>
        <p>
          https://www.youtube.com/watch?v=hKTrbg2ekAo&ab_channel=TheSocialGuide
        </p>
        <p>https://mailchimp.com/</p>
        <p>Login details</p>
        <p>
              mre@uniquescents.com.au <br/>
              CT56Aa:H94nKj.N
        </p>
      </div>
    </div>
  );
};

export default NewsDatatable;
