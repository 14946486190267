import axios from "axios";

export const listRaffles = () => async (dispatch, getState) => {
    try {
        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
              Authorization: `Bearer ${userInfo.token}`,
            },
        }
        dispatch({ type: 'RAFFLE_LIST_REQUEST'});

        const { data } = await axios.get(`/api/v1/raffles`, config
        );
        const dataRow = data.data.raffles
        dispatch({
            type: 'RAFFLE_LIST_SUCCESS',
            payload: dataRow,
        })
    } catch (error) {
        dispatch({
            type: 'RAFFLE_LIST_FAIL',
            payload:
                error.response && error.response.data.message
                    ?error.response.data.message
                    :error.message,
        })
    }
}

export const getRaffleById = (id) => async (dispatch, getState) => {
    try {
        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
              Authorization: `Bearer ${userInfo.token}`,
            },
        }
        dispatch({ type: 'RAFFLE_DETAILS_BY_ID_REQUEST'});
        const { data } = await axios.get(`/api/v1/raffles/raffledetails/${id}`, config);

        dispatch({
            type: 'RAFFLE_DETAILS_BY_ID_SUCCESS',
            payload: data.data.raffle,
        })
    } catch (error) {
        console.log(error);
    }
}


export const createRaffle = ({ original_price, image, name, month, day, year, size }) => async (dispatch, getState) => {
    try {

        const { userLogin: { userInfo } } = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`
            }
        }
        const { data } = await axios.post('/api/v1/raffles', {
            original_price, image, name, month, day, year, size
        }, config)

        dispatch({
            type: 'RAFFLE_CREATE_SUCCESS'
        })
    } catch (error) {
        console.log(error)
    }
}

export const updateRaffle = ({  original_price, image, name, month, day, year, raffleId, size }) => async (dispatch, getState) => {
    try {

        const {
            userLogin: { userInfo }
        } = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            }
        }
        var id = raffleId
        const { data } = await axios.put(`/api/v1/raffles/${id}`, {
            original_price, image, name, month, day, year, size
        
        }, config)
        //console.log(images)
        dispatch({
            type: 'RAFFLE_UPDATE_SUCCESS',
            payload: data,
        })
    } catch (error) {
        console.log(error);
    }
}

export const deleteRaffle = (id) => async (dispatch, getState) => {
    try {
        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
              Authorization: `Bearer ${userInfo.token}`,
            },
        }
        const { data } = await axios.delete(`/api/v1/raffles/${id}`, config);
        dispatch({
            type: 'DELETE_RAFFLE_SUCCESS',
            payload: data,
        })
    } catch (error) {
        console.log(error);
    }
}



