export const dealListReducer = (state = { deals: []}, action) => {
    switch (action.type) {
        case 'DEAL_LIST_REQUEST':
            return { deals: [] }
        case 'DEAL_LIST_SUCCESS':
            return {
                deals: action.payload,
            }
        case 'DEAL_LIST_FAIL':
            return { error: action.payload }
        default:
            return state
    }
}


export const dealByIdReducer = (state = { deal: []}, action) => {
    switch (action.type) {
        case 'DEAL_DETAILS_BY_ID_REQUEST':
            return {...state}
        case 'DEAL_DETAILS_BY_ID_SUCCESS':
            return { deal: action.payload }
        default:
            return state
    }
}


export const dealCreateReducer = ( state = {}, action) => {
    switch (action.type) {
        case 'DEAL_CREATE_SUCCESS':
            return { deal: action.payload }
        default:
            return state
    }
}

export const updateDealReducer = ( state = {}, action) => {
    switch (action.type) {
        case 'DEAL_UPDATE_SUCCESS':
            return { deal: action.payload }
        default:
            return state
    }
}




