import axios from 'axios';

export const createCustomer = (businessName, businessEmail, businessContact, businessAddress, personName, personPhoneNumber, personEmail, personAddress) => async (dispatch, getState) => {
    try {
        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
              Authorization: `Bearer ${userInfo.token}`,
            },
        }
        const { data } = await axios.post('/api/v1/payment/create-customer', {
            businessName,
            businessContact,
            businessEmail,
            businessAddress,
            personName,
            personPhoneNumber,
            personEmail,
            personAddress,
        }, config);

        dispatch({
            type: 'CREATE_CUSTOMER_SUCCESS',
            payload: data.data.customer,
        })
    } catch (error) {
        console.log(error);
    }
}


export const getCustomer = () => async (dispatch, getState) => {
    try {
        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
              Authorization: `Bearer ${userInfo.token}`,
            },
        }
        const { data } = await axios.get('/api/v1/payment', config);

        dispatch({
            type: 'GET_CUSTOMER_SUCCESS',
            payload: data.data.customers,
        })
    } catch (error) {
        console.log(error);
    }
}



export const statusCustomer = () => async (dispatch, getState) => {
    try {
        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
              Authorization: `Bearer ${userInfo.token}`,
            },
        }
        const { data } = await axios.get('/api/v1/payment/status', config);

        dispatch({
            type: 'STATUS_CUSTOMER_SUCCESS',
            payload: data.data.customers,
    })
    } catch (error) {
        console.log(error);
    }
}



export const getAllOrders = () => async (dispatch, getState) => {
    try {
        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
              Authorization: `Bearer ${userInfo.token}`,
            },
        }
        dispatch({ type: 'ORDERS_LIST_REQUEST'});

        const { data } = await axios.get('/api/v1/payment/orders', config); 
        const dataRow = data;
        console.log(dataRow)
        dispatch({
            type: 'ORDERS_LIST_SUCCESS',
            payload: dataRow,
        })
    } catch (error) {
        dispatch({
            type: 'ORDERS_LIST_FAIL',
            payload:
                error.response && error.response.data.message
                    ?error.response.data.message
                    :error.message,
        })
    }
}



export const getAllSubscribers = () => async (dispatch, getState) => {
    try {
        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
              Authorization: `Bearer ${userInfo.token}`,
            },
        }
        dispatch({ type: 'SUBSCRIBERS_LIST_REQUEST'});

        const { data } = await axios.get('/api/v1/payment/subscribers', config); 
        const dataRow = data;
        dispatch({
            type: 'SUBSCRIBERS_LIST_SUCCESS',
            payload: dataRow,
        })
    } catch (error) {
        dispatch({
            type: 'SUBSCRIBERS_LIST_FAIL',
            payload:
                error.response && error.response.data.message
                    ?error.response.data.message
                    :error.message,
        })
    }
}




export const orderStatus = (id, status, track) => async (dispatch, getState) => {
    try {
        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
              Authorization: `Bearer ${userInfo.token}`,
            },
        }

        const { data } = await axios.post('/api/v1/payment/orders', {
            id, status, track
        }, config); 
        const dataRow = data;
        console.log(dataRow)
        dispatch({
            type: 'ORDER_STATUS_SUCCESS',
            payload: dataRow,
        })
    } catch (error) {
        dispatch({
            type: 'ORDER_STATUS_FAIL',
            payload:
                error.response && error.response.data.message
                    ?error.response.data.message
                    :error.message,
        })
    }
}








export const deleteCustomer = (id) => async (dispatch, getState) => {
        try {
            const {
                userLogin: { userInfo },
            } = getState()
    
            const config = {
                headers: {
                  Authorization: `Bearer ${userInfo.token}`,
                },
            }
            const { data } = await axios.delete(`/api/v1/payment/${id}`, config);
            dispatch({
                type: 'DELETE_CUSTOMER_SUCCESS',
                payload: data,
            })
        } catch (error) {
            console.log(error);
        }
}
export const deleteOrder = (id) => async (dispatch, getState) => {
    try {
        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
              Authorization: `Bearer ${userInfo.token}`,
            },
        }
        const { data } = await axios.delete(`/api/v1/payment/orders/${id}`, config);
        dispatch({
            type: 'DELETE_ORDER_SUCCESS',
            payload: data,
        })
    } catch (error) {
        console.log(error);
    }
}
