
import axios from "axios";

export const listProducts = (cityB, areaB, suburbB, typeB, sub_typeB, keywords) => async (dispatch, getState) => {
    try {
        dispatch({ type: 'PRODUCT_LIST_REQUEST'});

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
              Authorization: `Bearer ${userInfo.token}`,
            },
        }

        const { data } = await axios.get(`/api/v1/products`,
            config
        ); //?cityB=${cityB}&typeB=${typeB}&sub_typeB=${sub_typeB}&areaB=${areaB}&suburbB=${suburbB}&keywords=${keywords}
        const dataRow = data.data.products
        console.log(dataRow)
        dispatch({
            type: 'PRODUCT_LIST_SUCCESS',
            payload: dataRow,
        })
    } catch (error) {
        dispatch({
            type: 'PRODUCT_LIST_FAIL',
            payload:
                error.response && error.response.data.message
                    ?error.response.data.message
                    :error.message,
        })
    }
}

export const getProductById = (id) => async (dispatch, getState) => {
    try {
        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
              Authorization: `Bearer ${userInfo.token}`,
            },
        }

        dispatch({ type: 'PRODUCT_DETAILS_BY_ID_REQUEST'});
        const { data } = await axios.get(`/api/v1/products/productdetails/${id}`, config);
        console.log(data.data.product);

        dispatch({
            type: 'PRODUCT_DETAILS_BY_ID_SUCCESS',
            payload: data.data.product,
        })
    } catch (error) {
        console.log(error);
    }
}


export const createProduct = ({ name, sku, sizeArray, imageArray, priceArray, saleArray, para, category, sub_category, brand, gender, stockArray}) => async (dispatch, getState) => {
    try {

        const { userLogin: { userInfo } } = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`
            }
        }
        const { data } = await axios.post('/api/v1/products', {
            name, sku, sizeArray, imageArray, priceArray, saleArray, para, category, sub_category, brand, gender, stockArray
        }, config)

        dispatch({
            type: 'VENUE_CREATE_SUCCESS'
        })
    } catch (error) {
        console.log(error)
    }
}

export const updateProduct = ({ productId, name, sku, category, sub_category, brand, gender, newImages, descriptions, pricee, stockk, salee, sizee }) => async (dispatch, getState) => {
    try {

        const {
            userLogin: { userInfo }
        } = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            }
        }
        var id = productId
        const { data } = await axios.put(`/api/v1/products/${id}`, {
            productId, 
            name, 
            sku, 
            category, 
            sub_category, 
            brand, 
            gender, 
            newImages, 
            descriptions, 
            pricee, 
            stockk, 
            salee, 
            sizee
        
        }, config)
   
        dispatch({
            type: 'PRODUCT_UPDATE_SUCCESS',
            payload: data,
        })
    } catch (error) {
        console.log(error);
    }
}

export const deleteProduct = (id) => async (dispatch, getState) => {
    try {

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
              Authorization: `Bearer ${userInfo.token}`,
            },
        }
        const { data } = await axios.delete(`/api/v1/products/${id}`, config);
        dispatch({
            type: 'DELETE_PRODUCT_SUCCESS',
            payload: data,
        })
    } catch (error) {
        console.log(error);
    }
}




