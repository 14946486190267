import React from 'react';
import "./raffles.scss"
import Sidebar from "../../components/sidebar/Sidebar"
import Navbar from "../../components/navbar/Navbar"
import RaffleDatatable from "../../components/raffledatatable/RaffleDatatable"

const Raffle = () => {
  return (
    <div className="list">
      <Sidebar/>
      <div className="listContainer">
        <Navbar/>
        <RaffleDatatable />
      </div>
    </div>
  )
}

export default Raffle;
