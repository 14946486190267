export const promoListReducer = (state = { promos: []}, action) => {
    switch (action.type) {
        case 'PROMO_LIST_REQUEST':
            return { promos: [] }
        case 'PROMO_LIST_SUCCESS':
            return {
                promos: action.payload,
            }
        case 'PROMO_LIST_FAIL':
            return { error: action.payload }
        default:
            return state
    }
}


export const promoByIdReducer = (state = { promo: []}, action) => {
    switch (action.type) {
        case 'PROMO_DETAILS_BY_ID_REQUEST':
            return {...state}
        case 'PROMO_DETAILS_BY_ID_SUCCESS':
            return { promo: action.payload }
        default:
            return state
    }
}


export const promoCreateReducer = ( state = {}, action) => {
    switch (action.type) {
        case 'PROMO_CREATE_SUCCESS':
            return { promos: action.payload }
        default:
            return state
    }
}

export const updatePromosReducer = ( state = {}, action) => {
    switch (action.type) {
        case 'PROMO_UPDATE_SUCCESS':
            return { promos: action.payload }
        default:
            return state
    }
}




