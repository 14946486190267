import React from 'react';
import "./subscriberdatatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { subscriberColumns } from "../../datatablesource";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { getAllSubscribers } from "../../actions/paymentActions";
import { useDispatch, useSelector } from "react-redux";

const SubscriberDatatable = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
      if(!userInfo) {
          navigate('/bro')
      }
  })

  const subscribers = useSelector((state) => state.subscriberList.subscribers);


  useEffect(() => {
    if(userInfo) {
      dispatch(getAllSubscribers())
    }
  }, [dispatch, userInfo])


  function refreshPage() {
    window.location.reload(false);
  }

  const handleDelete = (id, e) => {
    //setData(data.filter((item) => item.id !== id));
    e.preventDefault();
    //dispatch(deleteFranchise(id));
    refreshPage()
  };


  return (
    <div className="datatable">
      <div className="datatableTitle">
        Subscribers
      </div>
      {subscribers?
        <DataGrid
          className="datagrid"
          rows={subscribers}
          columns={subscriberColumns}
          pageSize={9}
          getRowId={(row) =>  row.customer_id}
          rowsPerPageOptions={[9]}
          checkboxSelection
        />
      : null
      }

    </div>
  );
};

export default SubscriberDatatable;
