import React from 'react';
import "./userdatatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { userColumns, userRows } from "../../datatablesource";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { listUsers, deleteUser, userStatus } from "../../actions/userActions";
import { useDispatch, useSelector } from "react-redux";


const UserDatatable = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
      if(!userInfo) {
          navigate('/bro')
      }
  })

  const users = useSelector((state) => state.userList.users);


  var eco = {}



  useEffect(() => {
    if(userInfo) {
      dispatch(listUsers())
    }
  }, [dispatch, userInfo])

  console.log(users)

  /*useEffect(() => {
    if(!data){
      /*products?.map((n) => {
        eco.id = n
        eco.value = n + 1
        return data?.push({id: n, value: n+2})
      })
      setData(products)
    }
  },[dispatch, data, products])*/

  ////const [data, setData] = useState(products);

  //console.log(products)


  function refreshPage() {
    window.location.reload(false);
  }

  const handleDelete = (id, e) => {
    //setData(data.filter((item) => item.id !== id));
    e.preventDefault();
    dispatch(deleteUser(id));
    refreshPage()
  };


  const handleEdit = (id, e) => {
    e.preventDefault();
    dispatch(userStatus(id))
    refreshPage()
  }

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cellAction">
              <div className="viewButton" onClick={(e) => handleEdit(params.row.id, e)}>
                Edit
              </div>
            <div
              className="deleteButton"
              onClick={(e) => handleDelete(params.row.id, e)}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];
  return (
    <div className="datatable">
      <div className="datatableTitle">
        ALL Users
      </div>
      {users?
        <DataGrid
          className="datagrid"
          rows={users}
          columns={userColumns.concat(actionColumn)}
          pageSize={50}
          rowsPerPageOptions={[50]}
          checkboxSelection
        />
      : null
      }

    </div>
  );
};

export default UserDatatable;
