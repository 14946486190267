import Home from "./pages/home/Home";
import Login from "./pages/login/Login";
import List from "./pages/list/List";
import Deal from "./pages/deals/deals";
import UserList from "./pages/userlist/UserList";
import OrderList from "./pages/orderlist/OrderList";
import FranchiseList from "./pages/franchiselist/FranchiseList";
import Single from "./pages/single/Single";
import New from "./pages/new/New";
import AddProduct from "./pages/product/AddProduct";
import AddDeal from "./pages/adddeal/AddDeal";
import AddRaffle from "./pages/addraffle/AddRaffle";
import EditRaffle from "./pages/editraffle/EditRaffle";
import Raffle from "./pages/raffles/raffles";
import AddFranchise from "./pages/franchise/AddFranchise";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import SubscribersList from "./pages/subscribers/Subscribers";
import { productInputs, userInputs } from "./formSource";
import Utils from "./pages/utils/Utils";
import EditProduct from "./pages/productEdit/EditProduct";
import EditDeal from "./pages/editdeal/EditDeal";
import EditFranchise from "./pages/franchiseEdit/EditFranchise";
import OrderStatus from "./components/orderstatus/OrderStatus";
import "./style/dark.scss";
import { useContext } from "react";
import { DarkModeContext } from "./context/darkModeContext";
import Register from "./pages/Register";
import Promo from "./pages/promos/promos";
import EditPromo from "./pages/editpromo/EditPromo";
import AddPromo from "./pages/addpromo/AddPromo";
import Newsletter from "./pages/newsletter/Newsletter";


function App() {
  const { darkMode } = useContext(DarkModeContext);

  return (
    <div className={darkMode ? "app dark" : "app"}>
      <BrowserRouter>
        <Routes>
          <Route path="/">
            <Route index element={<Home />} />
            <Route path="login" element={<Login />} />
            <Route path="register" element={<Register />} />
            <Route path="users">
              <Route index element={<UserList />} />
              <Route path=":userId" element={<Single />} />
              <Route
                path="new"
                element={<New inputs={userInputs} title="Add New User" />}
              />
            </Route>

            <Route path="subscribers">
              <Route index element={<SubscribersList />} />
              <Route path="edit/:id" element={<OrderStatus />} />
              <Route path=":subscribeId" element={<Single />} />
              <Route
                path="new"
                element={<New inputs={userInputs} title="Add New Subscriber" />}
              />
            </Route>
            <Route path="franchises">
              <Route index element={<FranchiseList />} />
              <Route path="edit/:id" element={<EditFranchise />} />
              <Route path=":franchiseId" element={<Single />} />
              <Route
                path="new"
                element={<AddFranchise inputs={userInputs} title="Add New User" />}
              />
            </Route>
            <Route path="orders">
              <Route index element={<OrderList />} />
              <Route path="edit/:id" element={<OrderStatus />} />
              <Route path=":userId" element={<Single />} />
              <Route
                path="new"
                element={<New inputs={userInputs} title="Add New User" />}
              />
            </Route>

            <Route path="deals">
              <Route index element={<Deal />} />
              <Route path="edit/:id" element={<EditDeal />} />
              <Route path=":dealId" element={<Single />} />
              <Route
                path="new"
                element={<AddDeal title="Add New Deal" />}
              />
            </Route>
            <Route path="products">
              <Route index element={<List />} />
              <Route path="edit/:id" element={<EditProduct />} />
              <Route path=":productId" element={<Single />} />
              <Route
                path="new"
                element={<AddProduct inputs={productInputs} title="Add New Product" />}
              />
            </Route>

            <Route path="raffles">
              <Route index element={<Raffle />} />
              <Route path="edit/:id" element={<EditRaffle />} />
              <Route path=":raffleId" element={<Single />} />
              <Route
                path="new"
                element={<AddRaffle title="Add New Raffle" />}
              />
            </Route>

            <Route path="promos">
              <Route index element={<Promo />} />
              <Route path="edit/:id" element={<EditPromo />} />
              <Route path=":raffleId" element={<Single />} />
              <Route
                path="new"
                element={<AddPromo title="Add New Promo" />}
              />
            </Route>

            <Route path="newsletter">
              <Route index element={<Newsletter />} />
            </Route>

            <Route path="utils">
              <Route index element={<Utils />} />
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
