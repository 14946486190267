import React, { useEffect, useState } from "react";
import "./adddeal.scss";
import styled from "styled-components";
import axios from "axios";
import MDEditor from "@uiw/react-md-editor";

import ImageUploading from "react-images-uploading";
import Sidebar from "../../components/sidebar/Sidebar";
import Compressor from "compressorjs";
import { Select, MenuItem, InputLabel } from "@mui/material";
import Navbar from "../../components/navbar/Navbar";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import { WEIGHTS, QUERIES, COLORS } from "../../constants";
import { createDeal } from "../../actions/dealActions";
import { listUtils } from "../../actions/utilActions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";


const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 100px 30px;
    justify-content: center;
    align-items: center;
`;

const Label = styled.label`
    padding: 20px 0px 10px 0px;
    font-size: 1.5rem;
    font-weight: ${WEIGHTS.medium};
    color: #023E8A;

`;

const Container = styled.div`
    display: flex;
    flex-direction: column;

    @media ${QUERIES.laptopAndUp} {

    }
`;

const Label2 = styled(Label)`
    padding: 20px 0px 10px 0px;
    font-size: 1.5rem;
    font-weight: ${WEIGHTS.medium};
    color: #023E8A;
`;

const Input = styled.input`
    width: 350px;
    height: 50px;
    font-size: 1rem;
    border-radius: 4px;
    color: grey;
    outline: none;

    &:focus {
        border-color: #0081ba;
    }
`;

const SelectWrapper = styled.div`
  position: relative;
  isolation: isolate;
  text-decoration: uppercase;
  display: flex;
  z-index: 1;
  align-items: flex-start;
  flex-direction: column;
  border-radius: 5px;
  background: #023E8A;
  width: 280px;
  padding-bottom: 20px;
  box-shadow: 10px ${COLORS.gray[1100]};
`;


const Select2 = styled.select`
  position: relative;
  appearance: none;
  filter: none;
  width: 280px;
  font-weight: ${WEIGHTS.normal};
  text-transform: uppercase;
  font-size: 0.8rem;
  padding: 20px 0px 0px 12px;
  border: none;
  border-radius: 20px;
  outline: none;
  color: white;
  autocomplete: off;
  background: transparent;
  z-index: 4;
  cursor: pointer;
  & > :focus {
    outline: 0;
    outline-color: transparent;
    outline-style: none;
  }
`;

const Option = styled.option`
  background: #023E8A;
  font-size: 0.8rem;
  text-transform: uppercase;
  font-weight: ${WEIGHTS.medium};
`;

/*const ChevronIcon = styled(Icon)`
  position: absolute;
  top: 1px;
  right: 10px;
  bottom: 2px;
  margin: auto;
  width: 24px;
  height: 24px;
  z-index: 3;
  color: white;
  cursor: inherit;
`;*/


const ImageInput = styled.input`
    content: 'Select Image';
    display: inline-block;
    background: linear-gradient(top, #f9f9f9, #e3e3e3);
    border: 1px solid #999;
    border-radius: 3px;
    padding: 5px 8px;
    margin-left: 26px;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    text-shadow: 1px 1px #fff;
    font-weight: 700;
    font-size: 10pt;

    @media ${QUERIES.laptopAndUp} {
        padding-left: 28px;
        margin-left: 26px;
    }

    @media ${QUERIES.tabletOnly} {
        padding-left: 28px;
        margin-left: 26px;
    }
`;



const TextArea = styled.textarea`

`;

const Button = styled.button`
    width: 150px;
    padding: 10px;
    margin: 8px;
    border-radius: 5px;
    border: 1px solid #023E8A;
    color: white;
    background: #023E8A;
    cursor: pointer;
`;

const Container2 = styled(Container)`
    flex-direction: row;
    align-items: center;
`;

const CheckInput = styled.input`
    width: 80px;
    height: 40px;
`;




const AddDeal = ({ inputs, title }) => {
    const [file, setFile] = useState("");
    const [name, setName] = useState("");
    const [sku, setSku] = useState("");
    const [size, setSize] = useState(0);
    const [price, setPrice] = useState("");
    const [para, setPara] = useState("");
    const [uploadAlert, setUploadAlert] = useState('bro');
    const [description, setDescription] = useState([]);
    const [sale, setSale] = useState("");
    const [stock, setStock] = useState("");
    const [saleArray, setSaleArray] = useState([]);
    const [priceArray, setPriceArray] = useState([]);
    const [sizeArray, setSizeArray] = useState([]);
    const [stockArray, setStockArray] = useState([]);
    const [category, setCategory] = useState("");
    const [sub_category, setSub_Category] = useState("");
    const [gender, setGender] = useState("");
    const [brand, setBrand] = useState("");
    const [compressedFile, setCompressedFile] = useState(null);
    const [images, setImages] = useState([]);
    const [imageArray, setImageArray] = useState([]);
    const [fileB, setFileB] = useState();


    const [original_price, setOriginalPrice] = useState(0);
    const [deal_price, setDealPrice] = useState(0);
    const [month, setMonth] = useState(0);
    const [day, setDay] = useState(0);
    const [year, setYear] = useState(0);
    const [image, setImage]= useState('');


    const MAX_LENGTH = 4;


    const dispatch = useDispatch();

    const navigate = useNavigate();


    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;

    useEffect(() => {
        if(!userInfo) {
            navigate('/')
        }
    })


    const utils = useSelector((state) => state.utilList.utils);

    var id = "1";


    useEffect(() => {
        if(id) {
            dispatch(
                listUtils()
            )
        }
    }, [id]);


    const handleCompressedUpload = (e) => {
        if (Array.from(e.target.files).length > MAX_LENGTH) {
            e.preventDefault();
            console.log(`Cannot upload files more than ${MAX_LENGTH}`);
            return;
        } else {
            setImages(e.target.files);
        }
    };


    console.log(saleArray)

    const handleSize = (e) => {
         e.preventDefault()
        if(price.trim() !== ''){
            setPriceArray(arr => [...arr, price])
            if(size.trim() !== '') {
                setSizeArray(arr => [...arr, size])
            } else {
                setSizeArray(arr => [...arr, 'none'])
            }

            if(sale.trim() !== '') {
                setSaleArray(arr => [...arr, sale])
            } else {
                setSaleArray(arr => [...arr, 0])
            }

            if(stock.trim() !== '') {
                setStockArray(arr => [...arr, stock])
            } else {
                setStockArray(arr => [...arr, 0])
            }
        }
    }


    const handleDes = (e) => {
        e.preventDefault()
       if(para.trim() !== ''){
           setDescription(arr => [...arr, para])
       }
   }



    console.log(images?.length)

    const imageHandler = async (e) => {
         e.preventDefault()
        //if(images){
            var a = 0

            while (a < images?.length) {
                await compressedUploader(images[a])
                a = a + 1
                setUploadAlert('progress')
            }

            setUploadAlert('success')
            //const newImage = await compressedUploader(images[1])
            //console.log('newImage', newImage);
           //console.log(compressedFile)
           //console.log(fileB);
        //}
    }


    const compressedUploader = async (image) => {
        new Compressor(image, {
            quality: 0.6,

            async success(result) {
              const formData = new FormData();

              formData.append('image', result);


              const res = await axios.post('/api/posts', formData, { headers: {'Content-Type': 'multipart/form-data'}});
              setCompressedFile(res.data);
              setImage(res.data);
              setImageArray(arr => [...arr, res.data])
              console.log(res.data);
              //setSuccess(true);
              //setAlert(false)
              //setFileB(res.data);
            },
            error(err) {
              console.log(err.message);
            },

        });

    }

    const category_list = [['fragrance', 'Fragrance'], ['candles', 'Candles']];
    const subCategory = [['unboxed', 'Unboxed'], ['gift-sets', 'Gift Sets'], ['bath-body', 'Bath & Body'], ['cologne', 'Cologne']];
    const genders = [['women', 'Women'], ['men', 'Men'], ['unisex', 'Unisex']]
    const brands = [['dior', 'Dior'], ['channel', 'Channel']]


    const onCategoryChange = (e) => {
        const selectedCategory = e.target.value;
        /*if(selectedSubType === 'any') {
            setSubType(selectedSubType);
        } else {
            const selectedSubTypeId = SUBTYPE.filter((t) => t.id === selectedSubType)[0]? SUBTYPE.filter((t) => t.id === selectedSubType)[0] : utils?.subtype?.filter((a) => a[0] === selectedSubType)[0];
            const realSubType = selectedSubTypeId.id? selectedSubTypeId.id : selectedSubTypeId[0];
            console.log(realSubType)
            setSubType(realSubType);
        } */

        const selectedCategoryId = utils?.category?.filter((a) => a[0] === selectedCategory)[0];
        const realCategory = selectedCategoryId.id? selectedCategoryId.id : selectedCategoryId[0];
        console.log(realCategory)
        setCategory(realCategory);
    };


    const onSubCategoryChange = (e) => {
        const selectedSubCategory = e.target.value;
        /*if(selectedSubType === 'any') {
            setSubType(selectedSubType);
        } else {
            const selectedSubTypeId = SUBTYPE.filter((t) => t.id === selectedSubType)[0]? SUBTYPE.filter((t) => t.id === selectedSubType)[0] : utils?.subtype?.filter((a) => a[0] === selectedSubType)[0];
            const realSubType = selectedSubTypeId.id? selectedSubTypeId.id : selectedSubTypeId[0];
            console.log(realSubType)
            setSubType(realSubType);
        } */

        const selectedSubCategoryId = utils?.sub_category?.filter((a) => a[0] === selectedSubCategory)[0];
        const realSubCategory = selectedSubCategoryId.id? selectedSubCategoryId.id : selectedSubCategoryId[0];
        console.log(realSubCategory)
        setSub_Category(realSubCategory);
    };


    const onBrandChange = (e) => {
        const selectedBrand = e.target.value;
        /*if(selectedSubType === 'any') {
            setSubType(selectedSubType);
        } else {
            const selectedSubTypeId = SUBTYPE.filter((t) => t.id === selectedSubType)[0]? SUBTYPE.filter((t) => t.id === selectedSubType)[0] : utils?.subtype?.filter((a) => a[0] === selectedSubType)[0];
            const realSubType = selectedSubTypeId.id? selectedSubTypeId.id : selectedSubTypeId[0];
            console.log(realSubType)
            setSubType(realSubType);
        } */

        const selectedBrandId = utils?.brand.filter((a) => a[0] === selectedBrand)[0];
        const realBrand = selectedBrandId.id? selectedBrandId.id : selectedBrandId[0];
        console.log(realBrand)
        setBrand(realBrand);
    };


    const onGenderChange = (e) => {
        const selectedGender= e.target.value;
        /*if(selectedSubType === 'any') {
            setSubType(selectedSubType);
        } else {
            const selectedSubTypeId = SUBTYPE.filter((t) => t.id === selectedSubType)[0]? SUBTYPE.filter((t) => t.id === selectedSubType)[0] : utils?.subtype?.filter((a) => a[0] === selectedSubType)[0];
            const realSubType = selectedSubTypeId.id? selectedSubTypeId.id : selectedSubTypeId[0];
            console.log(realSubType)
            setSubType(realSubType);
        } */

        const selectedGenderId = genders.filter((a) => a[0] === selectedGender)[0];
        const realGender = selectedGenderId.id? selectedGenderId.id : selectedGenderId[0];
        console.log(realGender)
        setGender(realGender);
    };


    const addProductHandler = async (e) => {
        e.preventDefault();
        dispatch(
            createDeal({ original_price, deal_price, image, name, month, day, year, size })
        )
        navigate('/deals')
    }



  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div className="top">
          <h1>{title}</h1>
        </div>
        <div className="bottom">
          <div className="right">
            <form>
              <div className="formInput">
                <label htmlFor="file">
                  Product Image: <DriveFolderUploadOutlinedIcon className="icon" />
                </label>
                <div className="formInput">
                    <ImageInput
                        accept="image/*"
                        type="file"
                        onChange={handleCompressedUpload}
                        multiple
                    />
                    <button onClick={(e) => imageHandler(e)}>Add Image</button>
                    {uploadAlert === 'progress'?
                        <h1>uploading...</h1>
                    : uploadAlert === 'success'?
                        <h1>Successfully uploaded</h1>
                    : null
                    }
                </div>
              </div>
                <div className="formInput">
                    <label>Product Name</label>
                    <input value={name} onChange={(e) =>  setName(e.target.value)} type='text' placeholder="Calvin Klein" />
                    <label>Product Size/Weight(In ml or gm)</label>
                    <input value={size} onChange={(e) =>  setSize(e.target.value)} type='number' placeholder="70" />
                </div>
                <div className="formInput">
                    <label>Original Price</label>
                    <input value={original_price} type='Number' onChange={(e) => setOriginalPrice(e.target.value)} placeholder="19" />
                    <label>Deal Price</label>
                    <input value={deal_price} type='Number' onChange={(e) => setDealPrice(e.target.value)} placeholder="19" />
                </div>
                <div className="formInput">
                    <label>Month(0 - 11)</label>
                    <input value={month} type='Number' onChange={(e) => setMonth(e.target.value)} placeholder="19" />
                    <label>Day(1 - 31)</label>
                    <input value={day} type='Number' onChange={(e) => setDay(e.target.value)} placeholder="19" />
                    <label>year(e.g 2023)</label>
                    <input value={year} type='Number' onChange={(e) => setYear(e.target.value)} placeholder="19" />
                </div>
                <div className="formInput">
                <Button onClick={(e) => addProductHandler(e)}>Add Product</Button>
                </div>

            </form>
        </div>
        </div>
      </div>
    </div>

  );
};


const Wrapper2 = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    width 100%;
    height: auto;
`;

export default AddDeal;
