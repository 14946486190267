import React from 'react';
import "./orderdatatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { ordersColumns, userColumns, userRows } from "../../datatablesource";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { listUsers, deleteUser } from "../../actions/userActions";
import { getAllOrders } from "../../actions/paymentActions";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";


const styles = theme => ({

})


const Wrapper = styled.div`
  height: auto;
`;

const OrderDatatable = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
      if(!userInfo) {
          navigate('/bro')
      }
  })

  const orders = useSelector((state) => state.orderList.orders);


  var eco = {}



  useEffect(() => {
    if(userInfo) {
      dispatch(getAllOrders())
    }
  }, [dispatch, userInfo])

  console.log(orders)

  /*useEffect(() => {
    if(!data){
      /*products?.map((n) => {
        eco.id = n
        eco.value = n + 1
        return data?.push({id: n, value: n+2})
      })
      setData(products)
    }
  },[dispatch, data, products])*/

  ////const [data, setData] = useState(products);

  //console.log(products)




  function refreshPage() {
    window.location.reload(false);
  }

  const handleDelete = (id, e) => {
    //setData(data.filter((item) => item.id !== id));
    e.preventDefault();
    dispatch(deleteUser(id));
    refreshPage()
  };

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Link to={`/orders/edit/${params.row.id}`} style={{ textDecoration: "none" }}>
              <div className="viewButton">Edit</div>
            </Link>
            <div
              className="deleteButton"
              onClick={(e) => handleDelete(params.row.id, e)}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];
  return (
    <div className="datatable">
      <div className="datatableTitle">
        All Orders
      </div>
      {orders?
        <DataGrid
          className="datagrid"
          rows={orders}
          rowHeight={200}
          columns={ordersColumns.concat(actionColumn)}
          pageSize={50}
          rowsPerPageOptions={[50]}
        />
      : null
      }

    </div>
  );
};

export default OrderDatatable;
