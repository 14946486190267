import React, { useState, useEffect } from 'react';
import "./dealEditMain.scss"

import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import MDEditor from "@uiw/react-md-editor";
import { Select, MenuItem, InputLabel } from "@mui/material";
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import Compressor from 'compressorjs';
import { WEIGHTS, QUERIES, COLORS, NavLinks, AREA, VENUE, SUBTYPE, SUBURB, MEL_SUBURB, SYD_SUBURB, BRIS_SUBURB, ADEL_SUBURB, PERTH_SUBURB } from '../../constants';
import { getDealById, updateDeal } from '../../actions/dealActions';
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { listUtils } from '../../actions/utilActions';
import Icon from '../../components/Icon';

const EditDeal = () => {
    const [productId, setProductId] = useState(0);
    const [para, setPara] = useState('');
    const [fileBNewImages, setFileBNewImages] = useState();
    const [size, setSize] = useState();
    const [singleImage, setSingleImage] = useState('');
    const [compressedFileSingleEdit, setCompressedFileSingleEdit] = useState('');
    const [linkText, setLinkText] = useState('');
    const [descriptionArray, setDescriptionArray] = useState([]);
    const [editAlert, setEditAlert] = useState(false);
    const [successSingleEdit, setSuccessSingleEdit] = useState(false);
    const [ newSubTitle, setNewSubTitle] = useState('');
    const [ newSubTitleLink, setNewSubTitleLink] = useState('');
    const [ newSubTitleLinkText, setNewSubTitleLinkText] = useState('');
    const [capacityId, setCapacityId] = useState('');
    const [newSingleImage, setNewSingleImage] = useState('');
    const [newPara, setNewPara] = useState('');
    const [priceIdArray, setPriceIdArray] = useState([]);
    const [newImageArray, setNewImageArray] = useState([]);
    const [idIndex, setIdIndex] = useState(0);
    const [finalVenue, setFinalVenue] = useState(false);
    const [finalVenueAlert, setFinalVenueAlert] = useState(false);
    const [finalAlert, setFinalAlert] = useState('');
    const [newLink,  setNewLink] = useState('');
    const [newImages, setNewImages] = useState([]);
    const [capInd, setCapInd] = useState();
    const [newLinkText, setNewLinkText] = useState('');
    const [newDescriptionArray, setNewDescriptionArray] = useState([]);
    const [descriptionIdArray, setDescriptionIdArray] = useState([]);
    const [descriptions, setDescriptions] = useState('');
    const [area, setArea] = useState('regional-nsw');
    const [fileBSingleEdit ,setFileBSingleEdit] = useState();
    const [suburb, setSuburb] = useState('alexandria');
    const [type, setType] = useState('bars');
    const [alertNewDesImage, setAlertNewDesImage] = useState(false);
    const [successNewDesImage, setSuccessNewDesImage] = useState(false);
    const [sub_type, setSub_Type] = useState('hidden-bars');
    const [descriptionC, setDescriptionC] = useState([]);
    const [images, setImages] = useState([]);
    const [imageArray, setImageArray] = useState([]);
    const [success, setSuccess] = useState(false);
    const [descriptionId, setDescriptionId] = useState(0);

    const [compressedFile, setCompressedFile] = useState(null);

    const [alert, setAlert] = useState(false);


    const [fileB, setFileB] = useState();
    const [room, setRoom] = useState('');
    const [newRoom, setNewRoom] = useState('');
    const [min, setMin] = useState('');
    const [newMin, setNewMin] = useState('');
    const [editId, setEditId] = useState('');
    const [max, setMax] = useState('');
    const [newMax, setNewMax] = useState('');
    const [phone, setPhone] = useState('');
    const [isDraft, setIsDraft] = useState(false);
    const [companyEmail, setCompanyEmail] = useState('');
    const [website, setWebsite] = useState('');
    const [maps, setMaps] = useState('');
    const [imageId, setImageId] = useState(0);
    const [imageIdArray, setImageIdArray] = useState([]);
    const [capacityIdArray, setCapacityIdArray] = useState([]);
    const [file, setFile] = useState("");
    const [name, setName] = useState("");
    const [sku, setSku] = useState("");


    const [original_price, setOriginalPrice] = useState(0);
    const [deal_price, setDealPrice] = useState(0);
    const [dealId, setDealId] = useState();
    const [month, setMonth] = useState(0);
    const [day, setDay] = useState(0);
    const [year, setYear] = useState(0);
    const [image, setImage]= useState('');

    const { id } = useParams();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;

    const deal = useSelector((state) => state.dealByIdDetails.deal);

    useEffect(() => {
        if(id !== deal?.deal_id) {
            dispatch(getDealById(id))
        }
    }, [dispatch, deal, id]);


    useEffect(() => {
        if(deal) {
            setDealId(deal?.deal_id)
            setName(deal?.name)
            setImage(deal?.image)
            setOriginalPrice(deal?.original_price)
            setDealPrice(deal?.deal_price);
            setMonth(deal?.month);
            setDay(deal?.day);
            setYear(deal?.year);
            setSize(deal?.size);
        }
    }, [deal])


    useEffect(() => {
        if (!userInfo) {
            navigate('/');
        }

    })



    const compressedUploader = async (image) => {
        new Compressor(image, {
            quality: 0.6,

            async success(result) {
              const formData = new FormData();

              formData.append('image', result);


              const res = await axios.post('/api/posts', formData, { headers: {'Content-Type': 'multipart/form-data'}});
              setCompressedFile(res.data);
              setNewImageArray(arr => [...arr, res.data])
              console.log(res.data);
              //setSuccess(true);
              //setAlert(false)
              //setFileB(res.data);
            },
            error(err) {
              console.log(err.message);
            },

        });

    }




    const addVenueHandler = async (e) => {
            e.preventDefault();


            if(name.trim() !== '' || sku.trim() !== '') {
                dispatch(
                    updateDeal({ original_price, deal_price, image, name, month, day, year, dealId, size })
                )
                setFinalAlert(false)

                navigate('/deals')
            } else {
                setFinalAlert(true)
            }


    }



    useEffect(() => {
        if(compressedFile !== null) {
            setImageArray(arr => [...arr, compressedFile])
        }
    }, [compressedFile])


    return (
        <div className="list">
            <Sidebar/>
            <div className="listContainer">
                <Navbar/>
                <Top>
                    <h1>Edit Product</h1>
                </Top>
                <Wrapper>
            <Container>
                <Label2>Product Name</Label2>
                <Input value={name} onChange={(e) => setName(e.target.value)} type='text' placeholder='Calvin Klein' />
                <label>Product Size/Weight(In ml or gm)</label>
                <input value={size} onChange={(e) =>  setSize(e.target.value)} type='number' placeholder="70" />
            </Container>

            <Container>
                <Label2>Original Price</Label2>
                <Input2 value={original_price} onChange={(e) => setOriginalPrice(e.target.value)} type='Number' placeholder="19" />
            </Container>

            <Container>
                <Label2>Deal Price</Label2>
                <Input2 value={deal_price} type='Number' onChange={(e) => setDealPrice(e.target.value)} placeholder="19" />
            </Container>

            <Container>
                <Label2>Month</Label2>
                <Input2 value={month} type='Number' onChange={(e) => setMonth(e.target.value)} placeholder="19" />
            </Container>

            <Container>
                <Label2>Day</Label2>
                <Input2 value={day} type='Number' onChange={(e) => setDay(e.target.value)} placeholder="19" />
            </Container>

            <Container>
                <Label2>Year</Label2>
                <Input2 value={year} type='Number' onChange={(e) => setYear(e.target.value)} placeholder="19" />
            </Container>
            <Button onClick={(e) => addVenueHandler(e)}>Add Product</Button>
        </Wrapper>
            </div>
        </div>
    )
}

const List  =  styled.div`
    display: flex;
    width: 100%;
`;


const ListContainer = styled.div`

`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 100px 30px;
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
    padding: 10px;
    margin: 20px;
    justify-content: center;
    align-items: center;
`;


const Top = styled.div`
    display: flex;
    flex-direction: column;
    padding: 10px 30px;
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);

    .h1{
        color: lightgray !important;
        font-size: 20px;
    }

`;



const Label = styled.label`
    padding: 20px 0px 10px 0px;
    font-size: 1.5rem;
    font-weight: ${WEIGHTS.medium};
    color: black;

`;

const Container = styled.div`
    display: flex;
    flex-direction: column;

`;

const Container2 = styled(Container)`
    flex-direction: row;
    align-items: center;
`;

const Label2 = styled(Label)`
    padding: 20px 0px 10px 0px;
    font-size: 1.5rem;
    font-weight: ${WEIGHTS.medium};
    color: black;
`;

const Input = styled.input`
    width: 350px;
    height: 50px;
    font-size: 1rem;
    border-radius: 4px;
    color: grey;
    outline: none;

    &:focus {
        border-color: #0081ba;
    }
`;


const Input2 = styled(Input)`
    width: 100px;
`;

const Image2 = styled.img`
    height: auto;
    max-width: 100%;
    vertical-align: middle;
    border-style: none;
    width: 360px;
    aspect-ratio: auto 674 / 495;
    height: 264px;
    padding-bottom: 30px;

    @media ${QUERIES.laptopAndUp} {
        width: 674px;
        height: 495px;
    }

    @media ${QUERIES.tabletOnly} {
        width: 518px;
        height: 380px;
    }


`;



const SelectWrapper = styled.div`
  position: relative;
  isolation: isolate;
  text-decoration: uppercase;
  display: flex;
  z-index: 1;
  align-items: flex-start;
  flex-direction: column;
  border-radius: 5px;
  background: #023E8A;
  width: 280px;
  padding-bottom: 20px;
  box-shadow: 10px ${COLORS.gray[1100]};
`;


const Select2 = styled.select`
  position: relative;
  appearance: none;
  filter: none;
  width: 280px;
  font-weight: ${WEIGHTS.normal};
  text-transform: uppercase;
  font-size: 0.8rem;
  padding: 20px 0px 0px 12px;
  border: none;
  border-radius: 20px;
  outline: none;
  color: white;
  autocomplete: off;
  background: transparent;
  z-index: 4;
  cursor: pointer;
  & > :focus {
    outline: 0;
    outline-color: transparent;
    outline-style: none;
  }
`;

const Option = styled.option`
  background: #023E8A;
  font-size: 0.8rem;
  text-transform: uppercase;
  font-weight: ${WEIGHTS.medium};
`;

const ChevronIcon = styled(Icon)`
  position: absolute;
  top: 1px;
  right: 10px;
  bottom: 2px;
  margin: auto;
  width: 24px;
  height: 24px;
  z-index: 3;
  color: white;
  cursor: inherit;
`;


const ImageInput = styled.input`
    content: 'Select Image';
    display: inline-block;
    background: linear-gradient(top, #f9f9f9, #e3e3e3);
    border: 1px solid #999;
    border-radius: 3px;
    padding: 5px 8px;
    margin-left: 26px;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    text-shadow: 1px 1px #fff;
    font-weight: 700;
    font-size: 10pt;

    @media ${QUERIES.laptopAndUp} {
        padding-left: 28px;
        margin-left: 26px;
    }

    @media ${QUERIES.tabletOnly} {
        padding-left: 28px;
        margin-left: 26px;
    }
`;



const TextArea = styled.textarea`

`;

const Button = styled.button`
    width: 150px;
    padding: 10px;
    margin: 8px;
    border-radius: 5px;
    border: 1px solid #023E8A;
    color: white;
    background: #023E8A;
    cursor: pointer;
`;


const Button2 = styled(Button)`
    width: 100px;
`;

const CheckInput = styled.input`
    width: 80px;
    height: 40px;
`;


export default EditDeal;
