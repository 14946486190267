import React, { useEffect, useState } from "react";
import "./addfranchise.scss";
import styled from "styled-components";
import axios from "axios";
import MDEditor from "@uiw/react-md-editor";

import ImageUploading from "react-images-uploading";
import Sidebar from "../../components/sidebar/Sidebar";
import Compressor from "compressorjs";
import { Select, MenuItem, InputLabel } from "@mui/material";
import Navbar from "../../components/navbar/Navbar";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import { WEIGHTS, QUERIES, COLORS } from "../../constants";
import { createFranchise } from "../../actions/franchiseActions";
import { listUtils } from "../../actions/utilActions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";


const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 100px 30px;
    justify-content: center;
    align-items: center;
`;

const Label = styled.label`
    padding: 20px 0px 10px 0px;
    font-size: 1.5rem;
    font-weight: ${WEIGHTS.medium};
    color: #023E8A;

`;

const Container = styled.div`
    display: flex;
    flex-direction: column;

    @media ${QUERIES.laptopAndUp} {

    }
`;

const Label2 = styled(Label)`
    padding: 20px 0px 10px 0px;
    font-size: 1.5rem;
    font-weight: ${WEIGHTS.medium};
    color: #023E8A;
`;

const Input = styled.input`
    width: 350px;
    height: 50px;
    font-size: 1rem;
    border-radius: 4px;
    color: grey;
    outline: none;

    &:focus {
        border-color: #0081ba;
    }
`;

const SelectWrapper = styled.div`
  position: relative;
  isolation: isolate;
  text-decoration: uppercase;
  display: flex;
  z-index: 1;
  align-items: flex-start;
  flex-direction: column;
  border-radius: 5px;
  background: #023E8A;
  width: 280px;
  padding-bottom: 20px;
  box-shadow: 10px ${COLORS.gray[1100]};
`;


const Select2 = styled.select`
  position: relative;
  appearance: none;
  filter: none;
  width: 280px;
  font-weight: ${WEIGHTS.normal};
  text-transform: uppercase;
  font-size: 0.8rem;
  padding: 20px 0px 0px 12px;
  border: none;
  border-radius: 20px;
  outline: none;
  color: white;
  autocomplete: off;
  background: transparent;
  z-index: 4;
  cursor: pointer;
  & > :focus {
    outline: 0;
    outline-color: transparent;
    outline-style: none;
  }
`;

const Option = styled.option`
  background: #023E8A;
  font-size: 0.8rem;
  text-transform: uppercase;
  font-weight: ${WEIGHTS.medium};
`;

/*const ChevronIcon = styled(Icon)`
  position: absolute;
  top: 1px;
  right: 10px;
  bottom: 2px;
  margin: auto;
  width: 24px;
  height: 24px;
  z-index: 3;
  color: white;
  cursor: inherit;
`;*/


const ImageInput = styled.input`
    content: 'Select Image';
    display: inline-block;
    background: linear-gradient(top, #f9f9f9, #e3e3e3);
    border: 1px solid #999;
    border-radius: 3px;
    padding: 5px 8px;
    margin-left: 26px;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    text-shadow: 1px 1px #fff;
    font-weight: 700;
    font-size: 10pt;

    @media ${QUERIES.laptopAndUp} {
        padding-left: 28px;
        margin-left: 26px;
    }

    @media ${QUERIES.tabletOnly} {
        padding-left: 28px;
        margin-left: 26px;
    }
`;



const TextArea = styled.textarea`

`;

const Button = styled.button`
    width: 150px;
    padding: 10px;
    margin: 8px;
    border-radius: 5px;
    border: 1px solid #023E8A;
    color: white;
    background: #023E8A;
    cursor: pointer;
`;

const Container2 = styled(Container)`
    flex-direction: row;
    align-items: center;
`;

const CheckInput = styled.input`
    width: 80px;
    height: 40px;
`;




const AddFranchise = ({ inputs, title }) => {
    const [franchiseId, setFranchiseId] = useState(0);
    const [longi, setLongi] = useState();
    const [lat, setLat] = useState();
    const [franchisee, setFranchisee] = useState('');
    const [phone_no, setPhone_no] = useState('')
    const [email, setEmail] = useState('');
    const [date, setDate] = useState('');
    const [address, setAddress] = useState('');
    const [coordinates, setCoordinates] = useState();
    const [file, setFile] = useState("");
    const [name, setName] = useState("");
    const [sku, setSku] = useState("");
    const [size, setSize] = useState("");
    const [price, setPrice] = useState("");
    const [para, setPara] = useState("");
    const [uploadAlert, setUploadAlert] = useState('bro');
    const [description, setDescription] = useState([]);
    const [sale, setSale] = useState("");
    const [stock, setStock] = useState("");
    const [saleArray, setSaleArray] = useState([]);
    const [priceArray, setPriceArray] = useState([]);
    const [sizeArray, setSizeArray] = useState([]);
    const [stockArray, setStockArray] = useState([]);
    const [category, setCategory] = useState("");
    const [sub_category, setSub_Category] = useState("");
    const [gender, setGender] = useState("");
    const [brand, setBrand] = useState("");
    const [compressedFile, setCompressedFile] = useState(null);
    const [images, setImages] = useState([]);
    const [imageArray, setImageArray] = useState([]);
    const [fileB, setFileB] = useState();

    const MAX_LENGTH = 4;


    const dispatch = useDispatch();

    const navigate = useNavigate();


    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;

    useEffect(() => {
        if(!userInfo) {
            navigate('/')
        }
    })


    const utils = useSelector((state) => state.utilList.utils);

    var id = "1";


    useEffect(() => {
        if(id) {
            dispatch(
                listUtils()
            )
        }
    }, [id]);

    var realDate = date;
    const addProductHandler = async (e) => {
        e.preventDefault();
        dispatch(
            createFranchise({ franchisee, phone_no, email, realDate, address, coordinates })
        )
        navigate('/franchises')
    }

    const coHandler = (e) => {
        e.preventDefault()
        setCoordinates([longi, lat])
    }

  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div className="top">
          <h1>Add New Franchisee</h1>
        </div>
        <div className="bottom">
          <div className="right">
            <form>
              <div className="formInput">
              </div>
                <div className="formInput">
                    <label>Franchisee</label>
                    <input value={franchisee} onChange={(e) => setFranchisee(e.target.value)} type='text' placeholder='' />
                </div>
                <div className="formInput">
                    <label>Phone No</label>
                    <input value={phone_no} onChange={(e) => setPhone_no(e.target.value)} type='text' placeholder='' />
                </div>
                <div className="formInput">
                    <label>Email</label>
                    <input value={email} onChange={(e) => setEmail(e.target.value)} type='text' placeholder='' />
                </div>
                <div className="formInput">
                    <label>Date</label>
                    <input value={date} onChange={(e) => setDate(e.target.value)} type='text' placeholder='dd/mm/yyyy' />
                    {date?
                        <h1>{date.toString()}</h1>
                    : null
                    }
                </div>
                <div className="formInput">
                    <label>Address</label>
                    <input value={address} onChange={(e) => setAddress(e.target.value)} type='text' placeholder=''  />
                </div>
                <div className="formInput">
                    <label>Co-ordinates</label>
                    <input value={longi} onChange={(e) => setLongi(e.target.value)} type='number' placeholder='Longitude' />
                    <input value={lat} onChange={(e) => setLat(e.target.value)} type='number' placeholder='Latitude' />
                    <Button onClick={(e) => coHandler(e)}>Set Co-ordinates</Button>
                </div>
                <div className="formInput"></div>
                <div className="formInput">

                </div>

            </form>
            <Wrapper2 data-color-mode="light">
                <Button onClick={(e) => addProductHandler(e)}>Add Franchise</Button>
            </Wrapper2>

        </div>
        </div>
      </div>
    </div>

  );
};


const Wrapper2 = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    width 100%;
    height: auto;
`;

export default AddFranchise;
