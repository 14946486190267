import React, { useState, useEffect } from 'react';
import "./franchiseEdit.scss"

import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import MDEditor from "@uiw/react-md-editor";
import { MenuItem, InputLabel } from "@mui/material";
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import Compressor from 'compressorjs';
import { WEIGHTS, QUERIES, COLORS, NavLinks, AREA, VENUE, SUBTYPE, SUBURB, MEL_SUBURB, SYD_SUBURB, BRIS_SUBURB, ADEL_SUBURB, PERTH_SUBURB } from '../../constants';
import { getFranchiseById, updateFranchise } from '../../actions/franchiseActions';
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { listUtils } from '../../actions/utilActions';
import Icon from '../../components/Icon';

const EditFranchise = () => {
    const [longi, setLongi] = useState();
    const [lat, setLat] = useState();
    const [franchiseId, setFranchiseId] = useState(0);
    const [franchisee, setFranchisee] = useState(0);
    const [phone_no, setPhone_no] = useState('')
    const [email, setEmail] = useState('');
    const [date, setDate] = useState('');
    const [address, setAddress] = useState();
    const [coordinates, setCoordinates] = useState('');
    const [priceIdArray, setPriceIdArray] = useState([]);
    const [newImageArray, setNewImageArray] = useState([]);
    const [idIndex, setIdIndex] = useState(0);
    const [finalVenue, setFinalVenue] = useState(false);
    const [finalVenueAlert, setFinalVenueAlert] = useState(false);
    const [finalAlert, setFinalAlert] = useState('');
    const [newLink,  setNewLink] = useState('');
    const [newImages, setNewImages] = useState([]);
    const [capInd, setCapInd] = useState();
    const [newLinkText, setNewLinkText] = useState('');
    const [newDescriptionArray, setNewDescriptionArray] = useState([]);
    const [descriptionIdArray, setDescriptionIdArray] = useState([]);
    const [descriptions, setDescriptions] = useState('');
    const [area, setArea] = useState('regional-nsw');
    const [fileBSingleEdit ,setFileBSingleEdit] = useState();
    const [suburb, setSuburb] = useState('alexandria');
    const [type, setType] = useState('bars');
    const [alertNewDesImage, setAlertNewDesImage] = useState(false);
    const [successNewDesImage, setSuccessNewDesImage] = useState(false);
    const [sub_type, setSub_Type] = useState('hidden-bars');
    const [descriptionC, setDescriptionC] = useState([]);
    const [images, setImages] = useState([]);
    const [imageArray, setImageArray] = useState([]);
    const [success, setSuccess] = useState(false);
    const [descriptionId, setDescriptionId] = useState(0);

    const [compressedFile, setCompressedFile] = useState(null);

    const [alert, setAlert] = useState(false);


    const [fileB, setFileB] = useState();
    const [room, setRoom] = useState('');
    const [newRoom, setNewRoom] = useState('');
    const [min, setMin] = useState('');
    const [newMin, setNewMin] = useState('');
    const [editId, setEditId] = useState('');
    const [max, setMax] = useState('');
    const [newMax, setNewMax] = useState('');
    const [phone, setPhone] = useState('');
    const [isDraft, setIsDraft] = useState(false);
    const [companyEmail, setCompanyEmail] = useState('');
    const [website, setWebsite] = useState('');
    const [maps, setMaps] = useState('');
    const [imageId, setImageId] = useState(0);
    const [imageIdArray, setImageIdArray] = useState([]);
    const [capacityIdArray, setCapacityIdArray] = useState([]);
    const [file, setFile] = useState("");
    const [status, setStatus] = useState('');
    const [name, setName] = useState("");
    const [sku, setSku] = useState("");
    const [size, setSize] = useState("");
    const [price, setPrice] = useState("");
    const [description, setDescription] = useState([]);
    const [sale, setSale] = useState("");
    const [stock, setStock] = useState("");
    const [size2, setSize2] = useState("");
    const [price2, setPrice2] = useState("");
    const [sale2, setSale2] = useState("");
    const [stock2, setStock2] = useState("");
    const [saleArray, setSaleArray] = useState([]);
    const [sizeArray, setSizeArray] = useState([]);
    const [stockArray, setStockArray] = useState([]);
    const [priceArray, setPriceArray] = useState([]);
    const [priceId, setPriceId] = useState();
    const [newPriceArray, setNewPriceArray] = useState([]);
    const [newSaleArray, setNewSaleArray] = useState([]);
    const [newSizeArray, setNewSizeArray] = useState([]);
    const [newStockArray, setNewStockArray] = useState([]);


    const [sub_category, setSub_Category] = useState("");
    const [gender, setGender] = useState("");
    const [brand, setBrand] = useState("");

    const { id } = useParams();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;

    const franchise = useSelector((state) => state.franchiseByIdDetails.franchise);

    useEffect(() => {
        if(id !== franchise?.id) {
            dispatch(getFranchiseById(id))
        }
    }, [dispatch, franchise, id]);


    useEffect(() => {
        if(franchise) {
            setFranchiseId(franchise?.id)
            setFranchisee(franchise?.franchisee)
            setPhone_no(franchise?.phone_no)
            setEmail(franchise?.email)
            setDate(franchise?.registered_date)
            setAddress(franchise?.address)
            setCoordinates(franchise?.coordinates);
            setLongi(franchise?.coordinates?.[0]);
            setStatus(franchise?.status);
            setLat(franchise?.coordinates?.[1]);

        }
    }, [franchise])

    useEffect(() => {
        if (!userInfo) {
            navigate('/');
        }

    })



    const addVenueHandler = async (e) => {
            e.preventDefault();
            var realDate = date;
            if(franchisee.trim() !== '' || coordinates?.length !== 0) {
                dispatch(
                    updateFranchise({ franchiseId, franchisee, phone_no, email, realDate, address, coordinates, status })
                )
                setFinalAlert(false)

                navigate('/franchises')
            } else {
                setFinalAlert(true)
            }


    }

    const coHandler = (e) => {
        e.preventDefault()
        setCoordinates([longi, lat])
    }

    const statuss = [['sold', 'Sold'], ['unsold', 'unsold']]

    const onGenderChange = (e) => {
        const selectedGender= e.target.value;
        /*if(selectedSubType === 'any') {
            setSubType(selectedSubType);
        } else {
            const selectedSubTypeId = SUBTYPE.filter((t) => t.id === selectedSubType)[0]? SUBTYPE.filter((t) => t.id === selectedSubType)[0] : utils?.subtype?.filter((a) => a[0] === selectedSubType)[0];
            const realSubType = selectedSubTypeId.id? selectedSubTypeId.id : selectedSubTypeId[0];
            console.log(realSubType)
            setSubType(realSubType);
        } */

        const selectedGenderId = statuss.filter((a) => a[0] === selectedGender)[0];
        const realGender = selectedGenderId.id? selectedGenderId.id : selectedGenderId[0];
        console.log(realGender)
        setStatus(realGender);
    };

    return (
        <div className="list">
            <Sidebar/>
            <div className="listContainer">
                <Navbar/>
                <Top>
                    <h1>Edit Franchisee</h1>
                </Top>
                <Wrapper>
            <Container>
                <Label2>Franchisee</Label2>
                <Input value={franchisee} onChange={(e) => setFranchisee(e.target.value)} type='text' placeholder='' />
            </Container>
            <Container>
                <Label2>Phone No.</Label2>
                <Input value={phone_no} onChange={(e) => setPhone_no(e.target.value)} type='text' placeholder='' />
            </Container>
            <Container>
                <Label2>Email</Label2>
                <Input value={email} onChange={(e) => setEmail(e.target.value)} type='text' placeholder='' />
            </Container>
            <Container>
                <Label2>Date</Label2>
                <Input value={date} onChange={(e) => setDate(e.target.value)} type='text' placeholder='dd/mm/yyyy' />
                <h1>{date}</h1>
            </Container>

                <Label2>Status</Label2>
                <SelectWrapper>
                                            <Select2
                                                value={status}
                                                onChange={(e) => {
                                                    onGenderChange(e);
                                                }}
                                            >
                                                        {statuss?.map((s, index) => (
                                                            <Option key={index} value={s?.[0]}>
                                                                {s?.[1]}
                                                            </Option>
                                                        ))}

                                            </Select2>
                                            <ChevronIcon
                                                id="chevron-down"
                                                size={24}
                                                strokeWidth={1.5}
                                            />
                                        </SelectWrapper>

            <Container>
                <Label2>Address</Label2>
                <Input value={address} onChange={(e) => setAddress(e.target.value)} type='text' placeholder='' />
            </Container>
            <Container>
                <Label2>Co-ordinates</Label2>
                <Input value={longi} onChange={(e) => setLongi(e.target.value)} type='number' placeholder='Longitude' />
                <Input value={lat} onChange={(e) => setLat(e.target.value)} type='number' placeholder='Latitude' />
                <Button onClick={(e) => coHandler(e)}>Set Co-ordinates</Button>
            </Container>
            <Button onClick={(e) => addVenueHandler(e)}>Add Franchise</Button>
        </Wrapper>
            </div>
        </div>
    )
}

const List  =  styled.div`
    display: flex;
    width: 100%;
`;


const ListContainer = styled.div`

`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 100px 30px;
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
    padding: 10px;
    margin: 20px;
    justify-content: center;
    align-items: center;
`;


const Top = styled.div`
    display: flex;
    flex-direction: column;
    padding: 10px 30px;
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);

    .h1{
        color: lightgray !important;
        font-size: 20px;
    }

`;



const Label = styled.label`
    padding: 20px 0px 10px 0px;
    font-size: 1.5rem;
    font-weight: ${WEIGHTS.medium};
    color: black;

`;

const Container = styled.div`
    display: flex;
    flex-direction: column;

`;

const Container2 = styled(Container)`
    flex-direction: row;
    align-items: center;
`;

const Label2 = styled(Label)`
    padding: 20px 0px 10px 0px;
    font-size: 1.5rem;
    font-weight: ${WEIGHTS.medium};
    color: black;
`;

const Input = styled.input`
    width: 350px;
    height: 50px;
    font-size: 1rem;
    border-radius: 4px;
    color: grey;
    outline: none;

    &:focus {
        border-color: #0081ba;
    }
`;


const Input2 = styled(Input)`
    width: 100px;
`;

const Image2 = styled.img`
    height: auto;
    max-width: 100%;
    vertical-align: middle;
    border-style: none;
    width: 360px;
    aspect-ratio: auto 674 / 495;
    height: 264px;
    padding-bottom: 30px;

    @media ${QUERIES.laptopAndUp} {
        width: 674px;
        height: 495px;
    }

    @media ${QUERIES.tabletOnly} {
        width: 518px;
        height: 380px;
    }


`;



const SelectWrapper = styled.div`
  position: relative;
  isolation: isolate;
  text-decoration: uppercase;
  display: flex;
  z-index: 1;
  align-items: flex-start;
  flex-direction: column;
  border-radius: 5px;
  background: #023E8A;
  width: 280px;
  padding-bottom: 20px;
  box-shadow: 10px ${COLORS.gray[1100]};
`;


const Select2 = styled.select`
  position: relative;
  appearance: none;
  filter: none;
  width: 280px;
  font-weight: ${WEIGHTS.normal};
  text-transform: uppercase;
  font-size: 0.8rem;
  padding: 20px 0px 0px 12px;
  border: none;
  border-radius: 20px;
  outline: none;
  color: white;
  autocomplete: off;
  background: transparent;
  z-index: 4;
  cursor: pointer;
  & > :focus {
    outline: 0;
    outline-color: transparent;
    outline-style: none;
  }
`;

const Option = styled.option`
  background: #023E8A;
  font-size: 0.8rem;
  text-transform: uppercase;
  font-weight: ${WEIGHTS.medium};
`;

const ChevronIcon = styled(Icon)`
  position: absolute;
  top: 1px;
  right: 10px;
  bottom: 2px;
  margin: auto;
  width: 24px;
  height: 24px;
  z-index: 3;
  color: white;
  cursor: inherit;
`;


const ImageInput = styled.input`
    content: 'Select Image';
    display: inline-block;
    background: linear-gradient(top, #f9f9f9, #e3e3e3);
    border: 1px solid #999;
    border-radius: 3px;
    padding: 5px 8px;
    margin-left: 26px;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    text-shadow: 1px 1px #fff;
    font-weight: 700;
    font-size: 10pt;

    @media ${QUERIES.laptopAndUp} {
        padding-left: 28px;
        margin-left: 26px;
    }

    @media ${QUERIES.tabletOnly} {
        padding-left: 28px;
        margin-left: 26px;
    }
`;



const TextArea = styled.textarea`

`;

const Button = styled.button`
    width: 150px;
    padding: 10px;
    margin: 8px;
    border-radius: 5px;
    border: 1px solid #023E8A;
    color: white;
    background: #023E8A;
    cursor: pointer;
`;



const Button2 = styled(Button)`
    width: 100px;
`;

const CheckInput = styled.input`
    width: 80px;
    height: 40px;
`;


export default EditFranchise;
