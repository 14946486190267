export const raffleListReducer = (state = { raffles: []}, action) => {
    switch (action.type) {
        case 'RAFFLE_LIST_REQUEST':
            return { raffles: [] }
        case 'RAFFLE_LIST_SUCCESS':
            return {
                raffles: action.payload,
            }
        case 'RAFFLE_LIST_FAIL':
            return { error: action.payload }
        default:
            return state
    }
}


export const raffleByIdReducer = (state = { raffle: []}, action) => {
    switch (action.type) {
        case 'RAFFLE_DETAILS_BY_ID_REQUEST':
            return {...state}
        case 'RAFFLE_DETAILS_BY_ID_SUCCESS':
            return { raffle: action.payload }
        default:
            return state
    }
}


export const raffleCreateReducer = ( state = {}, action) => {
    switch (action.type) {
        case 'RAFFLE_CREATE_SUCCESS':
            return { raffle: action.payload }
        default:
            return state
    }
}

export const updateRaffleReducer = ( state = {}, action) => {
    switch (action.type) {
        case 'RAFFLE_UPDATE_SUCCESS':
            return { raffle: action.payload }
        default:
            return state
    }
}




