import React from 'react';
import "./dealdatatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { dealColumns, userRows } from "../../datatablesource";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { listDeals, deleteDeal } from "../../actions/dealActions";
import { useDispatch, useSelector } from "react-redux";

const DealDatatable = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
      if(!userInfo) {
          navigate('/bro')
      }
  })

  const deals = useSelector((state) => state.dealList.deals);

  useEffect(() => {
    if(userInfo) {
      dispatch(listDeals())
    }
  }, [dispatch, userInfo])


  const [data, setData] = useState(deals);

  function refreshPage() {
    window.location.reload(false);
  }

  const handleDelete = (id, e) => {
    //setData(data.filter((item) => item.id !== id));
    e.preventDefault();
    dispatch(deleteDeal(id));
    refreshPage()
  };

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Link to={`/deals/edit/${params.row.deal_id}`} style={{ textDecoration: "none" }}>
              <div className="viewButton">Edit</div>
            </Link>
            <div
              className="deleteButton"
              onClick={(e) => handleDelete(params.row.deal_id, e)}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];
  return (
    <div className="datatable">
      <div className="datatableTitle">
        Add New Deal
        <Link to="/deals/new" className="link">
          Add New
        </Link>
      </div>
      {deals?
        <DataGrid
          className="datagrid"
          rows={deals}
          columns={dealColumns.concat(actionColumn)}
          pageSize={9}
          getRowId={(row) =>  row.deal_id}
          rowsPerPageOptions={[9]}
          checkboxSelection
        />
      : null
      }

    </div>
  );
};

export default DealDatatable;
