import React from 'react';
import "./promos.scss"
import Sidebar from "../../components/sidebar/Sidebar"
import Navbar from "../../components/navbar/Navbar"
import PromoDatatable from "../../components/promodatatable/PromoDatatable"

const Promo = () => {
  return (
    <div className="list">
      <Sidebar/>
      <div className="listContainer">
        <Navbar/>
        <PromoDatatable />
      </div>
    </div>
  )
}

export default Promo;
