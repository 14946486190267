import axios from 'axios';
 

export const listUsers = () => async (dispatch, getState) => {
    try {
        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
              Authorization: `Bearer ${userInfo.token}`,
            },
        }
        dispatch({ type: 'USERS_LIST_REQUEST'});

        const { data } = await axios.get(`/api/users`, config); 
        const dataRow = data.data.users
        console.log(dataRow)
        dispatch({
            type: 'USERS_LIST_SUCCESS',
            payload: dataRow,
        })
    } catch (error) {
        dispatch({
            type: 'USERS_LIST_FAIL',
            payload:
                error.response && error.response.data.message
                    ?error.response.data.message
                    :error.message,
        })
    }
}




export const login = (email, password) => async (dispatch) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
            }
        }

        const { data } = await  axios.post(
            '/api/users/login',
            {email, password},
            config
        ) 
        
        console.log(data);
        

        dispatch({
            type: 'USER_LOGIN_SUCCESS',
            payload: data,
        })

        console.log(data);

        localStorage.setItem('userInfo', JSON.stringify(data))

    } catch (error) {
        console.log(error);
    }
}



export const register = (name, email, password) => async (dispatch, getState) => {
    try {
        
      dispatch({
        type: 'USER_REGISTER_REQUEST',
      })
      const {
        userLogin: { userInfo },
    } = getState()
  
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      }
  
      const { data } = await axios.post(
        '/api/users',
        { name, email, password},
        config
      )
  
      dispatch({
        type: 'USER_REGISTER_SUCCESS',
        payload: data,
      })
  
      //localStorage.setItem('userInfo', JSON.stringify(data))
    } catch (error) {
      dispatch({
        type: 'USER_REGISTER_FAIL',
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
}




export const logout = () => (dispatch) => {
    localStorage.removeItem('userInfo')
    dispatch({ type: 'USER_LOGOUT' })

    document.location.href = '/'
}


export const getUserDetails = (id) => async (dispatch, getState) => {
    try {
        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
              Authorization: `Bearer ${userInfo.token}`,
            },
        }

        const { data } = await axios.post(`/api/users/${id}`, config)
        dispatch({
            type: 'USER_DETAILS_SUCCESS',
            payload: data,
        })
    } catch (error) {

        if(error === 'Not authorized, token failed') {
            dispatch(logout())
        }
        console.log(error);
    }
}




export const deleteUser = (id) => async (dispatch, getState) => {
    try {
        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
              Authorization: `Bearer ${userInfo.token}`,
            },
        }
        const { data } = await axios.delete(`/api/users/${id}`, config);
        dispatch({
            type: 'DELETE_USER_SUCCESS',
            payload: data,
        })
    } catch (error) {
        console.log(error);
    }
}


export const userStatus = (id) => async (dispatch, getState) => {
    try {
        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
              Authorization: `Bearer ${userInfo.token}`,
            },
        }

        const { data } = await axios.post('/api/users/status', {
            id
        }, config)
        dispatch({
            type: 'USER_STATUS_SUCCESS',
            payload: data,
        })
    } catch (error) {

        if(error === 'Not authorized, token failed') {
            dispatch(logout())
        }
        console.log(error);
    }
}