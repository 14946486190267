import React, { useEffect, useState } from "react";
import "./addpromo.scss";
import styled from "styled-components";
import axios from "axios";

import ImageUploading from "react-images-uploading";
import Sidebar from "../../components/sidebar/Sidebar";
import { useParams } from "react-router-dom";
import Compressor from "compressorjs";
import { Select, MenuItem, InputLabel } from "@mui/material";
import Navbar from "../../components/navbar/Navbar";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import { WEIGHTS, QUERIES, COLORS } from "../../constants";
import { createPromo } from "../../actions/promoActions";
import { listUtils } from "../../actions/utilActions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { orderStatus } from "../../actions/paymentActions";

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 100px 30px;
    justify-content: center;
    align-items: center;
`;

const Label = styled.label`
    padding: 20px 0px 10px 0px;
    font-size: 1.5rem;
    font-weight: ${WEIGHTS.medium};
    color: #023E8A;

`;

const Container = styled.div`
    display: flex;
    flex-direction: column;

    @media ${QUERIES.laptopAndUp} {

    }
`;

const Label2 = styled(Label)`
    padding: 20px 0px 10px 0px;
    font-size: 1.5rem;
    font-weight: ${WEIGHTS.medium};
    color: #023E8A;
`;

const Input = styled.input`
    width: 350px;
    height: 50px;
    font-size: 1rem;
    border-radius: 4px;
    color: grey;
    outline: none;

    &:focus {
        border-color: #0081ba;
    }
`;

const SelectWrapper = styled.div`
  position: relative;
  isolation: isolate;
  text-decoration: uppercase;
  display: flex;
  z-index: 1;
  align-items: flex-start;
  flex-direction: column;
  border-radius: 5px;
  background: #023E8A;
  width: 280px;
  padding-bottom: 20px;
  box-shadow: 10px ${COLORS.gray[1100]};
`;


const Select2 = styled.select`
  position: relative;
  appearance: none;
  filter: none;
  width: 280px;
  font-weight: ${WEIGHTS.normal};
  text-transform: uppercase;
  font-size: 0.8rem;
  padding: 20px 0px 0px 12px;
  border: none;
  border-radius: 20px;
  outline: none;
  color: white;
  autocomplete: off;
  background: transparent;
  z-index: 4;
  cursor: pointer;
  & > :focus {
    outline: 0;
    outline-color: transparent;
    outline-style: none;
  }
`;

const Option = styled.option`
  background: #023E8A;
  font-size: 0.8rem;
  text-transform: uppercase;
  font-weight: ${WEIGHTS.medium};
`;

/*const ChevronIcon = styled(Icon)`
  position: absolute;
  top: 1px;
  right: 10px;
  bottom: 2px;
  margin: auto;
  width: 24px;
  height: 24px;
  z-index: 3;
  color: white;
  cursor: inherit;
`;*/


const ImageInput = styled.input`
    content: 'Select Image';
    display: inline-block;
    background: linear-gradient(top, #f9f9f9, #e3e3e3);
    border: 1px solid #999;
    border-radius: 3px;
    padding: 5px 8px;
    margin-left: 26px;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    text-shadow: 1px 1px #fff;
    font-weight: 700;
    font-size: 10pt;

    @media ${QUERIES.laptopAndUp} {
        padding-left: 28px;
        margin-left: 26px;
    }

    @media ${QUERIES.tabletOnly} {
        padding-left: 28px;
        margin-left: 26px;
    }
`;



const TextArea = styled.textarea`

`;

const Button = styled.button`
    width: 150px;
    padding: 10px;
    margin: 8px;
    border-radius: 5px;
    border: 1px solid #023E8A;
    color: white;
    background: #023E8A;
    cursor: pointer;
`;

const Container2 = styled(Container)`
    flex-direction: row;
    align-items: center;
`;

const CheckInput = styled.input`
    width: 80px;
    height: 40px;
`;




const AddPromo = ({ inputs, title }) => {

    const [status, setStatus] = useState("");

    const [track, setTrack] = useState("");

    const [codes, setCodes] = useState('');

    const [percent, setPercent] = useState();


    const MAX_LENGTH = 4;

    const { id } = useParams();


    const dispatch = useDispatch();

    const navigate = useNavigate();


    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;

    useEffect(() => {
        if(!userInfo) {
            navigate('/')
        }
    })


    const order = [['active', 'Active'], ['inactive', 'Inactive']];



    const onStatusChange = (e) => {
        const selectedGender= e.target.value;
        const selectedGenderId = order.filter((a) => a[0] === selectedGender)[0];
        const realGender = selectedGenderId.id? selectedGenderId.id : selectedGenderId[0];
        console.log(realGender)
        setStatus(realGender);
    };


    const orderStatusHandler = async (e) => {
        e.preventDefault();
        dispatch(
            createPromo({ codes, percent, status })
        )
        navigate('/promos')
    }




  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div className="top">
          <h1>Add Promo</h1>
        </div>
        <div className="bottom">
            <h1></h1>
                    <Label>Code</Label>
                    <Input value={codes} onChange={(e) => setCodes(e.target.value)} type='text' placeholder='Promo Code' />

                    <Label>Discount Percent</Label>
                    <Input value={percent} onChange={(e) => setPercent(e.target.value)} type='text' placeholder='percent' />

                <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={status}
                        label="Status"
                        onChange={onStatusChange}
                    >
                        {order?.map((s, index) => (
                            <MenuItem key={index} value={s?.[0]}>
                                {s?.[1]}
                            </MenuItem>
                        ))}
                    </Select>

            <Button onClick={(e) => orderStatusHandler(e)}>Add Promo</Button>
        </div>
        </div>
    </div>
  );
};

export default AddPromo;
