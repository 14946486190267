import { combineReducers, compose, createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension'; //its basically for testing redux in browser, see in more details

import {
    productCreateReducer,
    productListReducer,
    productByIdReducer,
} from './reducers/productReducers';

import {
    dealCreateReducer,
    dealListReducer,
    dealByIdReducer,
} from './reducers/dealReducers';

import {
    promoListReducer,
    promoByIdReducer,
    promoCreateReducer,
} from './reducers/promoReducers';

import {
    raffleCreateReducer,
    raffleByIdReducer,
    raffleListReducer,
} from './reducers/raffleReducers';

import {
    franchiseCreateReducer,
    franchiseListReducer,
    deleteFranchiseReducer,
    franchiseByIdReducer,
    updateFranchiseReducer,
} from './reducers/franchiseReducers';

import {
    userLoginReducer,
    userDetailsReducer,
    userListReducer,
} from './reducers/userReducers';

import {
    createCustomerReducer,
    getCustomerReducer,
    orderListReducer,
    subscriberListReducer,
} from './reducers/paymentReducers';


import {
    utilListReducer,
} from './reducers/utilReducers';

const reducer = combineReducers({
    productList: productListReducer,
    promoList: promoListReducer,
    raffleList: raffleListReducer,
    dealList: dealListReducer,
    franchiseList: franchiseListReducer,
    productCreate: productCreateReducer,
    promoCreate: promoCreateReducer,
    dealCreate: dealCreateReducer,
    raffleCreate: raffleCreateReducer,
    franchiseCreate: franchiseCreateReducer,
    userLogin: userLoginReducer,
    userList: userListReducer,
    orderList: orderListReducer,
    subscriberList: subscriberListReducer,
    userDetails: userDetailsReducer,
    productByIdDetails: productByIdReducer,
    promoByIdDetails: promoByIdReducer,
    raffleByIdDetails: raffleByIdReducer,
    dealByIdDetails: dealByIdReducer,
    franchiseByIdDetails: franchiseByIdReducer,
    utilList: utilListReducer,
    createCustomers: createCustomerReducer,
    getCustomers: getCustomerReducer,
});

const userInfoFromLocalStorage = localStorage.getItem('userInfo')
    ? JSON.parse(localStorage.getItem('userInfo'))
    : null

const middleware = [thunk]

const initialState = {

    userLogin: {userInfo: userInfoFromLocalStorage},
}

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; 

const store = createStore(
    reducer,
    initialState,
    composeEnhancer(applyMiddleware(...middleware))
)

export default store;
