import React, { useState, useEffect } from 'react';
import "./productEditMain.scss"
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import MDEditor from "@uiw/react-md-editor";
import { Select, MenuItem, InputLabel } from "@mui/material";
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import Compressor from 'compressorjs';
import { WEIGHTS, QUERIES, COLORS, NavLinks, AREA, VENUE, SUBTYPE, SUBURB, MEL_SUBURB, SYD_SUBURB, BRIS_SUBURB, ADEL_SUBURB, PERTH_SUBURB } from '../../constants';
import { getProductById, updateProduct } from '../../actions/productActions';
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { listUtils } from '../../actions/utilActions';
import Icon from '../../components/Icon';

const EditProduct = () => {
    const [productId, setProductId] = useState(0);
    const [fileBNewImages, setFileBNewImages] = useState();

    const [descriptionArray, setDescriptionArray] = useState([]);
    const [capacityId, setCapacityId] = useState('');
    const [newSingleImage, setNewSingleImage] = useState('');
    const [newPara, setNewPara] = useState('');
    const [priceIdArray, setPriceIdArray] = useState([]);
    const [newImageArray, setNewImageArray] = useState([]);
    const [idIndex, setIdIndex] = useState(0);
    const [finalVenue, setFinalVenue] = useState(false);
    const [finalVenueAlert, setFinalVenueAlert] = useState(false);
    const [finalAlert, setFinalAlert] = useState('');
    const [newLink,  setNewLink] = useState('');
    const [newImages, setNewImages] = useState([]);
    const [capInd, setCapInd] = useState();
    const [newLinkText, setNewLinkText] = useState('');
    const [newDescriptionArray, setNewDescriptionArray] = useState([]);
    const [descriptionIdArray, setDescriptionIdArray] = useState([]);
    const [descriptions, setDescriptions] = useState('');
    const [area, setArea] = useState('regional-nsw');
    const [fileBSingleEdit ,setFileBSingleEdit] = useState();
    const [suburb, setSuburb] = useState('alexandria');
    const [type, setType] = useState('bars');
    const [alertNewDesImage, setAlertNewDesImage] = useState(false);
    const [successNewDesImage, setSuccessNewDesImage] = useState(false);
    const [sub_type, setSub_Type] = useState('hidden-bars');
    const [descriptionC, setDescriptionC] = useState([]);
    const [images, setImages] = useState([]);
    const [imageArray, setImageArray] = useState([]);
    const [success, setSuccess] = useState(false);
    const [descriptionId, setDescriptionId] = useState(0);

    const [compressedFile, setCompressedFile] = useState(null);

    const [alert, setAlert] = useState(false);


    const [fileB, setFileB] = useState();
    const [editId, setEditId] = useState('');
    const [imageId, setImageId] = useState(0);
    const [imageIdArray, setImageIdArray] = useState([]);
    const [capacityIdArray, setCapacityIdArray] = useState([]);
    const [file, setFile] = useState("");
    const [name, setName] = useState("");
    const [sku, setSku] = useState("");
    const [size, setSize] = useState("");
    const [price, setPrice] = useState("");
    const [description, setDescription] = useState([]);
    const [sale, setSale] = useState("");
    const [stock, setStock] = useState("");
    const [size2, setSize2] = useState("");
    const [price2, setPrice2] = useState("");
    const [sale2, setSale2] = useState("");
    const [stock2, setStock2] = useState("");
    const [saleArray, setSaleArray] = useState([]);
    const [sizeArray, setSizeArray] = useState([]);
    const [stockArray, setStockArray] = useState([]);
    const [priceArray, setPriceArray] = useState([]);
    const [priceId, setPriceId] = useState();
    const [newPriceArray, setNewPriceArray] = useState([]);
    const [newSaleArray, setNewSaleArray] = useState([]);
    const [newSizeArray, setNewSizeArray] = useState([]);
    const [newStockArray, setNewStockArray] = useState([]);


    const [sub_category, setSub_Category] = useState("");
    const [gender, setGender] = useState("");
    const [brand, setBrand] = useState("");

    const { id } = useParams();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;

    const product = useSelector((state) => state.productByIdDetails.product);

    useEffect(() => {
        if(id !== product?.id) {
            dispatch(getProductById(id))
        }
    }, [dispatch, product, id]);


    const utils = useSelector((state) => state.utilList.utils);

    var ids = "1";


    useEffect(() => {
        if(ids) {
            dispatch(
                listUtils()
            )
        }
    }, [ids]);

    useEffect(() => {
        if(product) {
            setProductId(product?.id)
            setName(product?.name)
            setSku(product?.sku)
            setCategory(product?.category)
            setSub_Category(product?.sub_category)
            setBrand(product?.brand)
            setGender(product?.gender)
            setImages(product?.images)
            setDescriptionArray(product?.description)
            setPriceArray(product?.prices)
            setStockArray(product?.stock)
            setSizeArray(product?.sizes)
            setSaleArray(product?.saleprices);
            setDescriptions(product?.descriptions)
        }
    }, [product])

    const [category, setCategory] = useState(product?.category);


    const onCategoryChange = (e) => {
        const selectedCategory = e.target.value;
        const selectedCategoryId = utils?.category?.filter((a) => a[0] === selectedCategory)[0];
        const realCategory = selectedCategoryId.id? selectedCategoryId.id : selectedCategoryId[0];
        console.log(realCategory)
        setCategory(realCategory);
    };


    const onSubCategoryChange = (e) => {
        const selectedSubCategory = e.target.value;
        const selectedSubCategoryId = utils?.sub_category?.filter((a) => a[0] === selectedSubCategory)[0];
        const realSubCategory = selectedSubCategoryId.id? selectedSubCategoryId.id : selectedSubCategoryId[0];
        console.log(realSubCategory)
        setSub_Category(realSubCategory);
    };


    const onBrandChange = (e) => {
        const selectedBrand = e.target.value;

        const selectedBrandId = utils?.brand?.filter((a) => a[0] === selectedBrand)[0];
        const realBrand = selectedBrandId.id? selectedBrandId.id : selectedBrandId[0];
        console.log(realBrand)
        setBrand(realBrand);
    };


    const onGenderChange = (e) => {
        const selectedGender= e.target.value;


        const selectedGenderId = genders.filter((a) => a[0] === selectedGender)[0];
        const realGender = selectedGenderId.id? selectedGenderId.id : selectedGenderId[0];
        console.log(realGender)
        setGender(realGender);
    };



    useEffect(() => {
        if (!userInfo) {
            navigate('/');
        }

    })


    const handleCompressedUpload = (e) => {
        var MAX_LENGTH = 4
        var realAllowed = 0
        var  already = imageIdArray?.length
        var before = images?.length
        if(already === 0) {
            realAllowed = MAX_LENGTH - before
        } else {
            realAllowed = MAX_LENGTH - already
        }
        if (Array.from(e.target.files).length >  realAllowed) {
            e.preventDefault();
            console.log(`Cannot upload files more than ${MAX_LENGTH}`);
            return;
        } else {
            setNewImages(e.target.files);
        }
    };


    const setPriceIdHandler = () => {

    }


    const handleCompressedNewImagesUpload = (e) => {
        const image = e.target.files[0];
        setFileBNewImages(image);
    };

    const handleCompressedEditSingleUpload = (e) => {
        const image = e.target.files[0];
        setFileBSingleEdit(image);
    };


    const imageHandlerr = (images) => {
        setImageArray(arr => [...arr, images])

    }




    const imageHandler = async (id, sd) => {
        if(compressedFile) {
            console.log('compren', compressedFile);
        }
        console.log(fileB);
        if(!fileB){
            setAlert(true)
        } else if(fileB) {
            setAlert(false);
            setSuccess(false);
            const newImage = await compressedUploader()
            console.log('newImage', newImage);
           console.log(compressedFile)
           console.log(fileB);
        }
    }


    const imageHandlerNewImages = async (e) => {
        e.preventDefault()
        //if(images){
            var a = 0
            while (a < newImages?.length) {
                await compressedUploader(newImages[a])
                a = a + 1
            }
            //const newImage = await compressedUploader(images[1])
            //console.log('newImage', newImage);
           //console.log(compressedFile)
           //console.log(fileB);
        //}
    }


    const compressedUploader = async (image) => {
        new Compressor(image, {
            quality: 0.6,

            async success(result) {
              const formData = new FormData();

              formData.append('image', result);


              const res = await axios.post('/api/posts', formData, { headers: {'Content-Type': 'multipart/form-data'}});
              setCompressedFile(res.data);
              setNewImageArray(arr => [...arr, res.data])
              console.log(res.data);
              //setSuccess(true);
              //setAlert(false)
              //setFileB(res.data);
            },
            error(err) {
              console.log(err.message);
            },

        });

    }


    const compressedUploaderNewImages = async () => {
        new Compressor(fileBNewImages, {
            quality: 0.6,

            async success(result) {
              const formData = new FormData();

              formData.append('image', result);


              const res = await axios.post('/api/posts', formData, { headers: {'Content-Type': 'multipart/form-data'}});
              setCompressedFile(res.data);
              //setImages(res.data)
              console.log(res.data);
              setSuccess(true);
              setAlert(false)
              //setFileB(res.data);
            },
            error(err) {
              console.log(err.message);
            },

        });

    }



    const genders = [['women', 'Women'], ['men', 'Men'], ['unisex', 'Unisex']]



    const FinalizeVenueEdit = (e) => {
        setFinalVenue(true)
        e.preventDefault();

    }

    const addVenueHandler = async (e) => {
            e.preventDefault();

            const newImages = await newImagesHandler();
            console.log('the newImages', newImages);


            const newPrices = await newPricesHandler();
            console.log('the prices', newPrices);

            var pricee = newPrices[0]

            var stockk = newPrices[2]

            var salee = newPrices[3]

            var sizee = newPrices[1]

            //const newDescriptions = await newDescriptionHandler();

            //console.log('final Description', newDescriptions)

            console.log(imageArray);


            if(name.trim() !== '' || sku.trim() !== '') {
                dispatch(
                    updateProduct({ productId, name, sku, category, sub_category, brand, gender, newImages, descriptions, pricee, stockk, salee, sizee})
                )
                setFinalAlert(false)

                navigate('/products')
            } else {
                setFinalAlert(true)
            }


    }


    const newImagesHandler  = () => {
        var newImagesArray2 = [];
        if(imageIdArray?.length !== 0) { //if we have image Id
            imageIdArray.map((id, index) => {
                var realId = id - 1
                return newImagesArray2[index] = product?.images[realId]
            })
        }

        if(newImagesArray2.length !== 0) {
            var lastIndex = newImagesArray2.length;
            if(newImageArray?.length !== 0) {
                newImageArray?.map((image, index) => (
                    newImagesArray2[index + lastIndex] = image
                ));
            }
        } else if (newImagesArray2?.length === 0) {
            if (product?.images?.length !== 0) {
                product?.images?.map((image, index) => (
                    newImagesArray2[index] = image
                ));

                var lasttIndex = newImagesArray2.length;

                newImageArray?.map((image, index) => (
                    newImagesArray2[index + lasttIndex] = image
                ));
            } else {
                newImageArray?.map((image, index) => (
                    newImagesArray2[index] = image
                ));
            }

        }

        console.log('new Image array', newImagesArray2)

        return newImagesArray2
    }




    const newPricesHandler = () => {
        var newPricesArray2 = [];
        var newSizesArray2 = [];
        var newStocksArray2 = [];
        var newSalesArray2 = [];

        //price
        if(priceArray?.length !== 0) {
            priceArray?.map((p, index) => {
                return newPricesArray2[index] = p
            })
        }

        if(newPriceArray?.length !== 0) {
            var lastIndex = newPricesArray2.length;
            newPriceArray?.map((p, index) => {
                return newPricesArray2[index + lastIndex] = p
            })
        }

        //size
        if(sizeArray?.length !== 0) {
            sizeArray?.map((p, index) => {
                return newSizesArray2[index] = p
            })
        }

        if(newSizeArray?.length !== 0) {
            var lastIndex2 = newSizesArray2.length;
            newSizeArray?.map((p, index) => {
                return newSizesArray2[index + lastIndex2] = p
            })
        }

        //stock
        if(stockArray?.length !== 0) {
            stockArray?.map((p, index) => {
                return newStocksArray2[index] = p
            })
        }

        if(newStockArray?.length !== 0) {
            var lastIndex3 = newStocksArray2.length;
            newStockArray?.map((p, index) => {
                return newStocksArray2[index + lastIndex3] = p
            })
        }


        //sale price
        if(saleArray?.length !== 0) {
            saleArray?.map((p, index) => {
                return newSalesArray2[index] = p
            })
        }

        if(newSaleArray?.length !== 0) {
            var lastIndex4 = newSalesArray2.length;
            newSaleArray?.map((p, index) => {
                return newSalesArray2[index + lastIndex4] = p
            })
        }


        console.log('new price array', newPricesArray2)
        console.log('new newSizesArray2', newSizesArray2)
        console.log('new newStocksArray2', newStocksArray2)
        console.log('new newSalesArray2', newSalesArray2)

        var priceAr = [newPricesArray2, newSizesArray2, newStocksArray2, newSalesArray2]

        return priceAr
    }


    useEffect(() => {
        if(compressedFile !== null) {
            setImageArray(arr => [...arr, compressedFile])
        }
    }, [compressedFile])


    const setImageIdHandler = (e) => {
        e.preventDefault();
        if(priceId.trim() !== '') {
            setPriceIdArray(arr => [...arr, priceId.trim()]);
            setPriceId('');
        }
    }

    const setCapacityIdHandler = (e) => {
        e.preventDefault();
        if(capacityId.trim() !== '') {
            setCapacityIdArray(arr => [...arr, capacityId.trim()]);
        }
    }


    const setDescriptionIdHandler = (e) => {
        e.preventDefault();
        if(descriptionId.trim() !== '') {
            setDescriptionIdArray(arr => [...arr, descriptionId.trim()]);
            setDescriptionId('');
        }
    }


    //console.log(venue?.title)



    const subTitleHandler = (id, sd, newSubTitle) => {
        if(newSubTitle !== '') {
            //setSubTitleAr(subTitle)
            setNewDescriptionArray(arr => [...arr, [id, sd, newSubTitle]])
            //descriptionSetter(id, sd, subTitle)
            console.log(newDescriptionArray)
        }


    }


    const linkHandler = (id, linkText, link) => {
        if(link !== '') {
            setNewDescriptionArray(arr => [...arr, [id, linkText, link]])
        }
        console.log(newDescriptionArray)
    }


    const subTitleLinkHandler = (id, linkText, link) => {
        if(link !== '') {
            setDescriptionArray(arr => [...arr, [id, linkText, link]])
        }
        console.log(newDescriptionArray)
    }


    const descriptionHandler = (id, sd, newPara) => {
        if(newPara !== '') {
            setNewDescriptionArray(arr => [...arr, [id, sd, newPara]])
            setDescriptionC(arr => [...arr, newPara])
        }
        console.log(newDescriptionArray)
    }


    const singleImageHandler = async (id, sd) => {
        console.log(fileB);
        if(!fileB){
            setAlertNewDesImage(!alert)
        } else if(fileB) {
            setSuccessNewDesImage(false)
            const newImage = await compressedUploader()
            console.log('newImage', newImage);
           console.log(compressedFile)
           console.log(fileB);
        }
    }


    const editPriceHandler = (e, ids, pricez, sizez, stockz, salez) => {
        e.preventDefault()
        setIdIndex(ids)
        setPrice(pricez)
        setSize(sizez)
        setStock(stockz)
        setSale(salez)
    }



    const finalizeHandler = (e) => {
        console.log(newDescriptionArray)
        console.log(descriptionC);
    }


    const finalizeEditedDescriptionHandler = (e) => {
        console.log(descriptionArray);
        console.log(descriptionC);
    }


    const editedPriceHandler = (e, pricez, sizez, stockz, salez) => {
        e.preventDefault();
        console.log(pricez)
        console.log(sizez)
        console.log(stockz)
        console.log(salez)
        console.log(idIndex)

            priceArray[idIndex] = price

            if(size.trim() !== '') {
                sizeArray[idIndex] = size
                console.log()
            } else {
                sizeArray[idIndex] = 'none'
            }

            if(sale !== '') {
                saleArray[idIndex] = sale
            } else {
                saleArray[idIndex] = 1
            }

            if(stock !== '') {
                stockArray[idIndex] = stock
            } else {
                stockArray[idIndex] = 0
            }



            setEditId('')
            setIdIndex(0)
            setPrice('');
            setSize('');
            setSale('');
            setStock('')

        /*} else {
            setEditAlert(true)
        }*/
    }




    const handleSize = (e) => {
        e.preventDefault()
       if(price2 !== ''){
           setNewPriceArray(arr => [...arr, price2])

           if(size2.trim() !== '') {
               setNewSizeArray(arr => [...arr, size2])
           } else {
               setNewSizeArray(arr => [...arr, 'none'])
           }

           if(sale2 !== '') {
               setNewSaleArray(arr => [...arr, sale2])
           } else {
               setNewSaleArray(arr => [...arr, 1])
           }

           if(stock2 !== '') {
               setNewStockArray(arr => [...arr, stock2])
           } else {
               setNewStockArray(arr => [...arr, 0])
           }
       }
   }



    return (
        <div className="list">
            <Sidebar/>
            <div className="listContainer">
                <Navbar/>
                <Top>
                    <h1>Edit Product</h1>
                </Top>
                <Wrapper>
            <Container>
                <Label2>Product Name</Label2>
                <Input value={name} onChange={(e) => setName(e.target.value)} type='text' placeholder='Calvin Klein' />
            </Container>
            <Container>
                <Label2>Product Sku</Label2>
                <Input value={sku} onChange={(e) => setSku(e.target.value)} type='text' placeholder='Calvin Klein' />
            </Container>
            <Container>
                    <Label2>Category</Label2>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={category}
                        label="Category"
                        onChange={onCategoryChange}
                    >
                        {utils?.category?.map((s, index) => (
                            <MenuItem key={index} value={s?.[0]}>
                                {s?.[1]}
                            </MenuItem>
                        ))}
                    </Select>
                </Container>
                <Container>
                    <Label2>Sub Category</Label2>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={sub_category}
                        label="Category"
                        onChange={onSubCategoryChange}
                    >
                        {utils?.sub_category?.map((s, index) => (
                            <MenuItem key={index} value={s?.[0]}>
                                {s?.[1]}
                            </MenuItem>
                        ))}
                    </Select>
                </Container>

                <Container>
                    <Label2>Brands</Label2>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={brand}
                        label="Category"
                        onChange={onBrandChange}
                    >
                        {utils?.brand?.map((s, index) => (
                            <MenuItem key={index} value={s?.[0]}>
                                {s?.[1]}
                            </MenuItem>
                        ))}
                    </Select>
                </Container>
                <Container>
                    <Label2>Gender</Label2>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={gender}
                        label="Category"
                        onChange={onGenderChange}
                    >
                        {genders.map((s, index) => (
                            <MenuItem key={index} value={s?.[0]}>
                                {s?.[1]}
                            </MenuItem>
                        ))}
                    </Select>
                </Container>
                <Container>
                    {product?.prices?.length !== 0?
                        <>
                            <Label2>Previous Sizes Prices</Label2>
                            { product?.prices?.length !== 0?
                                <>
                                    { product?.prices?.map((price, index) => (
                                        <Container key={index}>
                                            <Label2>Price id{' '}{index + 1}</Label2>
                                            <label>Price</label>
                                            <Input2 value={product?.prices?.[index]} type='Number' disabled='true' placeholder="19" />
                                            <label>Size</label>
                                            <Input2 value={product?.sizes?.[index]} type='text' disabled='true' placeholder="120 oz" />
                                            <label>Stock</label>
                                            <Input2 value={product?.stock?.[index]} type='text' disabled='true' placeholder="3" />
                                            <label>Sale Price</label>
                                            <Input2 value={product?.saleprices?.[index]} type='text' disabled='true' placeholder="13" />
                                            <Button onClick={(e)  => editPriceHandler(e, index, product?.prices?.[index], product?.sizes?.[index], product?.stock?.[index], product?.saleprices?.[index])}>Edit Price</Button>
                                        </Container>
                                    ))}
                                </>

                            : null
                            }
                            {idIndex !== ''?
                            <>
                                    <Label2>Edit Previous Sizes Prices</Label2>
                                    <Container>
                                        <label>Price</label>
                                            <Input2 value={price} type='Number' onChange={(e) => setPrice(e.target.value)} placeholder="19" />
                                            <label>Size</label>
                                            <Input2 value={size} type='text' onChange={(e) => setSize(e.target.value)} placeholder="120 oz" />
                                            <label>Stock</label>
                                            <Input2 value={stock} type='Number' onChange={(e) => setStock(e.target.value)} placeholder="3" />
                                            <label>Sale Price</label>
                                            <Input2 value={sale} type='Number' onChange={(e) => setSale(e.target.value)} placeholder="13" />
                                            <Button onClick={(e)  => editedPriceHandler(e, price, size, stock, sale)}>Save Price</Button>
                                            {sizeArray?.length !== 0 ?
                                                <>
                                                    {priceArray?.map((si, index) => (
                                                        <div style={{ 'display': 'flex', 'gap': '8px'}}>
                                                            <h1>{priceArray[index]}</h1>
                                                            <h1>{sizeArray[index]}</h1>
                                                            <h1>{stockArray[index]}</h1>
                                                            <h1>{saleArray[index]}</h1>
                                                        </div>
                                                    ))}
                                                </>
                                                : null
                                            }
                                    </Container>

                            </>
                            : null}
                        </>
                    : null
                    }
                    <Label2>New Sizes Prices</Label2>
                    <label>Price</label>
                    <input value={price2} type='Number' onChange={(e) => setPrice2(e.target.value)} placeholder="19" />
                    <label>Size</label>
                    <input value={size2} type='text' onChange={(e) => setSize2(e.target.value)} placeholder="120 oz" />
                    <label>Stock</label>
                    <input value={stock2} type='Number' onChange={(e) => setStock2(e.target.value)} placeholder="3" />
                    <label>Sale Price</label>
                    <input value={sale2} type='Number' onChange={(e) => setSale2(e.target.value)} placeholder="13" />
                    <button onClick={(e) => handleSize(e)}>Add</button>
                    {sizeArray?.length !== 0 ?
                        <>
                            {priceArray?.map((si, index) => (
                                <div style={{ 'display': 'flex', 'gap': '8px'}}>
                                    <h1>{si}</h1>
                                    <h1>{sizeArray[index]}</h1>
                                    <h1>{stockArray[index]}</h1>
                                    <h1>{saleArray[index]}</h1>
                                </div>
                            ))}
                        </>
                        : null
                    }
                </Container>

            <Container>
                {product?.images?.length !== 0?
                    <>
                        <Label2>Old Images</Label2>
                        { product?.images?.length !== 0?
                            <>
                                { product?.images?.map((image, index) => (
                                    <Container key={index}>
                                        <Label2>Image id{' '}{index + 1}</Label2>
                                        <Image2 src={image} alt='' />
                                    </Container>
                                ))}
                            </>

                        : null
                        }
                        <Label2>Arrange Images According to its Id</Label2>
                        <Container2>
                            <Input2 value={imageId} onChange={(e) => setImageId(e.target.value)} type='text' />
                            <Button2 onClick={(e) => setImageIdHandler(e)}>Set Image Id</Button2>
                            {imageIdArray?.length !== 0?
                                <>
                                    {imageIdArray.map((imagee, index) => (
                                        <Label2>{imagee}</Label2>
                                    ))}
                                </>
                            : null
                            }
                        </Container2>
                    </>
                : null
                }
            </Container>
            <Container>
                <Label2>New Images</Label2>
                <ImageInput
                        accept="image/*"
                        type="file"
                        onChange={handleCompressedUpload}
                        multiple
                />
                <Button onClick={(e) => imageHandlerNewImages(e)}>Add Images</Button>
                    {alert?
                        <h1>Please select the image</h1>
                    : null
                    }
                    {success?
                        <h1>Successfully Uploaded</h1>
                    : null
                    }
                </Container>
                <Container>
                    <Label2>Description</Label2>
                    <MDEditor height={200} value={descriptions} onChange={setDescriptions} />
                </Container>
            <Button onClick={(e) => addVenueHandler(e)}>Add Product</Button>
        </Wrapper>
            </div>
        </div>
    )
}

const List  =  styled.div`
    display: flex;
    width: 100%;
`;


const ListContainer = styled.div`

`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 100px 30px;
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
    padding: 10px;
    margin: 20px;
    justify-content: center;
    align-items: center;
`;


const Top = styled.div`
    display: flex;
    flex-direction: column;
    padding: 10px 30px;
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);

    .h1{
        color: lightgray !important;
        font-size: 20px;
    }

`;



const Label = styled.label`
    padding: 20px 0px 10px 0px;
    font-size: 1.5rem;
    font-weight: ${WEIGHTS.medium};
    color: black;

`;

const Container = styled.div`
    display: flex;
    flex-direction: column;

`;

const Container2 = styled(Container)`
    flex-direction: row;
    align-items: center;
`;

const Label2 = styled(Label)`
    padding: 20px 0px 10px 0px;
    font-size: 1.5rem;
    font-weight: ${WEIGHTS.medium};
    color: black;
`;

const Input = styled.input`
    width: 350px;
    height: 50px;
    font-size: 1rem;
    border-radius: 4px;
    color: grey;
    outline: none;

    &:focus {
        border-color: #0081ba;
    }
`;


const Input2 = styled(Input)`
    width: 100px;
`;

const Image2 = styled.img`
    height: auto;
    max-width: 100%;
    vertical-align: middle;
    border-style: none;
    width: 360px;
    aspect-ratio: auto 674 / 495;
    height: 264px;
    padding-bottom: 30px;

    @media ${QUERIES.laptopAndUp} {
        width: 674px;
        height: 495px;
    }

    @media ${QUERIES.tabletOnly} {
        width: 518px;
        height: 380px;
    }


`;



const SelectWrapper = styled.div`
  position: relative;
  isolation: isolate;
  text-decoration: uppercase;
  display: flex;
  z-index: 1;
  align-items: flex-start;
  flex-direction: column;
  border-radius: 5px;
  background: #023E8A;
  width: 280px;
  padding-bottom: 20px;
  box-shadow: 10px ${COLORS.gray[1100]};
`;


const Select2 = styled.select`
  position: relative;
  appearance: none;
  filter: none;
  width: 280px;
  font-weight: ${WEIGHTS.normal};
  text-transform: uppercase;
  font-size: 0.8rem;
  padding: 20px 0px 0px 12px;
  border: none;
  border-radius: 20px;
  outline: none;
  color: white;
  autocomplete: off;
  background: transparent;
  z-index: 4;
  cursor: pointer;
  & > :focus {
    outline: 0;
    outline-color: transparent;
    outline-style: none;
  }
`;

const Option = styled.option`
  background: #023E8A;
  font-size: 0.8rem;
  text-transform: uppercase;
  font-weight: ${WEIGHTS.medium};
`;

const ChevronIcon = styled(Icon)`
  position: absolute;
  top: 1px;
  right: 10px;
  bottom: 2px;
  margin: auto;
  width: 24px;
  height: 24px;
  z-index: 3;
  color: white;
  cursor: inherit;
`;


const ImageInput = styled.input`
    content: 'Select Image';
    display: inline-block;
    background: linear-gradient(top, #f9f9f9, #e3e3e3);
    border: 1px solid #999;
    border-radius: 3px;
    padding: 5px 8px;
    margin-left: 26px;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    text-shadow: 1px 1px #fff;
    font-weight: 700;
    font-size: 10pt;

    @media ${QUERIES.laptopAndUp} {
        padding-left: 28px;
        margin-left: 26px;
    }

    @media ${QUERIES.tabletOnly} {
        padding-left: 28px;
        margin-left: 26px;
    }
`;



const TextArea = styled.textarea`

`;

const Button = styled.button`
    width: 150px;
    padding: 10px;
    margin: 8px;
    border-radius: 5px;
    border: 1px solid #023E8A;
    color: white;
    background: #023E8A;
    cursor: pointer;
`;


const Button2 = styled(Button)`
    width: 100px;
`;

const CheckInput = styled.input`
    width: 80px;
    height: 40px;
`;


export default EditProduct;
