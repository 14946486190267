import axios from "axios";

export const listPromos = () => async (dispatch, getState) => {
    try {
        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
              Authorization: `Bearer ${userInfo.token}`,
            },
        }
        dispatch({ type: 'PROMO_LIST_REQUEST'});

        const { data } = await axios.get(`/api/v1/promos`, config
        );
        const dataRow = data.data.promos
        dispatch({
            type: 'PROMO_LIST_SUCCESS',
            payload: dataRow,
        })
    } catch (error) {
        dispatch({
            type: 'PROMO_LIST_FAIL',
            payload:
                error.response && error.response.data.message
                    ?error.response.data.message
                    :error.message,
        })
    }
}

export const getPromoById = (id) => async (dispatch, getState) => {
    try {
        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
              Authorization: `Bearer ${userInfo.token}`,
            },
        }
        dispatch({ type: 'PROMO_DETAILS_BY_ID_REQUEST'});
        const { data } = await axios.get(`/api/v1/promos/promodetails/${id}`, config);

        dispatch({
            type: 'PROMO_DETAILS_BY_ID_SUCCESS',
            payload: data.data.promo,
        })
    } catch (error) {
        console.log(error);
    }
}


export const createPromo = ({ codes, percent, status }) => async (dispatch, getState) => {
    try {

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
              Authorization: `Bearer ${userInfo.token}`,
            },
        }

        const { data } = await axios.post('/api/v1/promos', {
            codes, percent, status
        }, config)

        dispatch({
            type: 'PROMO_CREATE_SUCCESS'
        })
    } catch (error) {
        console.log(error)
    }
}

export const updatePromo = ({  codes, percent, status, promoId }) => async (dispatch, getState) => {
    try {

        const {
            userLogin: { userInfo }
        } = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            }
        }
        var id = promoId
        const { data } = await axios.put(`/api/v1/promos/${id}`, {
            codes, percent, status
        
        }, config)
        //console.log(images)
        dispatch({
            type: 'PROMO_UPDATE_SUCCESS',
            payload: data,
        })
    } catch (error) {
        console.log(error);
    }
}

export const deletePromo = (id) => async (dispatch, getState) => {
    try {

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
              Authorization: `Bearer ${userInfo.token}`,
            },
        }
        const { data } = await axios.delete(`/api/v1/promos/${id}`, config);
        dispatch({
            type: 'DELETE_PROMO_SUCCESS',
            payload: data,
        })
    } catch (error) {
        console.log(error);
    }
}



