export const utilListReducer = (state = { utils: []}, action) => {
    switch (action.type) {
        case 'UTILS_LIST_REQUEST':
            return { utils: [] }
        case 'UTILS_LIST_SUCCESS':
            return {
                utils: action.payload,
            }
        case 'UTILS_LIST_FAIL':
            return { error: action.payload }
        default:
            return state
    }
}

